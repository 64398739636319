import React from 'react';
import { cn } from '@src/utils/bem-config';
import './LinkInline.scss';

const bem = cn('LinkInline');

export const LinkInline = ({
  linkType = 'a',
  children,
  onClickHandler = () => null,
  href = '/',
  disabled = false,
  colored = false,
}) => React.createElement(linkType, { className: bem({ colored }), onClick: onClickHandler, href, disabled }, children);
