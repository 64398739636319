import {
  searchPosts,
  selectSearchedPosts,
  selectSearchValue,
  selectTagValue,
  setRecentSearchValue,
  setTagValue,
} from '@components/common/Search/searchSlice';
// import { Tabs } from '@components/common/Tabs';
import { PostsResult } from '@components/pages/SearchPage/PostsResult';
import { ParametersButton } from '@components/common/ParametresButton/ParametersButton';
import { cn } from '@utils/bem-config';
import React, { useEffect, useState } from 'react';
import { setUserSearchValue } from '@src/components/common/UsersSearch/usersSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@src/hooks/useDebounce';
import { SvgIcon } from '@components/common/SvgIcon';
import { getTags, selectTagsList } from '@components/common/PostsList/postsListSlice';
import './SearchPage.scss';

const bem = cn('search-page');

export const SearchPage = ({ isFocused }) => {
  const dispatch = useDispatch();
  const { results: searchedPosts } = useSelector(selectSearchedPosts);
  const searchGlobalValue = useSelector(selectSearchValue);
  const tagsList = useSelector(selectTagsList);
  const tagValue = useSelector(selectTagValue);
  const [searchValue, setSearchValue] = useState(searchGlobalValue);
  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const [selectedPostTag, setSelectedPostTag] = useState(tagValue);

  const debouncedSearchValue = useDebounce(searchValue?.trim(), 500);

  useEffect(() => {
    dispatch(setUserSearchValue(''));
    dispatch(getTags());
    if (tagValue) {
      setIsParametersOpen(true);
    }
  }, []);

  useEffect(() => !isFocused && setSearchValue(searchGlobalValue), [isFocused]);

  useEffect(() => {
    let isRequestEmpty = false;
    if (!debouncedSearchValue && selectedPostTag === undefined) {
      isRequestEmpty = true;
    }

    dispatch(setRecentSearchValue(debouncedSearchValue));
    dispatch(
      searchPosts({
        searchValue: debouncedSearchValue,
        size: 100,
        tag: selectedPostTag,
        emptyRequest: isRequestEmpty,
      })
    );
    if (tagValue !== undefined) {
      dispatch(setTagValue(undefined));
      setIsParametersOpen(true);
    }
  }, [debouncedSearchValue, selectedPostTag]);

  const toggleParameters = () => {
    setIsParametersOpen(!isParametersOpen);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleChangePostSearchValue = (e) => {
    e.target.value === '' ? setSearchValue(undefined) : setSearchValue(e.target.value);
  };

  const handleAddTag = (e) => {
    setSelectedPostTag(e.target.value);
  };

  return (
    <div className={bem()}>
      <header className={bem('header')}>
        <h1 className={bem('title')}>Посты</h1>
        <p className={bem('subtitle')}>Результатов: {searchedPosts?.length}</p>
      </header>
      <div className={bem('search-parameters')}>
        <div className={bem('search_wrapper')}>
          <input
            type="text"
            value={searchValue}
            onChange={handleChangePostSearchValue}
            className={bem('input')}
            placeholder="Поиск"
          />
          {searchValue && (
            <button type="button" onClick={clearSearch} className={bem('input-clear-btn')}>
              <SvgIcon type="close" />
            </button>
          )}
        </div>
        <ParametersButton toggleParameters={toggleParameters} isParametersOpen={isParametersOpen} />
      </div>
      {isParametersOpen && (
        <div className={`${bem('parameters')} ${selectedPostTag && bem('item-active')}`}>
          <select
            value={selectedPostTag}
            name="tag"
            onChange={(e) => handleAddTag(e)}
            className={`${bem('select-btn')} ${selectedPostTag && bem('select-active')}`}
          >
            <option value="">Тэг</option>
            {tagsList.map(
              (tag) =>
                tag && (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                )
            )}
          </select>
        </div>
      )}
      {!isFocused && <PostsResult searchedPosts={searchedPosts} />}

      {/* <Tabs */}
      {/*  tabList={[ */}
      {/*    { */}
      {/*      title: `Посты ${searchedPosts.length}`, */}
      {/*      component: ( */}
      {/*        <> */}
      {/*          {isParametersOpen && ( */}
      {/*            <div className={bem('parameters')}> */}
      {/*              <select */}
      {/*                value={selectedPostTag} */}
      {/*                name="tag" */}
      {/*                onChange={(e) => handleAddTag(e)} */}
      {/*                className={bem('select-btn')} */}
      {/*              > */}
      {/*                <option value="">Тэг</option> */}
      {/*                {tagsList.map( */}
      {/*                  (tag) => */}
      {/*                    tag && ( */}
      {/*                      <option key={tag} value={tag}> */}
      {/*                        {tag} */}
      {/*                      </option> */}
      {/*                    ) */}
      {/*                )} */}
      {/*              </select> */}
      {/*            </div> */}
      {/*          )} */}
      {/*          <PostsResult searchedPosts={searchedPosts} /> */}
      {/*        </> */}
      {/*      ), */}
      {/*      additionalComponent: ( */}
      {/*        <> */}
      {/*          {searchValue && ( */}
      {/*            <button */}
      {/*              type="button" */}
      {/*              onClick={toggleParameters} */}
      {/*              className={bem('parameters-btn', { active: isParametersOpen })} */}
      {/*            > */}
      {/*              <SvgIcon type="settings" className={bem('parameters-btn-icon')} /> */}
      {/*            </button> */}
      {/*          )} */}
      {/*        </> */}
      {/*      ), */}
      {/*    }, */}
      {/*  ]} */}
      {/* /> */}
    </div>
  );
};
