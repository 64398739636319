import { CommonTooltip } from '@components/common/CommonTooltip';
import { Modal } from '@components/common/Modal';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectRoles } from '@src/appSlice';
import { pinPosts } from '@components/common/PostsList/postsListSlice';
import { cn } from '@utils/bem-config';
import { Dropdown, Menu, message } from 'antd';
import { Button as ButtonFromScroll } from '@components/common/Button';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import './SelectForPost.scss';
import { SvgIcon } from '@components/common/SvgIcon';

const bem = cn('select-for-post');
export const SelectForPost = ({
  data,
  pinnedMode,
  setPinnedMode,
  handlePinnedPost,
  isDeleting,
  activateEditMode,
  isEditPost,
  deleteVote,
  enableDeleteVoice,
  isDeffered,
}) => {
  const roles = useSelector(selectRoles);
  const pinsPosts = useSelector(pinPosts);
  const isAdmin = new Set(roles).has('ADMIN');

  const handleShare = () => {
    let copyToClipboard;

    if (navigator.clipboard) {
      copyToClipboard = (text) => navigator.clipboard.writeText(text);
    } else {
      copyToClipboard = async (text) => {
        const tmp = document.createElement('TEXTAREA');
        const focus = document.activeElement;
        tmp.value = text;
        document.body.appendChild(tmp);
        tmp.select();
        document.execCommand('copy');
        document.body.removeChild(tmp);
        focus.focus();
      };
    }
    copyToClipboard(`${window.location.href}/${data?.postId}`).then(() => message.info('Скопировано'));
  };

  /**
   * Список выпадающего меню, который доступен всем пользователям.
   */
  const dropDownItemsCommon = (
    <>
      <Menu.Item key="menu-item-share" className={bem('dropDown-menu-item')} onClick={() => handleShare()}>
        Поделиться
      </Menu.Item>
      {data.postType === 'VOTE' && Boolean(enableDeleteVoice) && (
        <Menu.Item
          key="menu-item-delete-vote"
          className={bem('dropDown-menu-item')}
          onClick={() => deleteVote(data.voteResponse.id)}
        >
          Отменить голос
        </Menu.Item>
      )}
    </>
  );

  /**
   * Список выпадающего меню, который доступен только админу.
   */
  const dropDownItemsForAdmin = (
    <>
      {!isDeffered && (
        <>
          {data?.isPinned ? (
            <Menu.Item
              key="menu-item-unfasten"
              className={bem('dropDown-menu-item')}
              onClick={() => setPinnedMode(true)}
            >
              Открепить запись
            </Menu.Item>
          ) : (
            <Menu.Item key="menu-item-pin" className={bem('dropDown-menu-item')} onClick={() => setPinnedMode(true)}>
              Закрепить запись
            </Menu.Item>
          )}
        </>
      )}

      {data.postType === 'DEFAULT' && (
        <Menu.Item
          key="menu-item-edit"
          className={bem('dropDown-menu-item')}
          onClick={() => activateEditMode()}
          disabled={!!isEditPost}
        >
          {isEditPost ? <CommonTooltip title="Вы уже редактируете пост">Редактировать</CommonTooltip> : 'Редактировать'}
        </Menu.Item>
      )}
      <Menu.Item key="menu-item-delete" className={bem('dropDown-menu-item')} onClick={() => isDeleting(true)}>
        Удалить
      </Menu.Item>
    </>
  );

  const pinModalContent = (
    <div className={bem('buttons')}>
      <ButtonFromScroll type="button" onClick={handlePinnedPost}>
        Абсолютно
      </ButtonFromScroll>
      <ButtonAnimated onClick={() => setPinnedMode(false)} type="cancel">
        Отмена
      </ButtonAnimated>
    </div>
  );

  return (
    <div>
      <Dropdown
        className={bem('dropDown')}
        overlay={
          <Menu motion={null} className={bem('dropDown-menu')}>
            {isAdmin ? dropDownItemsForAdmin : dropDownItemsCommon}
          </Menu>
        }
        trigger={['click']}
        placement="bottomRight"
      >
        <button className={bem('button')}>
          <SvgIcon type="dots" className={bem('dots')} />
        </button>
      </Dropdown>

      {pinnedMode && (
        <Modal
          content={pinModalContent}
          closeHandler={() => setPinnedMode(false)}
          title={
            data?.isPinned
              ? 'Вы уверены, что хотите открепить данный пост?'
              : pinsPosts.length === 0
              ? 'Вы уверены, что хотите закрепить данный пост?'
              : 'Cуществует закрепленный пост, вы уверены, что хотите закрепить данный пост?'
          }
        />
      )}
    </div>
  );
};
