import { cn } from '@utils/bem-config';
import React from 'react';
import './AppPage.scss';
import { Link } from 'react-router-dom';
import { SvgIcon } from '@components/common/SvgIcon';
import { SnakeGame } from '@components/common/SnakeGame';

const bem = cn('app-page');

export const AppPage = ({ title = 'Название', route = '/' }) => (
  // const history = useHistory();

  <section className={bem()}>
    <header className={bem('header')}>
      <h1 className={bem('title')}>{title}</h1>
      <Link className={bem('back-link')} to={route}>
        <SvgIcon type="arrow" className={bem('back-btn-icon')} fill="var(--c-accent)" /> Ко всем играм
      </Link>
    </header>

    <SnakeGame />

    {/* <button className={bem('title')} onClick={history.goBack}>
        ← Назад
      </button> */}
  </section>
);
