import { STATUS } from '@src/hoc/StatusSwitch';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, request } from '@src/app/api';
import { getAddressGeo } from '@src/components/common/UsersList/usersListSlice';

const initialState = {
  offices: [],
  officesStatus: STATUS.NO_STATUS,
  officesCoordsStatus: STATUS.NO_STATUS,
};

export const getOfficesList = createAsyncThunk('getOfficesList', async () => {
  const response = await request.get(api.offices());

  return response.data;
});

export const setOfficesCoords = createAsyncThunk('setOfficesCoords', async ({ ymapsApi, officesAddress }) => {
  const result = await getAddressGeo(ymapsApi, officesAddress);

  return { data: result };
});

export const geographyOfficesSlice = createSlice({
  name: 'geographyOffices',
  initialState,
  extraReducers: {
    [getOfficesList.fulfilled]: (state, action) => {
      state.offices = action.payload;
      state.officesStatus = STATUS.DONE;
    },
    [getOfficesList.pending]: (state) => {
      state.officesStatus = STATUS.LOADING;
    },
    [getOfficesList.rejected]: (state) => {
      state.officesStatus = STATUS.ERROR;
    },
    [setOfficesCoords.fulfilled]: (state, action) => {
      state.offices = state.offices.map((office) => ({
        ...office,
        officeGeo: action.payload.data[office.address],
      }));
      state.officesCoordsStatus = STATUS.DONE;
    },
    [setOfficesCoords.pending]: (state) => {
      state.officesCoordsStatus = STATUS.LOADING;
    },
    [setOfficesCoords.rejected]: (state) => {
      state.officesCoordsStatus = STATUS.ERROR;
    },
  },
});

export const selectOfficesList = (state) => state.officesList.offices;
export const selectOfficesStatus = (state) => state.officesList.officesStatus;
export const selectOfficesCoordsStatus = (state) => state.officesList.officesCoordsStatus;

export default geographyOfficesSlice.reducer;
