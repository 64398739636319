import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api, request } from '@src/app/api';
import { STATUS } from '@src/hoc/StatusSwitch';

const initialState = {
  birthdays: [],
  listStatus: STATUS.NO_STATUS,
  /**
   * Начальная дата запроса списка дней рождения.
   */
  requestFirstDayBirthdays: null,
  /**
   * Конечная дата запроса списка дней рождения.
   */
  requestLastDayBirthdays: null,
};

/**
 * Получить список сотрудников с днями рождения в выбранном диапазоне даты.
 * @param {string} [data.firstDay] Необязательный параметр стартовой даты в выборке.
 * @param {string} [data.lastDay] Необязательный параметр конечной даты в выборке.
 */
export const getBirthdays = createAsyncThunk('getBirthdays', async ({ firstDay, lastDay } = {}) => {
  const response = await request.get(api.birthdays(), {
    params: { firstDay, lastDay },
  });
  return { data: response.data, firstDay, lastDay };
});

export const changeAvatarInBirthday = createAsyncThunk('changeAvatarInBirthday', async ({ userId, url, photoId }) => {
  const response = await request.get(api.birthdays());
  return { data: response.data, userId, photoId, url };
});

export const deleteAvatarInBirthday = createAsyncThunk('deleteAvatarInBirthday', async ({ userLdapId, photoId }) => {
  const response = await request.get(api.birthdays());
  return { userLdapId, data: response.data, photoId };
});

export const birthdaysListSlice = createSlice({
  name: 'birthdays',
  initialState,
  extraReducers: {
    [getBirthdays.pending]: (state) => {
      state.listStatus = STATUS.LOADING;
    },
    [getBirthdays.fulfilled]: (state, action) => {
      const { data, firstDay, lastDay } = action.payload;

      state.birthdays = data;
      state.listStatus = STATUS.DONE;
      state.requestFirstDayBirthdays = firstDay;
      state.requestLastDayBirthdays = lastDay;
    },
    [getBirthdays.rejected]: (state) => {
      state.listStatus = STATUS.ERROR;
    },
    [changeAvatarInBirthday.fulfilled]: (state, action) => {
      const { data, userId, photoId, url } = action.payload;
      state.birthdays = data.map((item) =>
        item.userId === userId ? { ...item, photos: [...item.photos, { photoId, url, photoLabel: 'AVATAR' }] } : item
      );
      state.listStatus = STATUS.DONE;
    },
    [changeAvatarInBirthday.rejected]: (state) => {
      state.listStatus = STATUS.ERROR;
    },
    [deleteAvatarInBirthday.fulfilled]: (state, action) => {
      const { data, userLdapId, photoId } = action.payload;
      state.birthdays = data.map((item) =>
        item.ldapId === userLdapId ? { ...item, photos: [...item.photos.filter((el) => el.photoId !== photoId)] } : item
      );
      state.listStatus = STATUS.DONE;
    },
    [deleteAvatarInBirthday.rejected]: (state) => {
      state.listStatus = STATUS.ERROR;
    },
  },
});

export const selectBirthdays = (state) => state.birthdaysList.birthdays;
export const selectStatus = (state) => state.birthdaysList.listStatus;
export const selectRequestFirstDayBirthdays = ({ birthdaysList }) => birthdaysList.requestFirstDayBirthdays;
export const selectRequestLastDayBirthdays = ({ birthdaysList }) => birthdaysList.requestLastDayBirthdays;

export default birthdaysListSlice.reducer;
