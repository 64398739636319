import React from 'react';
import { Tabs } from '@components/common/Tabs';
import { cn } from '@utils/bem-config';
import { useHistory } from 'react-router-dom';
import { PostsList } from '@components/common/PostsList';
import './PostListTabs.scss';
import { PostsListDelayed } from '@components/common/PostsListDelayed/PostsListDelayed';

const bem = cn('postListTabs');

export const PostListTabs = () => {
  function useQuery(location) {
    return new URLSearchParams(location.search ? location.search : '?tab=employees');
  }

  const history = useHistory();
  const { location } = history;
  const queryParams = useQuery(location);

  const tabComponents = [
    {
      title: 'Все записи',
      name: 'all',
      component: <PostsList />,
      url: `${location.pathname}?tab=all`,
    },
    {
      title: 'Отложенные',
      name: 'delayed',
      component: <PostsListDelayed />,
      url: `${location.pathname}?tab=delayed`,
    },
  ];

  const getTabIndex = (name) => {
    const foundIndex = tabComponents.findIndex((tab) => tab.name === name);
    const defaultIndex = 0;
    const activeTabIndex = foundIndex === -1 ? defaultIndex : foundIndex;
    return activeTabIndex;
  };

  const defaultTab = getTabIndex(queryParams.get('tab'));

  return (
    <div className={bem()}>
      <Tabs defaultTab={defaultTab} tabList={tabComponents} />
    </div>
  );
};
