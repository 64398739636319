export const apiMapKey = 'accfae3f-39a7-4b99-b0ca-bfff7244fe6a';
export const countOfCommentsPreview = 2;
export const email = 'social@irlix.ru';
export const limitOfNameCitySymbols = 15;
export const limitOfSearchedPostsSymbols = 30;
export const maxAge = 18;
export const maxAvatarAndBackgroundSize = 10; // 10 МБ
export const maxAvatarAndBackgroundSizeKB = maxAvatarAndBackgroundSize * 1024 * 1024;
export const maxImageSize = 10; // 10 МБ
export const maxImageSizeKB = maxImageSize * 1024 * 1024;
export const maxLengthMsg = 4095;
export const maxLengthMsgWithMedia = 1023;
export const maxValueUsersSearch = 128;
export const maxYear = new Date().getFullYear() - maxAge;
export const minYear = 1900;
export const postToLoadingCount = 7;
export const urlSubscribeTelegram = 'https://t.me/+INoqmy5y38U0ZDBi';
export const viewedPostStorageItemName = 'viewedPost';

export const addingPostWithPicStatuses = {
  image: {
    loading: 'imageLoading',
    loaded: 'imageLoaded',
    error: 'imageError',
  },
  post: {
    loading: 'postLoading',
    loaded: 'postLoaded',
    error: 'postError',
  },
};
