import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from 'antd';
import { cn } from '@utils/bem-config';
import moment from 'moment';
import './DropdownPublication.scss';
import ArrowIcon from '@components/common/SvgIcon/ArrowIcon';
import { addPostStatus } from '@components/common/PostsList/postsListSlice';
import { useSelector } from 'react-redux';

const bem = cn('dropdownPublication');

function hasSomeParentTheClass(element, classname) {
  if (
    element?.className &&
    !(element?.className instanceof SVGAnimatedString) &&
    element.className.split(' ').indexOf(classname) >= 0
  )
    return true;
  return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
}

export const DropdownPublication = ({ functionOnSelect, pickedDate }) => {
  const dateFormat = 'DD.MM.YYYY HH:mm';
  const wrapperRef = useRef(null);
  const addingPostStatus = useSelector(addPostStatus);
  const [selectedDate, setSelectedDate] = useState(pickedDate || null);
  const [isDropDownOpened, setIsDropdownOpened] = useState(false);

  const handleVisibility = () => {
    setIsDropdownOpened((prevState) => !prevState);
  };

  const handleDateChange = (date, dateString) => {
    if (!dateString) {
      setIsDropdownOpened(false);
      setSelectedDate(null);
      return;
    }
    setIsDropdownOpened(false);
    setSelectedDate(date);
  };

  useEffect(() => {
    if (!pickedDate && addingPostStatus === 'done') setSelectedDate(null);
  }, [addingPostStatus]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !hasSomeParentTheClass(event.target, 'ant-picker-dropdown')
      ) {
        setIsDropdownOpened(false);
      } else if (hasSomeParentTheClass(event.target, 'ant-picker-input')) {
        setIsDropdownOpened(true);
      }
    }
    if (isDropDownOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropDownOpened, wrapperRef]);

  useEffect(() => {
    let resultDate = selectedDate;
    if (selectedDate) {
      resultDate = moment(selectedDate).utc().format();
    }
    functionOnSelect(resultDate);
  }, [selectedDate]);

  return isDropDownOpened || selectedDate ? (
    <div ref={wrapperRef}>
      <DatePicker
        showNow={false}
        defaultValue={selectedDate ? moment(selectedDate) : undefined}
        format={dateFormat}
        showTime={{ format: 'HH:mm' }}
        disabledDate={(current) => {
          const customDate = moment().format('YYYY-MM-DD');
          return current && current < moment(customDate, 'YYYY-MM-DD');
        }}
        open={isDropDownOpened}
        onChange={handleDateChange}
        onClick={handleVisibility}
      />
    </div>
  ) : (
    <button className={bem('button')} onClick={handleVisibility}>
      <div>Сейчас</div>
      <ArrowIcon />
    </button>
  );
};
