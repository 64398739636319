import React from 'react';
import { SvgIcon } from '@components/common/SvgIcon';
import { cn } from '@utils/bem-config';
import './DeleteButton.scss';

const bem = cn('delete-button');

export const DeleteButton = ({ onClick }) => (
  <button className={bem()} onClick={onClick}>
    <SvgIcon type="close" />
  </button>
);
