import { maxYear, minYear } from '@utils/constants';

export const userInformationValidateParameters = {
  phone: {
    required: true,
    validate: (value) => {
      if (value.replace(/[\W_]/gi, '').length < 11) {
        return false;
      }
    },
  },
  birthDate: {
    valueAsDate: true,
    required: true,
    validate: (value) => value.getFullYear() < maxYear && value.getFullYear() > minYear,
  },
  skype: {
    required: true,
    validate: (value) => value.trim() !== '',
    maxLength: 255,
  },
  telegram: {
    required: true,
    validate: (value) => value.trim() !== '',
    maxLength: 33,
    minLength: 6,
  },
  city: {
    required: false,
    validate: (value) => value?.trim() !== '',
    maxLength: 255,
  },
};
export const isFileTypeValid = (file) =>
  !(file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/webp');
export const validImagesType = '.jpg, .webp, .png';
