import React from 'react';
import { cn } from '@utils/bem-config';
import './CreatorSection.scss';
import { CreatorItem } from '../CreatorItem';

const bem = cn('creator-section');

export function CreatorSection({ title, sectionUsers }) {
  return (
    <section className={bem()}>
      <h4 className={bem('title')}>{title}</h4>

      {sectionUsers.map((sectionUser) => {
        const userInfo = {
          name: sectionUser?.name,
          department: sectionUser?.department.join(' | '),
          ldapId: sectionUser?.ldapId,
        };

        return <CreatorItem key={sectionUser.ldapId} user={userInfo} />;
      })}
    </section>
  );
}
