import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUser,
  updateUserInformation,
} from '@src/components/common/MyProfilePreview/myProfilePreviewSlice';
import { cn } from '@utils/bem-config';
import './NotificationsSettings.scss';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { Button } from '@components/common/Button';
import { Checkbox } from 'antd';
import { urlSubscribeTelegram } from '@src/utils/constants';

const bem = cn('notifications');

export const NotificationsSettings = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isSubscribe = currentUser?.subscribe;
  const [acceptButtonDisable, setAcceptButtonDisable] = useState(true);
  const [isCheckboxState, setIsCheckoxState] = useState(isSubscribe);

  const clearState = () => {
    setAcceptButtonDisable(true);
    setIsCheckoxState(currentUser?.subscribe);
  };

  const onCheckboxChange = (event) => {
    setAcceptButtonDisable(!acceptButtonDisable);
    setIsCheckoxState(event.target.checked);
  };

  const acceptHandler = () => {
    const data = { subscribe: isCheckboxState };
    dispatch(updateUserInformation({ data, notification: 'подписка на уведомления' }));
  };

  const handleSubscribeTelegram = () => {
    window.open(urlSubscribeTelegram);
  };

  const handleCancel = () => clearState();

  useEffect(() => {
    setAcceptButtonDisable(true);
  }, [isSubscribe]);

  return (
    <div className={bem()}>
      <div className={bem('subscribes')}>
        <Checkbox checked={isCheckboxState} onChange={onCheckboxChange}>
          Получать уведомления на почту
        </Checkbox>
        <div className={bem('subscribes-telegram')}>
          <button className={bem('subscribes-telegram-button')} onClick={handleSubscribeTelegram}>
            Подписаться в Telegram
          </button>
        </div>
      </div>
      <div className={bem('footer-btns')}>
        <Button onClick={acceptHandler} disabled={acceptButtonDisable}>
          Принять
        </Button>
        <ButtonAnimated onClick={handleCancel} type="cancel" disabled={acceptButtonDisable}>
          Отмена
        </ButtonAnimated>
      </div>
    </div>
  );
};
