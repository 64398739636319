import React from 'react';
import { BirthdaysList } from '@src/components/common/BirthdaysList';
import { cn } from '@src/utils/bem-config';
import './Footer.scss';

const bem = cn('footer');

export const Footer = () => (
  <aside className={bem()}>
    <div className={bem('relative-container')}>
      <div className={bem('sticky-container')}>
        <BirthdaysList />
      </div>
    </div>
    <div className={bem('birthdays-list-container')} />
  </aside>
);
