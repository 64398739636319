import { STATUS } from '@src/hoc/StatusSwitch';

export const sendCommentFulfilled = (state, action) => {
  if (state.activePost) {
    state.activePost.commentOutputList = action.payload.data;
    state.activePost.commentCount += 1;
  }
  const postsList = state.posts ? state.posts : state.searchedPosts.results;
  const pinPostsList = state.pinnedPosts ? state.pinnedPosts : state.searchedPosts.results;
  if (postsList.length > 0) {
    const index = postsList.findIndex((post) => post.postId === action.payload.postId);
    if (index >= 0) {
      postsList[index].commentOutputList = action.payload.data;
      postsList[index].commentCount += 1;
    }
  }
  if (pinPostsList.length > 0) {
    const indexPin = pinPostsList.findIndex((post) => post.postId === action.payload.postId);
    if (indexPin >= 0) {
      pinPostsList[indexPin].commentOutputList = action.payload.data;
      pinPostsList[indexPin].commentCount += 1;
    }
  }
  state.sendCommentStatus = STATUS.DONE;
};

export const deleteCommentFulfilled = (state, action) => {
  const postsList = state.posts ? state.posts : state.searchedPosts.results;
  const pinPostsList = state.pinnedPosts ? state.pinnedPosts : state.searchedPosts.results;
  const delComm = (post) => {
    for (const comment of post.commentOutputList) {
      if (comment.commentId === action.payload.commentId) {
        post.commentOutputList[post.commentOutputList.indexOf(comment)].deleted = true;
        post.commentCount -= 1;
      }
    }
  };
  const index = postsList.findIndex((post) => post.postId === action.payload.postId);
  if (state.activePost && Object.keys(state.activePost).length) {
    delComm(state.activePost);
  }
  const indexPin = pinPostsList.findIndex((post) => post.postId === action.payload.postId);
  if (state.activePost && Object.keys(state.activePost).length) {
    delComm(state.activePost);
  }

  if (postsList.length > 0 && index >= 0) {
    delComm(postsList[index]);
  }
  if (pinPostsList.length > 0 && indexPin >= 0) {
    delComm(pinPostsList[indexPin]);
  }
  state.deleteCommentStatus = STATUS.DONE;
};

export const restoreCommentFulfilled = (state, action) => {
  const postsList = state.posts ? state.posts : state.searchedPosts.results;
  const pinPostsList = state.pinnedPosts ? state.pinnedPosts : state.searchedPosts.results;
  const index = postsList.findIndex((post) => post.postId === action.payload.postId);
  const indexPin = pinPostsList.findIndex((post) => post.postId === action.payload.postId);
  if (postsList.length > 0 && index >= 0) {
    for (const comment of postsList[index].commentOutputList) {
      if (comment.commentId === action.payload.commentId) {
        postsList[index].commentOutputList[postsList[index].commentOutputList.indexOf(comment)].deleted = false;
        postsList[index].commentCount += 1;
      }
    }
  }
  if (pinPostsList.length > 0 && indexPin >= 0) {
    for (const comment of pinPostsList[indexPin].commentOutputList) {
      if (comment.commentId === action.payload.commentId) {
        pinPostsList[indexPin].commentOutputList[
          pinPostsList[indexPin].commentOutputList.indexOf(comment)
        ].deleted = false;
        pinPostsList[indexPin].commentCount += 1;
      }
    }
  }
  state.restoreCommentStatus = STATUS.DONE;
};

export const deletePostFulfilled = (state, action) => {
  const postsList = state.posts ? state.posts : state.searchedPosts.results;
  const deferredPostsList = state?.defferedPosts;
  if (deferredPostsList) {
    const filteredDeferredPosts = deferredPostsList.filter((post) => post.postId !== action.payload.postId);
    state.defferedPosts = filteredDeferredPosts;
  }
  const filteredPosts = postsList.filter((post) => post.postId !== action.payload.postId);
  state.posts ? (state.posts = filteredPosts) : (state.searchedPosts.results = filteredPosts);
  const pinPostsList = state?.pinnedPosts ? state.pinnedPosts : state.searchedPosts.results;
  const filteredPinPosts = pinPostsList.filter((post) => post.postId !== action.payload.postId);
  state?.pinnedPosts ? (state.pinnedPosts = filteredPinPosts) : (state.searchedPosts.results = filteredPinPosts);
  state.deletePostStatus = STATUS.DONE;
  state.defferedStatus = STATUS.DONE;
};

export const addLikesFulfilled = (state, action) => {
  const postsList = state.posts ? state.posts : state.searchedPosts.results;
  const pinPostsList = state.pinnedPosts ? state.pinnedPosts : state.searchedPosts.results;
  const index = postsList.findIndex((post) => post.postId === action.payload.postId);
  const indexPin = pinPostsList.findIndex((post) => post.postId === action.payload.postId);
  if (postsList.length > 0 && index >= 0) {
    postsList[index].likes = action.payload.likes;
  }
  if (pinPostsList.length > 0 && indexPin >= 0) {
    pinPostsList[indexPin].likes = action.payload.likes;
  }
};

export const addViewsFulfilled = (state, action) => {
  const postsList = state.posts ? state.posts : state.searchedPosts.results;
  const pinPostsList = state?.pinnedPosts ? state.pinnedPosts : state?.searchedPosts?.results;
  const index = postsList.findIndex((post) => post.postId === action.payload.postId);
  const indexPin = pinPostsList.findIndex((post) => post.postId === action.payload.postId);
  if (postsList.length > 0 && index >= 0) {
    if (postsList[index].views) postsList[index].views += 1;
  }
  if (pinPostsList.length > 0 && indexPin >= 0) {
    if (pinPostsList[indexPin].views) pinPostsList[indexPin].views += 1;
  }
};
