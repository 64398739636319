import React from 'react';


const OwnerIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} height={height} style={style} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect width="16" height="16" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1714 2.37654L14.3905 7.95214L11.1714 13.5277L4.73324 13.5277L1.51417 7.95214L4.73324 2.37654L11.1714 2.37654ZM10.5425 3.46578L5.36211 3.46578L2.77191 7.95214L5.36211 12.4385L10.5425 12.4385L13.1327 7.95214L10.5425 3.46578Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6318 4.7836L13.6318 11.225L8.0041 14.4408L2.37637 11.225L2.37637 4.78359L8.0041 1.56775L13.6318 4.7836ZM12.5426 5.4157L8.0041 2.82228L3.46561 5.4157L3.46561 10.5929L8.0041 13.1863L12.5426 10.5929L12.5426 5.4157Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01403 4.83966L10.7399 6.41346L10.7259 9.57576L7.99426 11.1691L5.26835 9.59531L5.28243 6.433L8.01403 4.83966ZM8.01684 6.09902L6.36889 7.06027L6.3604 8.96806L7.99146 9.90975L9.6394 8.9485L9.6479 7.04071L8.01684 6.09902Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OwnerIcon;
