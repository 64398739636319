import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, request } from '@src/app/api';
import { delayStopper } from '@utils/delay';
import { STATUS } from '@src/hoc/StatusSwitch';
import { addingPostWithPicStatuses } from '@utils/constants';
import { message } from 'antd';
import {
  addLikesFulfilled,
  addViewsFulfilled,
  deleteCommentFulfilled,
  deletePostFulfilled,
  restoreCommentFulfilled,
  sendCommentFulfilled,
} from '@utils/postActionsHandlers';
import moment from 'moment';

const initialState = {
  activePost: {},
  activePostStatus: STATUS.NO_STATUS,
  addPinPostStatus: STATUS.NO_STATUS,
  addPostStatus: STATUS.NO_STATUS,
  commentsListStatus: STATUS.NO_STATUS,
  defferedPosts: [],
  defferedStatus: STATUS.NO_STATUS,
  deleteCommentStatus: STATUS.NO_STATUS,
  deletePictureStatus: STATUS.NO_STATUS,
  deletePostStatus: STATUS.NO_STATUS,
  deleteTagStatus: STATUS.NO_STATUS,
  deleteVoteStatus: STATUS.NO_STATUS,
  editPostStatus: STATUS.NO_STATUS,
  filesCount: 10,
  firstPostID: null,
  isEditPost: null,
  isNotification: false,
  isOpenVoteForm: false,
  isOpenYouTubeForm: false,
  lastPostID: '',
  listStatus: STATUS.NO_STATUS,
  newPosts: [],
  pinListStatus: STATUS.NO_STATUS,
  pinnedPosts: [],
  posts: [],
  postTagStatus: STATUS.NO_STATUS,
  postToLoadingCount: 4,
  putVoteStatus: STATUS.NO_STATUS,
  restoreCommentStatus: STATUS.NO_STATUS,
  sendCommentStatus: STATUS.NO_STATUS,
  statusOfAddingPostWithPic: STATUS.NO_STATUS,
  tags: [],
  tagsList: [],
};

export const getPost = createAsyncThunk('getPost', async (id) => {
  const response = await request.get(api.post(id));

  return response.data;
});

export const putVote = createAsyncThunk('putVote', async ({ answerId, postId }) => {
  const response = await request.put(api.vote(answerId));
  return { data: response.data, postId };
});

export const deleteVote = createAsyncThunk('deleteVote', async ({ answerId, postId }) => {
  const response = await request.delete(api.deleteVote(answerId));
  return { data: response.data, postId, answerId };
});

export const checkNewPosts = createAsyncThunk('checkNewPosts', async (_, { getState }) => {
  const { firstPostID } = getState().postsList;
  const response = await request.get(api.fewPosts(), {
    params: {
      afterId: '',
      size: 1,
    },
  });

  const post = response.data[0]?.isPinned ? response.data[1] : response.data[0];

  return { data: post, firstPostID };
});

export const getFewPosts = createAsyncThunk('getFewPosts', async ({ size }, { getState }) => {
  const response = await request.get(api.fewPosts(), { params: { size } });
  const { firstPostID } = getState().postsList;

  const filteredPosts = response.data.filter((post) => !post.isPinned);

  return { data: filteredPosts, firstPostID };
});

export const getMorePosts = createAsyncThunk('getMorePosts', async ({ size }, { getState }) => {
  const { lastPostID, firstPostID } = getState().postsList;
  const response = await request.get(api.fewPosts(), {
    params: {
      afterId: lastPostID,
      size,
    },
  });

  const filteredPosts = response.data.filter((post) => !post.isPinned);

  return { data: filteredPosts, firstPostID };
});

export const postPinningMode = createAsyncThunk('postPinningMode', async (postId) => {
  await request.post(api.pinPost(postId));

  const response = await request.get(api.post(postId)); // Хак для получения валидного адреса с бэка

  return response.data;
});

export const getPinnedPosts = createAsyncThunk('getPinnedPosts', async () => {
  const response = await request.get(api.pinnedPosts());
  return response.data;
});

export const getDefferedPosts = createAsyncThunk('getDefferedPosts', async () => {
  const response = await request.get(api.postsDeffered());
  return response.data;
});

export const updatePosts = createAsyncThunk('updatePosts', async ({ size }, { dispatch }) => {
  dispatch(getPinnedPosts());

  const response = await request.get(api.fewPosts(), {
    params: {
      afterId: '',
      size,
    },
  });
  return response.data.filter((post) => !post.isPinned);
});

export const getTags = createAsyncThunk('getTags', async () => {
  const response = await request.get(api.tags());
  const sortTags = response.data.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });

  return sortTags;
});

export const addTag = createAsyncThunk('addTag', async (_data) => {
  const response = await request.post(api.tags(), _data);
  const sortTags = response.data.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
  return sortTags;
});

export const deleteTag = createAsyncThunk('deleteTag', async (id) => {
  const response = await request.delete(api.tagDelete(id));

  return response.data;
});

export const addPost = createAsyncThunk('addPost', async (_data) => {
  const response = await request.post(api.posts(), _data);
  await delayStopper(300);

  return response.data;
});

export const deletePost = createAsyncThunk('deletePost', async (id) => {
  const response = await request.delete(api.postDelete(id));

  await delayStopper(300);

  return response.data;
});

export const sendComment = createAsyncThunk('sendComment', async (_body) => {
  const response = await request.post(api.comments(), {
    body: _body.commentText,
    postId: _body.postId,
  });

  return { data: response.data, postId: _body.postId };
});

export const getComments = createAsyncThunk('getComments', async (_body) => {
  const response = await request.get(api.commentsSearch(), {
    params: {
      postId: _body.postId,
    },
  });
  return {
    postId: _body.postId,
    data: response.data,
  };
});

export const deleteComment = createAsyncThunk('deleteComment', async (commentId) => {
  const response = await request.delete(api.commentsDelete(commentId));

  return response.data;
});

export const addPicture = createAsyncThunk('addPicture', async ({ file }) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await request.post(api.photos(), formData, {
    params: {
      label: 'POST_PICTURE',
    },
    headers: {
      contentType: 'multipart/form-data',
    },
  });

  return response.data;
});

export const deletePicture = createAsyncThunk('deletePicture', async (pictureId) => {
  const response = await request.delete(api.onePhoto(pictureId));

  return response.data;
});

export const restoreComment = createAsyncThunk('restoreComment', async (commentId) => {
  const response = await request.post(api.commentsRestore(commentId));

  return response.data;
});

export const addLikes = createAsyncThunk('addLikes', async (data) => {
  const { postId, debouncedNumberOfLikesToAdd } = data;
  const response = await request.post(api.likes(), {
    postId,
    like: debouncedNumberOfLikesToAdd >= 50 ? 50 : debouncedNumberOfLikesToAdd,
  });

  return { likes: response.data, postId };
});

export const addViews = createAsyncThunk('addViews', async ({ postId }) => {
  const response = await request.post(api.postViews(postId));

  return { views: response.data, postId };
});

export const editPost = createAsyncThunk('editPost', async (_data) => {
  const response = await request.put(api.postEdit(_data?.id), {
    content: _data?.content,
    textContent: _data?.textContent,
    tags: _data?.tags,
    picturesId: _data?.picturesId,
    publicationDate: _data?.publicationDate,
  });

  await delayStopper(300);

  return response.data;
});

export const publishDeferredPost = createAsyncThunk('publishDeferredPost', async (_data) => {
  const response = await request.put(api.postEdit(_data?.id), {
    content: _data?.content,
  });

  await delayStopper(300);

  return response.data;
});

export const postsListSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    clearPost(state) {
      state.post = null;
    },
    setPostPicturesLoading(state) {
      state.addPostStatus = STATUS.LOADING;
      if (state.statusOfAddingPostWithPic === STATUS.NO_STATUS) {
        state.statusOfAddingPostWithPic = addingPostWithPicStatuses.image.loading;
      }
    },
    setPostPicturesLoaded(state) {
      if (state.statusOfAddingPostWithPic === addingPostWithPicStatuses.image.loading) {
        state.statusOfAddingPostWithPic = addingPostWithPicStatuses.image.loaded;
      }
    },
    setPostPicturesError(state) {
      state.addPostStatus = STATUS.NO_STATUS;
      if (state.statusOfAddingPostWithPic === addingPostWithPicStatuses.image.loading) {
        state.statusOfAddingPostWithPic = addingPostWithPicStatuses.image.error;
      }
    },
    setIsOpenVoteForm(state) {
      state.isOpenVoteForm = !state.isOpenVoteForm;
    },
    setIsOpenYouTubeForm(state) {
      state.isOpenYouTubeForm = !state.isOpenYouTubeForm;
    },
    setPostWithPictureLoading(state) {
      if (state.statusOfAddingPostWithPic === addingPostWithPicStatuses.image.loaded) {
        state.statusOfAddingPostWithPic = addingPostWithPicStatuses.post.loading;
      }
    },
    setPostWithPictureLoaded(state) {
      if (state.statusOfAddingPostWithPic === addingPostWithPicStatuses.post.loading) {
        state.statusOfAddingPostWithPic = addingPostWithPicStatuses.post.loaded;
      }
    },
    setIsEditPost(state, action) {
      state.isEditPost = action.payload;
    },
  },
  extraReducers: {
    [deleteVote.pending]: (state) => {
      state.deleteVoteStatus = STATUS.LOADING;
    },
    [deleteVote.rejected]: (state) => {
      state.deleteVoteStatus = STATUS.ERROR;
    },
    [deleteVote.fulfilled]: (state, action) => {
      const changeSelectedPost = (posts) =>
        posts.map((post) =>
          post.postId === action.payload.postId
            ? {
                ...post,
                voteResponse: {
                  ...post.voteResponse,
                  answers: post.voteResponse.answers.map((answer) =>
                    answer.selected ? { ...answer, selected: false } : answer
                  ),
                },
              }
            : post
        );

      if (state.pinnedPosts[0]?.postId === action.payload.postId) {
        state.pinnedPosts = changeSelectedPost(state.pinnedPosts);
      } else {
        state.posts = changeSelectedPost(state.posts);
        const [activePostResult] = changeSelectedPost([state.activePost]);
        state.activePost = activePostResult;
      }
      state.deleteVoteStatus = STATUS.DONE;
    },
    [putVote.pending]: (state) => {
      state.putVoteStatus = STATUS.LOADING;
    },
    [putVote.fulfilled]: (state, action) => {
      if (state.pinnedPosts[0]?.postId === action.payload.postId) {
        state.pinnedPosts[0].voteResponse = action.payload.data;
      } else {
        state.posts = state.posts.map((post) =>
          post.postId === action.payload.postId ? { ...post, voteResponse: action.payload.data } : post
        );
        state.activePost = { ...state.activePost, voteResponse: action.payload.data };
      }
      state.putVoteStatus = STATUS.DONE;
    },
    [putVote.rejected]: (state) => {
      state.putVoteStatus = STATUS.ERROR;
    },
    [getPost.pending]: (state) => {
      state.activePostStatus = STATUS.LOADING;
    },
    [getPost.fulfilled]: (state, action) => {
      state.activePost = action.payload;
      state.activePostStatus = STATUS.DONE;
    },
    [getPost.rejected]: (state) => {
      state.activePostStatus = STATUS.ERROR;
    },
    [getFewPosts.pending]: (state) => {
      state.listStatus = STATUS.LOADING;
    },
    [getFewPosts.fulfilled]: (state, action) => {
      if (!action.payload.firstPostID) {
        state.firstPostID = action.payload.data[0]?.postId;
        state.posts = [...state.posts, ...action.payload.data];
      }
      action.payload.data.forEach((post, index) => {
        if (index === action.payload.data.length - 1) {
          state.lastPostID = post.postId;
        }
      });
      state.newPosts = action.payload.data;
      state.listStatus = STATUS.DONE;
    },

    [getMorePosts.fulfilled]: (state, action) => {
      action.payload.data.forEach((post, index) => {
        if (index === action.payload.data.length - 1) {
          state.lastPostID = post.postId;
        }
      });
      state.newPosts = action.payload.data;
      state.posts.push(...action.payload.data);
    },

    [checkNewPosts.fulfilled]: (state, action) => {
      if (
        action.payload.data?.postId !== action.payload.firstPostID &&
        action.payload.data?.postId > action.payload.firstPostID
      ) {
        state.firstPostID = action.payload.firstPostID;
        state.isNotification = true;
      }
    },
    [getFewPosts.rejected]: (state) => {
      state.listStatus = STATUS.ERROR;
    },
    [getPinnedPosts.pending]: (state) => {
      state.pinListStatus = STATUS.LOADING;
    },
    [getPinnedPosts.fulfilled]: (state, action) => {
      state.pinnedPosts = [...action.payload];
      state.pinListStatus = STATUS.DONE;
    },
    [getPinnedPosts.rejected]: (state) => {
      state.pinListStatus = STATUS.ERROR;
    },
    [postPinningMode.pending]: (state) => {
      state.pinListStatus = STATUS.LOADING;
      state.addPinPostStatus = STATUS.LOADING;
    },
    [postPinningMode.rejected]: (state) => {
      state.addPinPostStatus = STATUS.ERROR;
    },
    [postPinningMode.fulfilled]: (state, action) => {
      if (action.payload.isPinned) {
        state.pinnedPosts.length === 0
          ? (state.posts = [...state.posts])
          : (state.posts = [{ ...state.pinnedPosts[0], isPinned: false }, ...state.posts].sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            ));
        state.pinnedPosts = [
          {
            ...action.payload,
          },
        ];
        state.posts = state.posts.filter((post) => post.postId !== action.payload.postId);
      }
      if (!action.payload.isPinned) {
        state.pinnedPosts = [];
        state.posts = [
          {
            ...action.payload,
          },
          ...state.posts,
        ].sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      state.pinListStatus = STATUS.DONE;
      state.addPinPostStatus = STATUS.DONE;
    },
    [getDefferedPosts.fulfilled]: (state, action) => {
      state.defferedPosts = action.payload;
      state.defferedStatus = STATUS.DONE;
    },
    [getDefferedPosts.pending]: (state) => {
      state.defferedStatus = STATUS.LOADING;
    },
    [getDefferedPosts.rejected]: (state) => {
      state.defferedStatus = STATUS.ERROR;
    },
    [getTags.fulfilled]: (state, action) => {
      state.tagsList = action.payload.map((tag) => tag.name);
      state.tags = action.payload;
    },
    [addTag.pending]: (state) => {
      state.postTagStatus = STATUS.LOADING;
    },
    [addTag.rejected]: (state) => {
      state.postTagStatus = STATUS.ERROR;
    },
    [addTag.fulfilled]: (state) => {
      state.postTagStatus = STATUS.DONE;
    },
    [deleteTag.pending]: (state) => {
      state.deleteTagStatus = STATUS.LOADING;
    },
    [deleteTag.rejected]: (state) => {
      state.deleteTagStatus = STATUS.ERROR;
    },
    [deleteTag.fulfilled]: (state, action) => {
      state.tagsList = state.tagsList.filter((item) => item.id !== action.id);
      state.deleteTagStatus = STATUS.DONE;
    },
    [addPost.pending]: (state) => {
      state.listStatus = STATUS.LOADING;
      state.addPostStatus = STATUS.LOADING;
    },
    [addPost.rejected]: (state) => {
      state.addPostStatus = STATUS.ERROR;
    },
    [addPost.fulfilled]: (state, action) => {
      const currentDate = moment().utc().format();
      if (currentDate < action.payload?.date) {
        state.defferedPosts = [action.payload, ...state.defferedPosts];
      } else {
        state.posts = [action.payload, ...state.posts];
      }
      state.listStatus = STATUS.DONE;
      state.addPostStatus = STATUS.DONE;
      state.firstPostID = action.payload.postId;
    },
    [updatePosts.fulfilled]: (state, action) => {
      action.payload.forEach((post, index) => {
        if (index === action.payload.length - 1) {
          state.lastPostID = post.postId;
        }
      });
      state.isNotification = false;
      state.firstPostID = action.payload[0]?.postId;
      state.posts = action.payload;
    },
    [addPicture.pending]: (state) => {
      state.listStatus = STATUS.LOADING;
    },
    [addPicture.fulfilled]: (state) => {
      state.listStatus = STATUS.DONE;
    },
    [addPicture.rejected]: (state) => {
      state.listStatus = STATUS.ERROR;
    },
    [deletePost.pending]: (state) => {
      state.deletePostStatus = STATUS.LOADING;
    },
    [deletePost.fulfilled]: (state, action) => {
      deletePostFulfilled(state, action);
    },
    [deletePost.rejected]: (state) => {
      state.deletePostStatus = STATUS.ERROR;
      message.error('Ошибка в удалении записи!');
    },
    [sendComment.pending]: (state) => {
      state.sendCommentStatus = STATUS.LOADING;
    },
    [sendComment.fulfilled]: (state, action) => {
      sendCommentFulfilled(state, action);
    },
    [sendComment.rejected]: (state) => {
      state.sendCommentStatus = STATUS.ERROR;
    },
    [getComments.fulfilled]: (state, action) => {
      if (state.activePost) {
        state.activePost.commentOutputList = action.payload.data;
      }
      if (state.posts.length > 0) {
        const index = state.posts.findIndex((post) => post.postId === action.payload.postId);
        if (index !== -1) state.posts[index].commentOutputList = action.payload.data;
      }
      if (state.pinnedPosts.length > 0) {
        const indexPin = state.pinnedPosts.findIndex((post) => post.postId === action.payload.postId);
        if (indexPin !== -1) state.pinnedPosts[indexPin].commentOutputList = action.payload.data;
      }
      state.commentsListStatus = STATUS.DONE;
    },
    [getComments.pending]: (state) => {
      state.commentsListStatus = STATUS.LOADING;
    },
    [getComments.rejected]: (state) => {
      state.commentsListStatus = STATUS.ERROR;
    },
    [deleteComment.fulfilled]: (state, action) => {
      deleteCommentFulfilled(state, action);
    },
    [deleteComment.pending]: (state) => {
      state.deleteCommentStatus = STATUS.LOADING;
    },
    [deleteComment.rejected]: (state) => {
      state.deleteCommentStatus = STATUS.ERROR;
    },
    [restoreComment.fulfilled]: (state, action) => {
      restoreCommentFulfilled(state, action);
    },
    [restoreComment.pending]: (state) => {
      state.restoreCommentStatus = STATUS.LOADING;
    },
    [restoreComment.rejected]: (state) => {
      state.restoreCommentStatus = STATUS.ERROR;
    },
    [addLikes.fulfilled]: (state, action) => {
      addLikesFulfilled(state, action);
    },
    [addLikes.rejected]: () => {
      message.error('Ошибка сети!');
    },
    [addViews.fulfilled]: (state, action) => {
      addViewsFulfilled(state, action);
    },
    [addViews.rejected]: () => {
      message.error('Ошибка сети!');
    },
    [editPost.pending]: (state) => {
      state.editPostStatus = STATUS.LOADING;
    },
    [editPost.rejected]: (state) => {
      state.editPostStatus = STATUS.ERROR;
    },
    [editPost.fulfilled]: (state, action) => {
      let postsList = state.posts ? state.posts : state.searchedPosts.results;
      const checkIsPostDeferred = (obj) => obj.postId === action.payload.postId;
      if (state.defferedPosts.some(checkIsPostDeferred)) postsList = state.defferedPosts;
      const index = postsList.findIndex((post) => post.postId === action.payload.postId);
      const indexPinnedPost = state.pinnedPosts.findIndex((post) => post.postId === action.payload.postId);
      if (state.activePost) state.activePost = action.payload;
      if (state.defferedPosts.some(checkIsPostDeferred)) {
        state.defferedPosts[index] = action.payload;
      } else {
        state.posts[index] = action.payload;
        state.pinnedPosts[indexPinnedPost] = action.payload;
      }
      state.editPostStatus = STATUS.DONE;
    },
    [publishDeferredPost.pending]: (state) => {
      state.defferedStatus = STATUS.LOADING;
    },
    [publishDeferredPost.rejected]: (state) => {
      state.defferedStatus = STATUS.ERROR;
    },
    [publishDeferredPost.fulfilled]: (state, action) => {
      const deferredPostsList = state.defferedPosts;
      const index = deferredPostsList.findIndex((post) => post.postId === action.payload.postId);
      deferredPostsList.splice(index, 1);
      state.posts = [action.payload, ...state.posts];
      state.defferedStatus = STATUS.DONE;
    },
    [deletePicture.fulfilled]: (state) => {
      state.deletePictureStatus = STATUS.LOADING;
    },
    [deletePicture.pending]: (state) => {
      state.deletePictureStatus = STATUS.LOADING;
    },
    [deletePicture.rejected]: (state) => {
      state.deletePictureStatus = STATUS.ERROR;
    },
  },
});

export const {
  clearPost,
  setPostPicturesLoaded,
  setPostPicturesError,
  setPostPicturesLoading,
  setPostWithPictureLoaded,
  setPostWithPictureLoading,
  setIsOpenVoteForm,
  setIsOpenYouTubeForm,
  setIsEditPost,
} = postsListSlice.actions;

export const addPostWithPicture = (_data) => async (dispatch) => {
  dispatch(setPostPicturesLoading());
  const postImagesID = [];
  let hasError = false;
  for (const file of _data.files) {
    if (file.size >= _data.maxImageSizeKB) {
      dispatch(setPostPicturesError());
      hasError = true;
      break;
    } else {
      await dispatch(addPicture({ file })).then((response) => {
        postImagesID.push(response.payload.photoId);
      });
    }
  }
  if (hasError) {
    message.warning(`Максимальный размер загружаемого файла равен ${_data.maxImageSize} МБ`);
  } else {
    dispatch(setPostPicturesLoaded());
    dispatch(setPostWithPictureLoading());
    dispatch(
      addPost({
        content: _data.content,
        textContent: _data.textContent,
        tags: _data.tags,
        picturesId: postImagesID,
        publicationDate: _data.publicationDate,
      })
    );
  }
};

export const editPostWithPicture = (_data) => async (dispatch) => {
  const postImagesID = _data.pictures.map((picture) => picture.pictureId);
  let hasError = false;
  for (const file of _data.files) {
    if (file.size >= _data.maxImageSizeKB) {
      hasError = true;
      break;
    } else {
      await dispatch(addPicture({ file })).then((response) => {
        postImagesID.push(response.payload.photoId);
      });
    }
  }
  if (hasError) {
    message.warning(`Максимальный размер загружаемого файла равен ${_data.maxImageSize} МБ`);
  } else {
    dispatch(
      editPost({
        id: _data.id,
        content: _data.content,
        textContent: _data.textContent,
        tags: _data.tags,
        picturesId: postImagesID,
        publicationDate: _data?.publicationDate,
      })
    );
  }
};

export const addPostStatus = (state) => state.postsList.addPostStatus;
export const editPostStatus = (state) => state.postsList.editPostStatus;
export const filesCount = (state) => state.postsList.filesCount;
export const isOpenVoteForm = (state) => state.postsList.isOpenVoteForm;
export const isOpenYouTubeForm = (state) => state.postsList.isOpenYouTubeForm;
export const pinPosts = (state) => state.postsList.pinnedPosts;
export const pinPostsStatus = (state) => state.postsList.pinListStatus;
export const selectActivePost = (state) => state.postsList.activePost;
export const selectActivePostStatus = (state) => state.postsList.activePostStatus;
export const selectDefferedPosts = (state) => state.postsList.defferedPosts;
export const selectDefferedPostsStatus = (state) => state.postsList.defferedStatus;
export const selectDeleteCommentStatus = (state) => state.postsList.deleteCommentStatus;
export const selectIsEditPost = (state) => state.postsList.isEditPost;
export const selectLastPostID = (state) => state.postsList.lastPostID;
export const selectNewPosts = (state) => state.postsList.newPosts;
export const selectPosts = (state) => state.postsList.posts;
export const selectPostToLoadingCount = (state) => state.postsList.postToLoadingCount;
export const selectSendCommentStatus = (state) => state.postsList.sendCommentStatus;
export const selectStatus = (state) => state.postsList.listStatus;
export const selectTags = (state) => state.postsList.tags;
export const selectTagsList = (state) => state.postsList.tagsList;
export const setIsNotification = (state) => state.postsList.isNotification;

export default postsListSlice.reducer;
