/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-multi-assign */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable no-return-assign */
/* eslint-disable default-case */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-underscore-dangle */
import phoneImg from '@assets/images/phone.svg';
import React, { useEffect } from 'react';
import './SnakeGame.scss';

export const SnakeGame = () => {
  useEffect(() => {
    console.log(1);

    const ngIsMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      );
    let ngWindowWidth = document.documentElement.clientWidth;
    const ngBreakpoint = 768;
    let ngInitState = false;
    let ngShowState = false;

    const initSnake = () => {
      if (ngWindowWidth >= ngBreakpoint && !ngIsMobileDevice && !ngInitState && !ngShowState) {
        ngInitState = true;
        ngShowState = true;
        document.getElementsByClassName('ng')[0].style.display = 'block';

        // ------- console -------
        const _consoleArt_1 = `color: #058c8d;
    font-weight: 600;
    font-size: 16px;
    `;
        const _consoleArt_2 = `color: #C72C68;
    font-weight: 600;
    font-size: 16px;
    `;

        console.info(
          '%c Приветствую тебя, юный исследователь нашего сайта. Напиши нам и ты не останешься без печенья - ',
          _consoleArt_1
        );
        console.info('%c - hr@irlix.com', _consoleArt_2);
        // ------- console -------

        function getEl(str) {
          return document.getElementsByClassName(str);
        }

        function getElID(str) {
          return document.getElementById(str);
        }

        // NODEs
        const blindModeNode = getElID('blindMode');
        const canvas = getElID('canvas-snake');
        const canvas_bg = getElID('canvas-snake-bg');
        const canvas_overlay = getElID('canvas-snake-overlay');
        const ctx = canvas.getContext('2d');
        // const ctx_bg = canvas_bg.getContext('2d');
        const ctx_overlay = canvas_overlay.getContext('2d');
        const deletedNodesOnStart = getEl('delete-on-start');
        const closePhoneBtn = getEl('ng-close-phone-btn')[0];
        const closeResultBtn = getEl('ng-close-result-btn')[0];
        const edge = getElID('edge');
        const elemNgResultsNode = getElID('ngResults-table');
        // const inputNameNode = getEl('ng-nickname-input')[0];
        const levelNode = getElID('level');
        const pixelRatio = window.devicePixelRatio || 1;
        const renameBtnNode = getEl('ngResults__request-btn')[0];
        const renameInputNode = getEl('ngResults__request-input')[0];
        const scoreNode = getElID('score');
        const startBtnsNode = getEl('start-buttons')[0];
        const UUID = generateUID();
        const ngResultsInputTitleNode = getEl('ngResults__input-title')[0];
        const ngResultsNode = getEl('ngResults')[0];
        const ngResultsRequestNode = getEl('ngResults__request')[0];
        const phone = getEl('ng-phone')[0];
        // const openPhoneBtn = getEl('ng-open-phone-btn')[0];
        // const transformPhoneStart = elemComputedStyle(phone, 'transform');
        // phone
        // const pxTransformPhoneStart = new WebKitCSSMatrix(transformPhoneStart).m42;

        function openPhoneSnake() {
          setStyle(phone, 'transform', 'translate(0, 0)');
          closePhoneBtn.style.display = 'inline-block';
        }

        function closePhoneSnake() {
          pauseGame();
          // closePhoneBtn.style.display = 'none';
          // setStyle(phone, 'transform', `translate(0, ${pxTransformPhoneStart}px)`);
        }

        function setStyle(elem, prop, value) {
          elem.style[prop] = value;
        }

        // function elemComputedStyle(elem, prop = null) {
        //   return window.getComputedStyle(elem).getPropertyValue(prop);
        // }

        // game loop
        let last = performance.now();
        let dt = 0;
        let now;
        let gameIsActive = true;
        let gameIsLose = false;
        const gameSettings = {
          speed: 0,
          difficult: '',
          blindMode: false,
          gameID: 0,
        };

        // Величины
        const M = 24 * pixelRatio; // метр =12
        // const SEC = 60;
        const grid = {
          x: 14,
          y: 15,
        };

        const _step = Math.floor((grid.x * grid.y) / 17);
        // const levelSteps = {
        //   [1 * _step]: 'Student',
        //   [2 * _step]: 'Junior --',
        //   [3 * _step]: 'Junior -',
        //   [4 * _step]: 'Junior',
        //   [5 * _step]: 'Junior +',
        //   [6 * _step]: 'Junior ++',
        //   [7 * _step]: 'Middle --',
        //   [8 * _step]: 'Middle -',
        //   [9 * _step]: 'Middle',
        //   [10 * _step]: 'Middle +',
        //   [11 * _step]: 'Middle ++',
        //   [12 * _step]: 'Senior --',
        //   [13 * _step]: 'Senior -',
        //   [14 * _step]: 'Senior',
        //   [15 * _step]: 'Senior +',
        //   [16 * _step]: 'Senior ++',
        //   [17 * _step]: 'Expert',
        // };

        function getLevel(length) {
          switch (true) {
            case length < 1 * _step:
              return 'student';
            case length < 2 * _step:
              return 'junior --';
            case length < 3 * _step:
              return 'junior -';
            case length < 4 * _step:
              return 'junior';
            case length < 5 * _step:
              return 'junior +';
            case length < 6 * _step:
              return 'junior ++';
            case length < 7 * _step:
              return 'middle --';
            case length < 8 * _step:
              return 'middle -';
            case length < 9 * _step:
              return 'middle';
            case length < 10 * _step:
              return 'middle +';
            case length < 11 * _step:
              return 'middle ++';
            case length < 12 * _step:
              return 'senior --';
            case length < 13 * _step:
              return 'senior -';
            case length < 14 * _step:
              return 'senior';
            case length < 15 * _step:
              return 'senior +';
            case length < 16 * _step:
              return 'senior ++';
            case length < 17 * _step:
              return 'expert';

            default:
              return 'unknown level';
          }
        }

        canvas.width = canvas_overlay.width = canvas_bg.width = M * grid.x;
        canvas.height = canvas_overlay.height = canvas_bg.height = M * grid.y;
        edge.style.width = `${canvas.width / pixelRatio}px`;
        edge.style.height = `${canvas.height / pixelRatio}px`;

        canvas.style.transform = `scale(${1 / pixelRatio})`;
        canvas_overlay.style.transform = `scale(${1 / pixelRatio})`;
        canvas_bg.style.transform = `scale(${1 / pixelRatio})`;

        const snake = {
          data: [{ x: 14 * M, y: 10 * M }],
          direction: 'right',
          lastDirection: 'right',
          score: 1,
          level: '',
        };
        const food = {
          data: [],
          // color: '200, 20, 30',
          cost: 1,
        };

        const foodArr = [
          {
            kind: 'Frontend',
            score: 0,
            color: '#6600CC',
            skills: ['js', 'html', 'css'],
          },
          {
            kind: 'Backend',
            score: 0,
            color: '#3333CC',
            skills: ['C#', 'java', 'Go', 'C++', 'sql'],
          },
          {
            kind: 'QA',
            score: 0,
            color: '#3399CC',
            skills: ['bug', 'чгк'],
          },
          {
            kind: 'HR',
            score: 0,
            color: '#009999',
            skills: ['hh', 'sms'],
          },
          {
            kind: 'Analytic',
            score: 0,
            color: '#339900',
            skills: ['BPM', 'UML', 'IDEF'],
          },
          {
            kind: 'Designer',
            score: 0,
            color: '#CC6633',
            skills: ['art', 'ux'],
          },
        ];

        const progress = {};
        foodArr.forEach((element) => {
          progress[element.kind] = 0;
        });

        let sortedProgress = sortObj(progress);

        function getStep(difficult) {
          const FPS = difficult * 1.2; // фпс движка
          return 1 / FPS; // update should be called 60 times per second
        }

        // helps
        function getRandomIntInclusive(min, max) {
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(Math.random() * (max - min + 1)) + min; // Максимум и минимум включаются
        }

        function sortObj(obj) {
          return Object.keys(obj).sort((a, b) => obj[b] - obj[a]);
        }

        function generateUID() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
        }

        function checkValidInput(str) {
          if (str === '') {
            return false;
          }

          if (str.match(/[>,<,\/,;,:,",']/g)) {
            return false;
          }

          return true;
        }

        function sesameStreet(data) {
          let street = 0;
          Object.values(data).forEach((e) => {
            String(e)
              .split('')
              .forEach((ch) => {
                street += ch.charCodeAt();
              });
          });

          return Number(street).toString(16); // 1b1d14fe
        }

        function deleteDescriptionScreen() {
          for (let i = 0; i < deletedNodesOnStart.length; i++) {
            const element = deletedNodesOnStart[i];
            element.style.display = 'none';
          }
        }

        function pauseGame() {
          if (!gameIsLose) {
            if (!gameIsActive) {
              gameIsActive = true;
              ctx_overlay.clearRect(0, 0, canvas.width, canvas.height);
              requestAnimationFrame(() => callback(getStep(gameSettings.speed)));
            } else {
              gameIsActive = false;

              ctx_overlay.fillStyle = '#000000aa';
              ctx_overlay.rect(0, 0, canvas.width, canvas.height);
              ctx_overlay.fill();

              ctx_overlay.fillStyle = '#fff';
              ctx_overlay.font = `${M}pt Arial`;
              ctx_overlay.textBaseline = 'middle';
              ctx_overlay.textAlign = 'center';
              ctx_overlay.fillText('Pause', canvas.width / 2, canvas.height / 2);
            }
          }
        }

        function gameOver(foodType = false) {
          gameIsActive = false;
          gameIsLose = true;
          ctx_overlay.fillStyle = '#000000aa';
          ctx_overlay.rect(0, 0, canvas.width, canvas.height);
          ctx_overlay.fill();

          ctx_overlay.fillStyle = '#fff';
          ctx_overlay.font = `${M}pt Arial`;
          ctx_overlay.textBaseline = 'middle';
          ctx_overlay.textAlign = 'center';

          if (foodType) {
            ctx_overlay.fillText(`Game Over - не ешь ${foodType.name}!`, canvas.width / 2, canvas.height / 2);
          } else {
            ctx_overlay.fillText(`Game Over`, canvas.width / 2, canvas.height / 2);
          }

          gameSettings.gameID = sesameStreet({
            difficult: gameSettings.difficult,
            level: snake.level,
            spec: sortedProgress[0],
            userUUID: UUID,
            score: snake.score,
          });

          postResult({
            difficult: gameSettings.difficult,
            level: snake.level,
            spec: sortedProgress[0],
            userUUID: UUID,
            score: snake.score,
            gameId: gameSettings.gameID,
          });

          console.info('Your secret stats:', progress);
        }

        function createFoodPointData(knowKind = false) {
          let type;
          let element;

          if (knowKind) {
            element = foodArr.filter((a) => a.kind === knowKind)[0]; // забираем объект с этим типом
            type = element.skills[getRandomIntInclusive(0, element.skills.length - 1)];
          } else {
            element = foodArr[getRandomIntInclusive(0, foodArr.length - 1)];
            type = element.skills[getRandomIntInclusive(0, element.skills.length - 1)];
          }

          return {
            x: getRandomIntInclusive(0, grid.x - 1) * M,
            y: getRandomIntInclusive(0, grid.y - 1) * M,
            type,
            color: element.color,
            kind: element.kind,
          };
        }

        function createNewFood() {
          let newPoint;
          const countFood = getRandomIntInclusive(2, 5);
          food.data.length = 0;

          for (let i = 0; i < countFood; i++) {
            if (i < 2) {
              newPoint = createFoodPointData(sortedProgress[i]);

              while (compareWithTail(newPoint)) {
                newPoint = createFoodPointData(sortedProgress[i]);
              }
            } else {
              newPoint = createFoodPointData();

              while (compareWithTail(newPoint)) {
                newPoint = createFoodPointData();
              }
            }

            food.data[i] = newPoint;
          }
        }

        function compareWithTail(head) {
          for (let i = 0; i < snake.data.length; i++) {
            const element = snake.data[i];
            if (head.x === element.x && head.y === element.y) {
              return true;
            }
          }
          return false;
        }

        function eatFood(head) {
          for (let i = 0; i < food.data.length; i++) {
            const element = food.data[i];
            if (head.x === element.x && head.y === element.y) {
              return i + 1; // хак на приведение типов с 0
            }
          }
          return false;
        }

        function updateSnake() {
          const oldHeadX = snake.data[0].x;
          const oldHeadY = snake.data[0].y;
          const newHead = { x: oldHeadX, y: oldHeadY };

          // расчет следующего шага змейки (голова)
          switch (true) {
            case snake.direction === 'right':
              newHead.x = oldHeadX + M;
              break;

            case snake.direction === 'left':
              newHead.x = oldHeadX - M;
              break;

            case snake.direction === 'top':
              newHead.y = oldHeadY - M;
              break;

            case snake.direction === 'bottom':
              newHead.y = oldHeadY + M;
              break;
          }

          // Запоминаем направление последнего
          snake.lastDirection = snake.direction;

          // проверка на границу экрана
          switch (true) {
            case newHead.x / M >= grid.x:
              newHead.x = 0;
              break;

            case newHead.x / M < 0:
              newHead.x = grid.x * M;
              break;

            case newHead.y / M >= grid.y:
              newHead.y = 0;
              break;

            case newHead.y / M < 0:
              newHead.y = grid.y * M;
              break;
          }

          // проверка поедания себя
          if (compareWithTail(newHead)) {
            gameOver();
          }

          // добавляем голову
          snake.data.unshift(newHead);

          // проверка еды и обрезание хвоста
          const id = eatFood(newHead);
          if (id) {
            updateScore(food.data[id - 1].kind);
            createNewFood();
          } else {
            snake.data.pop();
          }
        }

        function updateScore(foodKind) {
          const l = snake.data.length;
          progress[foodKind] += 1;
          sortedProgress = sortObj(progress);
          snake.score = snake.score + food.cost + l;
          snake.level = getLevel(l);

          scoreNode.innerText = snake.score;
          l > 4 ? (levelNode.innerText = `${sortedProgress[0]}, ${snake.level}`) : (levelNode.innerText = snake.level);
        }

        // renders
        function renderSnake() {
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          for (let i = 0; i < snake.data.length; i++) {
            const position = snake.data[i];
            ctx.save();
            ctx.translate(position.x + 1, position.y + 1);
            ctx.fillStyle = `rgb(${255 - i * 3}, ${255 - i * 1}, ${255 - i * 1})`;
            ctx.fillRect(0, 0, M - 1, M - 1);
            ctx.restore();
          }

          renderFood();
        }

        function renderFood() {
          for (let i = 0; i < food.data.length; i++) {
            const item = food.data[i];
            ctx.save();
            ctx.translate(item.x, item.y);

            if (item.color) {
              ctx.fillStyle = item.color;
              ctx.fillRect(0, 0, M, M);
            }

            if (!gameSettings.blindMode) {
              ctx.fillStyle = '#fff';
              ctx.font = `${M / 3}pt Arial`;
              ctx.textBaseline = 'middle';
              ctx.textAlign = 'center';
              ctx.fillText(item.type, M / 2, M / 2);
            }

            ctx.restore();
          }
        }

        // function renderBg() {
        //   ctx_bg.fillStyle = `#B4D1BF`;
        //   ctx_bg.fillRect(0, 0, canvas.width, canvas.height);

        //   ctx_bg.fillStyle = `rgba(62, 142, 0, 0.02)`;
        //   for (let i = 0; i < grid.x; i++) {
        //     if (i % 2) {
        //       ctx_bg.save();
        //       ctx_bg.translate(i * M - M, 0);
        //       ctx_bg.fillRect(0, 0, M, canvas.height);
        //       ctx_bg.restore();
        //     }
        //   }

        //   ctx_bg.fillStyle = `rgba(63, 162, 1, 0.02)`;
        //   for (let i = 0; i < grid.y; i++) {
        //     if (i % 2) {
        //       ctx_bg.save();
        //       ctx_bg.translate(0, i * M - M);
        //       ctx_bg.fillRect(0, 0, canvas.width, M);
        //       ctx_bg.restore();
        //     }
        //   }
        // }

        // управление
        function keyPress(event) {
          const snakeIsLong = snake.data.length === 1;
          const SD = snake.lastDirection;

          if (
            (event.code === 'ArrowLeft' || event.code === 'KeyA') &&
            ((SD !== 'right' && SD !== 'left') || snakeIsLong)
          ) {
            snake.direction = 'left';
          } else if (
            (event.code === 'ArrowUp' || event.code === 'KeyW') &&
            ((SD !== 'bottom' && SD !== 'top') || snakeIsLong)
          ) {
            snake.direction = 'top';
          } else if (
            (event.code === 'ArrowRight' || event.code === 'KeyD') &&
            ((SD !== 'left' && SD !== 'right') || snakeIsLong)
          ) {
            snake.direction = 'right';
          } else if (
            (event.code === 'ArrowDown' || event.code === 'KeyS') &&
            ((SD !== 'top' && SD !== 'bottom') || snakeIsLong)
          ) {
            snake.direction = 'bottom';
          } else if (event.code === 'Space') {
            pauseGame();
          }
        }

        const callback = (step) => {
          if (gameIsActive && !gameIsLose) {
            now = performance.now();
            dt += (now - last) / 1000;

            //  если дельта меньше шага не обновляем движок
            if (dt > step) {
              if (dt > step * 3) {
                dt = step;
              }
              dt -= step;
              updateSnake();
            }
            last = now;

            // Рисуем по возможностям
            renderSnake();

            requestAnimationFrame(() => callback(step));
          }
        };

        // START GAME UI
        function startTheGame(difficult) {
          document.addEventListener('keydown', keyPress);
          createNewFood();
          requestAnimationFrame(() => callback(getStep(difficult)));
        }

        startBtnsNode.addEventListener('click', (e) => {
          const { difficult } = e.target.dataset;
          if (difficult) {
            switch (difficult) {
              case 'easy':
                gameSettings.speed = 3;
                food.cost = 7;
                gameSettings.difficult = 'easy';

                startTheGame(gameSettings.speed);
                break;

              case 'normal':
                gameSettings.speed = 6;
                food.cost = 19;
                gameSettings.difficult = 'normal';
                foodArr.forEach((element) => {
                  element.color = '#444';
                });

                startTheGame(gameSettings.speed);
                break;

              case 'hard':
                gameSettings.speed = 11;
                food.cost = 37;
                gameSettings.difficult = 'hard';
                foodArr.forEach((element) => {
                  element.color = '';
                });

                startTheGame(gameSettings.speed);
                break;
            }
          }

          deleteDescriptionScreen();
        });

        // openPhoneBtn.onclick = () => {
        // const transformPhone = elemComputedStyle(phone, 'transform');
        // const pxTransformPhone = new WebKitCSSMatrix(transformPhone).m42;
        // pxTransformPhone ?  : closePhoneSnake();
        openPhoneSnake();
        // };

        blindModeNode.onclick = () => {
          gameSettings.blindMode = !gameSettings.blindMode;
          blindModeNode.classList.toggle('active');
        };

        renameBtnNode.onclick = () => {
          const value = String(renameInputNode.value).trim();

          if (checkValidInput(value)) {
            renameName({
              gameId: gameSettings.gameID,
              name: value,
              userUUID: UUID,
            });
          } else {
            renameInputNode.style.borderColor = 'red';
          }
        };

        closePhoneBtn.onclick = () => {
          closePhoneSnake();
        };

        closeResultBtn.onclick = () => {
          ngResultsNode.style.display = 'none';
        };

        // Async
        // async function getRandomName() {
        //   const response = await fetch('http://192.168.88.11:8082/name/random');
        //   const result = await response.text(); // случайное имя
        //   inputNameNode.value = result;
        // }
        // getRandomName();

        async function postResult(data) {
          const response = await fetch('https://snake.irlix.com:8082/score', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(data),
          });

          const result = await response.json();
          ngResultsNode.style.display = 'block';
          elemNgResultsNode.insertAdjacentHTML('afterbegin', tableNgResults(result));
          phone.style.display = 'none';
          document.getElementsByClassName('me')[0].scrollIntoView({ block: 'center' });
        }

        async function renameName(data) {
          const response = await fetch('https://snake.irlix.com:8082/score/name', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(data),
          });

          const result = await response.json();
          elemNgResultsNode.innerHTML = '';
          ngResultsRequestNode.style.display = 'none';
          ngResultsInputTitleNode.style.display = 'none';
          elemNgResultsNode.insertAdjacentHTML('afterbegin', tableNgResults(result));
          document.getElementsByClassName('me')[0].scrollIntoView();
        }

        // TABLE ----------------------------------------------
        function tableNgResults(array) {
          let elemDiv = '<div class="table">';
          let table = '';
          let yourPlace;

          // fix array data
          array.forEach((obj, i) => {
            array[i] = {
              place: i + 1,
              ...array[i],
            };

            if (UUID === array[i].userUUID) {
              yourPlace = i;
            }

            delete array[i].userUUID;
          });

          function addTh(items) {
            let tr = '<tr>';
            Object.keys(items).forEach((i) => (tr += `<th>${i}</th>`));
            tr += '</tr>';
            return tr;
          }

          function addTd(items, cssClass = '') {
            let tr;

            cssClass ? (tr = `<tr class='${cssClass}'>`) : (tr = `<tr'>`);

            Object.values(items).forEach((i) => (tr += `<td>${String(i).replace(/[<,>]/g, '')}</td>`));
            tr += '</tr>';
            return tr;
          }

          table += `<table><thead>${addTh(array[0])}</thead><tbody>`;

          array.forEach((i, ind) => {
            if (ind === yourPlace) {
              table += addTd(i, 'me');
            } else {
              table += addTd(i);
            }
          });

          elemDiv += `${table}</tbody></table></div>`;
          return elemDiv;
        }
        // !TABLE ----------------------------------------------!
      }

      if (ngWindowWidth >= ngBreakpoint && !ngIsMobileDevice && ngInitState && !ngShowState) {
        document.getElementsByClassName('ng')[0].style.display = 'block';
      }
    };
    const disableSnake = () => {
      if (ngWindowWidth < ngBreakpoint || ngIsMobileDevice) {
        document.getElementsByClassName('ng')[0].style.display = 'none';
        ngShowState = false;
      }
    };
    initSnake();
    disableSnake();
    window.onresize = () => {
      ngWindowWidth = document.documentElement.clientWidth;
      initSnake();
      disableSnake();
    };
  }, []);

  return (
    <>
      <div className="ngResults">
        <button type="button" className="ng-close-result-btn">
          x Закрыть
        </button>
        <h2 className="ngResults__heading">Таблица результатов</h2>
        <div id="ngResults-table" />
        <h4 className="ngResults__input-title">Вы можете использовать другое имя</h4>
        <div className="ngResults__request">
          <input className="ngResults__request-input" type="text" placeholder="Введите ник сохранения результата" />
          <button className="ngResults__request-btn" type="button">
            Сохранить результат
          </button>
        </div>
        <p>Обновите страницу что бы сыграть еще раз</p>
      </div>
      <aside className="ng">
        <div className="ng-phone">
          <button type="button" className="ng-close-phone-btn">
            Пауза
          </button>

          <img className="ng-phone-img" src={phoneImg} alt="" />
          <canvas id="canvas-snake-bg" width="300" height="192">
            Canvas
          </canvas>
          <canvas id="canvas-snake" width="300" height="192">
            Canvas
          </canvas>
          <canvas id="canvas-snake-overlay" width="300" height="192">
            Canvas
          </canvas>

          <div className="score-wrapper">
            Счёт:
            <span id="score"> 0 </span>
          </div>

          <div id="edge">
            <h2 className="difficult delete-on-start">Выберите сложность</h2>
            <div className="start-buttons delete-on-start">
              <button className="d-button" type="button" data-difficult="easy">
                Junior
              </button>
              <button className="d-button" type="button" data-difficult="normal">
                Middle
              </button>
              <button className="d-button" type="button" data-difficult="hard">
                Senior
              </button>
            </div>

            <div className="delete-on-start">
              <h2 className="ng-inputs-h">Управление</h2>
              <p className="ng-inputs-text">Передвижение 1 - "W, A, S, D"</p>
              <p className="ng-inputs-text">Передвижение 2 - "⬆, ⬇, ⬅, ➡"</p>
              <p className="ng-inputs-text">Пауза - "Пробел"</p>
            </div>
          </div>

          <button className="blind-mode" id="blindMode" type="button">
            Режим ниндзя
          </button>
          <div className="level-wrapper" id="level">
            Ваш уровень
          </div>
        </div>
      </aside>
    </>
  );
};
