import React from 'react';
import { cn } from '@utils/bem-config';
import { SvgIcon } from '@components/common/SvgIcon';
import './PhotoNewPost.scss';

const bem = cn('img-block');

export const PhotoNewPost = ({ photo, deleteHandler, editMode, isPicture }) => (
  <div className={bem({ editMode })}>
    <div className={bem('delete-div')} onClick={deleteHandler}>
      <SvgIcon type="close" />
    </div>
    <img
      className={bem('img-photo')}
      src={isPicture ? photo.url : window.URL.createObjectURL(photo)}
      alt="Выбранное фото"
    />
  </div>
);
