import React from 'react';

const ExclamationPoint = ({
  style = {},
  fill = 'var(--c-accent)',
  width = '38',
  height = '38',
  className = '',
  viewBox = '0 0 38 38'
}) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none"
    xmlns="http://www.w3.org/2000/svg">

    <path d="M4.29297 10.6568L18.9062 2.21979L33.5195 10.6568V27.5307L18.9062 35.9677L4.29297 27.5307V10.6568Z"
      stroke={fill} strokeWidth="2.4375" />
    <path
      d="M20.125 10.5625C20.125 10.1315 19.9538 9.7182 19.649 9.41345C19.3443 9.10871 18.931 8.9375 18.5 8.9375C18.069 8.9375 17.6557 9.10871 17.351 9.41345C17.0462 9.7182 16.875 10.1315 16.875 10.5625V21.9375C16.875 22.3685 17.0462 22.7818 17.351 23.0865C17.6557 23.3913 18.069 23.5625 18.5 23.5625C18.931 23.5625 19.3443 23.3913 19.649 23.0865C19.9538 22.7818 20.125 22.3685 20.125 21.9375V10.5625Z"
      fill={fill} />
    <path
      d="M18.5 26.8125C18.931 26.8125 19.3443 26.9837 19.649 27.2885C19.9538 27.5932 20.125 28.0065 20.125 28.4375C20.125 28.8685 19.9538 29.2818 19.649 29.5865C19.3443 29.8913 18.931 30.0625 18.5 30.0625C18.069 30.0625 17.6557 29.8913 17.351 29.5865C17.0462 29.2818 16.875 28.8685 16.875 28.4375C16.875 28.0065 17.0462 27.5932 17.351 27.2885C17.6557 26.9837 18.069 26.8125 18.5 26.8125Z"
      fill={fill} />
  </svg>
);
export default ExclamationPoint;
