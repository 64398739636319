import React from 'react';


const CommentIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.50256H14.9998V12.5024H4.94157L1 14.4732V2.50256ZM2.19998 3.70254V12.5316L4.6583 11.3024H13.7998V3.70254H2.19998Z"
      fill={fill}
    />
  </svg>
);

export default CommentIcon;
