import { AddCommentForm } from '@components/common/AddCommentForm';
import { CommentsList } from '@components/common/CommentsList/CommentsList';
import { DeletingPostForm } from '@components/common/DeletingPostForm';
import { EditablePostContent } from '@components/common/EditablePostContent/EditablePostContent';
import { FeedbackPostButtons } from '@components/common/FeedbackPostButtons';
import { PostContent } from '@components/common/PostContent';
import { PostHeader } from '@components/common/PostHeader';
import {
  postPinningMode,
  setIsEditPost,
  deleteVote,
  publishDeferredPost,
} from '@components/common/PostsList/postsListSlice';
import { SelectForPost } from '@components/common/SelectForPost';
import { SvgIcon } from '@components/common/SvgIcon';
import { selectRoles, selectUserLdapId } from '@src/appSlice';
import { getFiredUser, selectAllUsers, selectUser } from '@src/components/common/UsersList/usersListSlice';
import { cn } from '@utils/bem-config';
import { useIsMount } from '@utils/useIsMount';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './Post.scss';
import { PostAvatarHeader } from '@components/common/PostAvatarHeader';
import { BigMobileQuery, LittleTabletQuery } from '@utils/responsive';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { Button as ButtonFromScroll, Button } from '@components/common/Button';
import { Modal } from '@components/common/Modal';

const bem = cn('post');

export const Post = ({ data, isLoading = false, postIsActive, pinsLength, isEditPost, isDeffered }) => {
  const dispatch = useDispatch();
  const UsersList = useSelector(selectAllUsers);
  const currentUserLdapId = useSelector(selectUserLdapId);
  const currentUserRoles = useSelector(selectRoles);
  const userFromState = useSelector(selectUser);
  const [commentsIsOpen, setCommentsIsOpen] = useState(false);
  const [isDeletingPost, setIsDeletingPost] = useState(false);
  const [pinnedMode, setPinnedMode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDeletingFormSticky, setIsDeletingFormSticky] = useState(false);
  const commentInputRef = useRef();
  const postRef = useRef();
  const isMount = useIsMount();
  const url = `/users/${data?.ldapId}`;
  const history = useHistory();
  const user = UsersList[0].find((userItem) => userItem.ldapId === data?.ldapId) ?? userFromState;
  let dataSelectedVoice;
  if (data?.postType === 'VOTE') {
    dataSelectedVoice = data.voteResponse.answers.find((item) => item.selected);
  }

  useEffect(() => {
    if (data?.ldapId && !user) {
      dispatch(getFiredUser(data.ldapId));
    }
  }, [data?.ldapId, user]);

  useEffect(() => {
    if (isMount && commentInputRef?.current) commentInputRef.current.focus();
  }, [commentsIsOpen]);

  useEffect(() => {
    if (isDeletingPost === true) {
      postRef?.current?.offsetHeight > window.innerHeight * 0.8
        ? setIsDeletingFormSticky(true)
        : setIsDeletingFormSticky(false);
    }
  }, [isDeletingPost]);

  const goBackToPosts = () => {
    if (history.location.state?.fromScroll) {
      history.goBack();
    } else {
      history.push('/feed');
    }
  };
  const activateEditMode = () => {
    dispatch(setIsEditPost(data.postId));
  };
  const activateViewMode = () => {
    dispatch(setIsEditPost(null));
  };

  const handlePinnedPost = () => {
    dispatch(postPinningMode(data.postId));
    setPinnedMode(false);
  };

  const deleteVoteHandler = (answerId) => {
    dispatch(deleteVote({ answerId, postId: data.postId }));
  };

  const handlePublication = () => {
    dispatch(
      publishDeferredPost({
        id: data?.postId,
        content: data?.content,
      })
    );
  };

  const publicationModalContent = (
    <div className={bem('buttons')}>
      <ButtonFromScroll type="button" onClick={handlePublication}>
        Абсолютно
      </ButtonFromScroll>
      <ButtonAnimated onClick={() => setModalIsOpen(false)} type="cancel">
        Отмена
      </ButtonAnimated>
    </div>
  );

  return (
    <>
      {postIsActive && (
        <button className={bem('back-btn')} onClick={goBackToPosts}>
          <SvgIcon type="arrow" className={bem('back-btn-icon')} /> Ко всем новостям
        </button>
      )}
      <article className={bem({ editing: isEditPost })} ref={postRef}>
        <LittleTabletQuery>
          <PostAvatarHeader url={url} isLoading={isLoading} userPhoto={user?.photos?.avatar?.url} />
        </LittleTabletQuery>
        <div className={bem('content', { editing: isEditPost })}>
          <LittleTabletQuery>
            <PostHeader data={data} user={user} url={url} isLoading={isLoading} postIsActive={postIsActive} />
          </LittleTabletQuery>
          <BigMobileQuery>
            <PostHeader data={data} user={user} url={url} isLoading={isLoading} postIsActive={postIsActive} mobile />
          </BigMobileQuery>
          {!isLoading && (
            <div className={bem('select-button-container')}>
              <SelectForPost
                data={data}
                activateEditMode={activateEditMode}
                handlePinnedPost={handlePinnedPost}
                pinnedMode={pinnedMode}
                setPinnedMode={setPinnedMode}
                isDeleting={setIsDeletingPost}
                isEditPost={isEditPost}
                deleteVote={deleteVoteHandler}
                enableDeleteVoice={dataSelectedVoice?.selected}
                isDeffered={isDeffered}
              />
            </div>
          )}
          <DeletingPostForm
            data={data}
            setIsDeletingPost={setIsDeletingPost}
            isDeletingPost={isDeletingPost}
            isDeletingFormSticky={isDeletingFormSticky}
            postIsActive={postIsActive}
          />
          {isEditPost ? (
            <EditablePostContent data={data} activateViewMode={activateViewMode} editMode={isEditPost} />
          ) : (
            <PostContent data={data} isLoading={isLoading} dataSelectedVoice={dataSelectedVoice} />
          )}
          {isDeffered ? (
            !isEditPost && (
              <>
                <hr className={bem('hr-line', { half: true })} />
                <div className={bem('publication-block')}>
                  <Button
                    onClick={() => {
                      setModalIsOpen(true);
                    }}
                  >
                    Опубликовать сейчас
                  </Button>
                </div>
              </>
            )
          ) : (
            <>
              <hr className={bem('hr-line', { half: true })} />
              <FeedbackPostButtons
                data={data}
                commentInputRef={commentInputRef}
                isLoading={isLoading}
                setCommentsIsOpen={setCommentsIsOpen}
                handlePinnedPost={handlePinnedPost}
                pinnedMode={pinnedMode}
                setPinnedMode={setPinnedMode}
                pinsLength={pinsLength}
              />
              <CommentsList
                data={data}
                commentsIsOpen={commentsIsOpen}
                currentUserLdapId={currentUserLdapId}
                currentUserRoles={currentUserRoles}
                setCommentsIsOpen={setCommentsIsOpen}
              />
              <AddCommentForm data={data} commentInputRef={commentInputRef} commentsIsOpen={commentsIsOpen} />
            </>
          )}
        </div>
      </article>
      {modalIsOpen && (
        <Modal
          content={publicationModalContent}
          closeHandler={() => setModalIsOpen(false)}
          title="Вы уверены, что хотите опубликовать сейчас?"
        />
      )}
    </>
  );
};
