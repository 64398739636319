import React from 'react';
import { Progress, Radio } from 'antd';
import { cn } from '@utils/bem-config';
import './VoteAnswers.scss';

const bem = cn('vote-answers');

export const VoteAnswers = ({ voteAnswers, onChange, voteNumber, voteId, isVoted }) => (
  <div className={bem({ voted: isVoted })}>
    <Radio.Group className={bem('group')} onChange={onChange} value={voteId}>
      {voteAnswers.map((answer) => {
        const percent = (answer.count * 100) / voteNumber;

        return (
          <Radio key={`vote${answer.id}`} className={bem('item', { disabled: isVoted })} value={answer.id}>
            <div className={bem('content')}>
              <p className={bem('text')}>{answer.text}</p>
              <p className={bem('percent', { show: isVoted })}>{percent ? percent.toFixed(1) : 0}%</p>
            </div>
            <Progress className={bem('progress', { show: isVoted })} percent={percent} showInfo={false} />
          </Radio>
        );
      })}
    </Radio.Group>
  </div>
);
