import React from 'react';
import { Post } from '@components/common/Post';
import { useSelector } from 'react-redux';
import { selectIsEditPost } from '@components/common/PostsList/postsListSlice';

export const DefferedPostsListOnDone = ({ posts, pinsLength = 0 }) => {
  const isEditPost = useSelector(selectIsEditPost);
  return (
    <>
      {posts.length === 0 && ''}
      {posts.length > 0 && (
        <>
          {posts.map((post) => (
            <Post
              key={post.postId}
              data={post}
              pinsLength={pinsLength}
              isEditPost={isEditPost === post.postId}
              isDeffered
            />
          ))}
        </>
      )}
    </>
  );
};
