import React from "react";


export const DotsIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '13', height = '3', className = '', viewBox = '0 0 13 3' }) => (
<svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill={fill} xmlns="http://www.w3.org/2000/svg">
  <circle cx="1.5" cy="1.5" r="1.5" fill={fill}/>
  <circle cx="6.5" cy="1.5" r="1.5" fill={fill}/>
  <circle cx="11.5" cy="1.5" r="1.5" fill={fill}/>
</svg>
);
export default DotsIcon;
