import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllUsers,
  setUserCoords,
  selectUsersCoordsStatus,
  setCoordsStatus,
} from '@components/common/UsersList/usersListSlice';
import { apiMapKey } from '@src/utils/constants';
import { cn } from '@utils/bem-config';
import { STATUS } from '@src/hoc/StatusSwitch';
import { Preloader } from '@src/components/common/Preloader';
import { ClustererLayoutEmployees } from './ClustererLayoutEmployees/ClustererLayoutEmployees';

const bem = cn('geography-employees');

export const GeographyEmployees = () => {
  const dispatch = useDispatch();
  const allUsers = useSelector(selectAllUsers)[0];
  const statusUsersCoords = useSelector(selectUsersCoordsStatus);
  const currentUsers = allUsers
    .filter((user) => !user.delete)
    .sort((a, b) => {
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    });
  const [map, setMap] = useState(null);
  const [uniqueCities, setUniqueCities] = useState([]);

  useEffect(() => {
    const cities = currentUsers.reduce((a, c) => {
      if (c.city) {
        a.add(c.city);
      }
      return a;
    }, new Set());
    setUniqueCities([...cities]);
  }, []);

  useEffect(() => {
    if (map) {
      dispatch(setUserCoords({ ymapsApi: map, uniqueCities }));
    }
  }, [map]);

  useEffect(
    () => () => {
      dispatch(setCoordsStatus('no-status'));
    },
    []
  );

  const mapDefaultState = {
    center: [55.75, 37.57],
    zoom: 4,
    controls: ['geolocationControl', 'fullscreenControl', 'zoomControl', 'typeSelector'],
  };
  const onLoadMap = (ymaps) => {
    setMap(ymaps);
  };

  return (
    <div className={bem()}>
      {statusUsersCoords === STATUS.LOADING && <Preloader mode="map" />}
      <YMaps
        query={{
          load: 'package.full',
          apikey: apiMapKey,
        }}
      >
        <div>
          <Map defaultState={mapDefaultState} width="100%" height="45rem" onLoad={onLoadMap}>
            <ClustererLayoutEmployees map={map}>
              {currentUsers.map(
                (user) =>
                  user?.cityGeo && (
                    <Placemark
                      key={user.userId}
                      geometry={user.cityGeo}
                      properties={{
                        balloonContentHeader: user.city,
                        balloonContentBody: `${user.lastName} ${user.firstName}`,
                        balloonContentLdapId: `${user.ldapId}`,
                      }}
                    />
                  )
              )}
            </ClustererLayoutEmployees>
          </Map>
        </div>
      </YMaps>
    </div>
  );
};
