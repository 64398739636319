const spacePattern = '\\s*';
const numberPattern = '(\\-*[\\d\\.]+)';

const twoNumbersRowPattern = (numberPattern + spacePattern).repeat(2);
const sixNumbersRowPattern = (numberPattern + spacePattern).repeat(6);

const moveToPattern = `M${spacePattern}${twoNumbersRowPattern}`;
const curvePattern = `C${spacePattern}${sixNumbersRowPattern}`;

const twoCurvesRowPattern = curvePattern.repeat(2);

export const pathRegExp = new RegExp(moveToPattern + twoCurvesRowPattern, 'g');
