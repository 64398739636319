import { CommonTooltip } from '@components/common/CommonTooltip';
import { DropdownMenuCategories } from '@components/common/DropdownMenuCategories/DropdownMenuCategories';
import { PhotoNewPost } from '@components/common/PhotoNewPost';
import { ReactQuillEditor } from '@components/common/ReactQuillEditor';
import { SvgIcon } from '@components/common/SvgIcon';
import {
  addPost,
  addPostStatus,
  addPostWithPicture,
  filesCount,
  isOpenVoteForm,
  isOpenYouTubeForm,
  selectTagsList,
  setIsOpenVoteForm,
  setIsOpenYouTubeForm,
} from '@src/components/common/PostsList/postsListSlice';
import { insertUserLinkInText } from '@src/utils/insertUserLinkInText';
import { useHuntedUser } from '@src/hooks/useHuntedUser';
import { STATUS } from '@src/hoc/StatusSwitch';
import { cn } from '@src/utils/bem-config';
import { maxImageSize, maxImageSizeKB, maxLengthMsg } from '@utils/constants';
import { isObject } from '@utils/utils';
import { isFileTypeValid } from '@utils/validationParameters';
import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@components/common/Modal';
import { useHistory } from 'react-router-dom';
import './NewPostForm.scss';
import { NewVoteForm } from '@components/common/NewVoteForm/NewVoteForm';
import { NewYoutubeForm } from '@components/common/NewYoutubeForm/NewYoutubeForm';
import { DropdownFoundUsers } from '@components/common/DropdownFoundUsers/DropdownFoundUsers';
import { DropdownPublication } from '@components/common/DropdownPublication/DropdownPublication';

const bem = cn('new-post-form');

export const NewPostForm = () => {
  const dispatch = useDispatch();
  const [postContent, setPostContent] = useState('');
  const [publicationDate, setPublicationDate] = useState(null);
  const [tagType, setTagType] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [files, setFiles] = useState([]);
  const [dropDownPosition, setDropDownPosition] = useState({});
  const [charactersLeft, setCharactersLeft] = useState(maxLengthMsg);
  const [isCharactersLeftValid, setisCharactersLeftValid] = useState(true);
  const { isTypeAt, foundUsersList, huntedUser, resetFoundUsers, getCurrentPosition } = useHuntedUser(postContent);
  const contentInputRef = useRef();
  const fileInput = useRef();
  const postAddingStatus = useSelector(addPostStatus);
  const countOfFiles = useSelector(filesCount);
  const tags = useSelector(selectTagsList);
  const isOpenVote = useSelector(isOpenVoteForm);
  const isOpenYouTube = useSelector(isOpenYouTubeForm);
  const textContent = contentInputRef?.current?.getEditor()?.getText();
  const history = useHistory();

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.maxHeight = isOpenVote ? '100vh' : 'none';
    body.style.overflow = isOpenVote ? 'hidden' : '';
  }, [isOpenVote]);

  useEffect(() => {
    if (postAddingStatus === STATUS.DONE) {
      setPostContent('');
      setFiles([]);
    }
  }, [postAddingStatus]);

  useEffect(() => {
    if (textContent) {
      setCharactersLeft(maxLengthMsg - textContent.length);
    }
  }, [textContent]);

  useEffect(() => {
    charactersLeft > 0 ? setisCharactersLeftValid(true) : setisCharactersLeftValid(false);
  }, [charactersLeft]);

  const handleSubmit = () => {
    if (textContent?.trim()) {
      if (files.length === 0) {
        dispatch(
          addPost({
            content: postContent,
            tags: tagType,
            textContent,
            publicationDate,
          })
        );
      } else if (postAddingStatus !== STATUS.LOADING) {
        dispatch(
          addPostWithPicture({
            files,
            content: postContent,
            tags: tagType,
            textContent,
            publicationDate,
            maxImageSize,
            maxImageSizeKB,
          })
        );
      }
    } else {
      contentInputRef.current.focus();
    }
  };

  const getCurrentSymbolPosition = () => {
    const rect = getCurrentPosition();
    setDropDownPosition({ left: rect.left, top: rect.top });
  };

  const handleCtrlEnter = (e) => {
    getCurrentSymbolPosition();

    if (e.which === 13 && e.ctrlKey) handleSubmit();
  };

  const startCreatePost = () => {
    setIsCreating(true);
  };

  const handleChange = (value) => {
    setPostContent(value);
  };

  const handleAddFiles = () => {
    fileInput.current.click();
  };

  const updatePhotosState = (key) => {
    const file = fileInput.current.files[key];
    setFiles((prevState) => [...prevState, file]);
  };

  const savePhoto = () => {
    if (files.length + fileInput?.current?.files.length <= countOfFiles) {
      if (fileInput?.current?.files) {
        Array.from(fileInput.current.files).forEach((file, keyOfFile) => {
          if (!isFileTypeValid(file)) {
            message.error(`Загружаемое изображение ${file.name} неправильного формата.`);
            return;
          }
          if (isObject(file) && file) {
            updatePhotosState(keyOfFile);
          }
        });
      }
    } else {
      message.error(`Максимальное количество изображений в посте: ${countOfFiles}`);
    }
  };

  const goToAdminPage = () => {
    history.push('/administration');
  };

  const deleteHandler = (name) => {
    const arrayFiles = [...files];
    files.forEach((file, index) => {
      if (file.name === name) {
        arrayFiles.splice(index, 1);
        setFiles(arrayFiles);
      }
    });
  };

  const modifyPostContent = (e) => {
    const modifyContent = insertUserLinkInText(huntedUser, postContent, e.target.id, e.target.innerText);
    setPostContent(modifyContent);
    resetFoundUsers();
  };

  const handleDateChange = (value) => {
    setPublicationDate(value);
  };

  return (
    <>
      {isOpenVote && <Modal content={<NewVoteForm />} closeHandler={() => dispatch(setIsOpenVoteForm())} />}
      {isOpenYouTube && <Modal content={<NewYoutubeForm />} closeHandler={() => dispatch(setIsOpenYouTubeForm())} />}

      <form className={bem({ isCreating })}>
        {isCreating && (
          <div className={bem('validation-bar', { 'is-error': !isCharactersLeftValid })}>
            <span>Осталось символов: </span>
            <span>{charactersLeft}</span>
          </div>
        )}

        <div className={bem('column')} onClick={startCreatePost}>
          <ReactQuillEditor
            handleChange={handleChange}
            postContent={postContent}
            handleCtrlEnter={handleCtrlEnter}
            contentInputRef={contentInputRef}
          />
          {isTypeAt && (
            <DropdownFoundUsers
              dropDownPosition={dropDownPosition}
              huntedUser={huntedUser}
              users={foundUsersList}
              modifyPostContent={modifyPostContent}
            />
          )}

          {files.length > 0 && (
            <div className={bem('photos-part')}>
              {files.map((item) => (
                <PhotoNewPost key={item.size} photo={item} deleteHandler={() => deleteHandler(item.name)} />
              ))}
            </div>
          )}
          <hr className={bem('hr-line')} />
          <div className={bem('actions-bar')}>
            <div className={bem('theme-button')}>
              <DropdownMenuCategories
                data={tags}
                currentData={tagType}
                setCurrentData={setTagType}
                functionOnClick={goToAdminPage}
              />
              <input
                className={bem('input-file')}
                accept="image/*"
                multiple
                id="contained-button-file"
                type="file"
                ref={fileInput}
                onChange={savePhoto}
              />
              <div className={bem('vert-line')} />
              <DropdownPublication functionOnSelect={handleDateChange} />
              <div className={bem('vert-line')} />
              <button
                type="button"
                className={bem('add-photo-button')}
                value="Прикрепить фото"
                onClick={handleAddFiles}
              >
                <SvgIcon type="addImage" />
              </button>
            </div>
            <CommonTooltip title="Опубликовать">
              <button
                type="button"
                className={bem('submit-btn', { disabled: !textContent?.trim() || !isCharactersLeftValid })}
                value="Опубликовать"
                onClick={handleSubmit}
              >
                <SvgIcon type="send" />
              </button>
            </CommonTooltip>
          </div>
        </div>
      </form>
    </>
  );
};
