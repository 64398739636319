import React from 'react';

const CloseIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg className={className} width={width} style={style} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.18792 8.00002L3 3.81211L3.81211 3L8.00002 7.18792L12.1879 3L13 3.81211L8.81213 8.00002L13 12.1879L12.1879 13L8.00002 8.81213L3.81211 13L3 12.1879L7.18792 8.00002Z"
      fill={fill}
    />
  </svg>
);
export default CloseIcon;
