import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { useDispatch, useSelector } from 'react-redux';
import { apiMapKey } from '@src/utils/constants';
import { cn } from '@utils/bem-config';
import { STATUS } from '@src/hoc/StatusSwitch';
import { Preloader } from '@src/components/common/Preloader';
import { ClustererLayoutOffices } from './ClustererLayoutOffices/ClustererLayoutOffices';
import {
  getOfficesList,
  selectOfficesList,
  setOfficesCoords,
  selectOfficesCoordsStatus,
} from './geographyOfficesSlice';

const bem = cn('geography-offices');

// TODO: переиновать кластеры ClastererLayoutOffice & ClastererLayoutEmployees

export const GeographyOffices = () => {
  const [map, setMap] = useState(null);
  const dispatch = useDispatch();
  const offices = useSelector(selectOfficesList);
  const statusOfficesCoords = useSelector(selectOfficesCoordsStatus);
  const mapDefaultState = { center: [55.75, 37.57], zoom: 4 };
  const onLoadMap = (ymaps) => {
    setMap(ymaps);
  };

  useEffect(() => {
    dispatch(getOfficesList());
  }, []);

  useEffect(() => {
    if (map) {
      const officesAddress = offices.map((office) => office.address);

      dispatch(setOfficesCoords({ ymapsApi: map, officesAddress }));
    }
  }, [map]);

  return (
    <div className={bem()}>
      {statusOfficesCoords === STATUS.LOADING && <Preloader mode="map" />}
      <YMaps
        query={{
          load: 'package.full',
          apikey: apiMapKey,
        }}
      >
        <div>
          <Map defaultState={mapDefaultState} width="100%" height="45rem" onLoad={onLoadMap}>
            <ClustererLayoutOffices map={map}>
              {offices.map(
                (office) =>
                  office?.officeGeo && (
                    <Placemark
                      key={office.officeId}
                      geometry={office.officeGeo}
                      properties={{
                        balloonContentHeader: office.name,
                        balloonContenBody: office.address,
                        balloonContentFooter: office.photos[0].url,
                      }}
                    />
                  )
              )}
            </ClustererLayoutOffices>
          </Map>
        </div>
      </YMaps>
    </div>
  );
};
