import React from 'react';
import { cn } from '@utils/bem-config';
import { getNumberWithAccuracy } from './utils/getNumberWithAccurancy';
import './AnimatedButtonBackground.css';

const disabledFill = '#C4C4C4';
const bem = cn('animated-button-background');

/**
 * Добавляет svg анимацию к блоку.
 * Занимает весь родительский блока.
 *
 * @param svgAnimation
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export const AnimatedButtonBackground = ({ svgAnimation, disabled = false }) => (
  <div className={bem()}>
    <svg height={svgAnimation.height} width={svgAnimation.width} viewBox={svgAnimation.viewport}>
      <path d={svgAnimation.initialPath} fill={disabled ? disabledFill : svgAnimation.initialFill}>
        {!disabled && (
          <>
            <animate
              attributeName="d"
              keyTimes={svgAnimation.keyTimes}
              calcMode="spline"
              keySplines={svgAnimation.keySplines}
              values={svgAnimation.animatePath}
              dur={getNumberWithAccuracy(svgAnimation.dur)}
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill"
              keyTimes={svgAnimation.keyTimes}
              calcMode="spline"
              keySplines={svgAnimation.keySplines}
              values={svgAnimation.animateFill}
              dur={getNumberWithAccuracy(svgAnimation.dur)}
              repeatCount="indefinite"
            />
          </>
        )}
      </path>
    </svg>
  </div>
);
