import { Footer } from '@components/common/Footer';
import { Header } from '@components/common/Header';
import { Scroll } from '@components/common/Scroll';
import { getAllUsers, selectUsersStatus } from '@src/components/common/UsersList/usersListSlice';
import { STATUS } from '@src/hoc/StatusSwitch';
import { keycloak } from '@src/index';
import { cn } from '@utils/bem-config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BigMobileQuery, DesktopQuery, LittleTabletQuery, TabletQuery } from '@utils/responsive';
import './MainPage.scss';
import { selectRoles } from '@src/appSlice';
import { MobileHeader } from '@components/common/MobileHeader';
import { BirthdaysList } from '@components/common/BirthdaysList';
import { MainPosts } from '@components/common/MainPosts';
import { useSwipeable } from 'react-swipeable';

const bem = cn('main-page');

const LOCAL_ERROR_BLOCK = ({ onExit }) => (
  <>
    <div>-</div>
    <div style={{ textAlign: 'center', padding: '5rem 0' }}>
      <h1>Неизвестная ошибка приложения</h1>
      <h3>Попробуйте позже</h3>
      <button type="button" onClick={onExit} style={{ padding: '1rem 3rem', margin: '2rem' }}>
        ВЫЙТИ
      </button>
    </div>
    <div>-</div>
  </>
);

export const MainPage = () => {
  const dispatch = useDispatch();
  const statusUsersList = useSelector(selectUsersStatus);
  const [isFocused, setIsFocused] = useState(false);
  const [isBirthdayOpen, setIsBirthdayOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const roles = useSelector(selectRoles);
  const isAdmin = new Set(roles).has('ADMIN');

  const deals = [
    'Раскрашиваем кнопки',
    'Кормим девопса',
    'Откачиваем дизайнера',
    'Фотошопим аватарки',
    'Ищем разработчиков',
    'Дезинфицируем код',
    'Готовим лодку',
    'Готовим вёсла',
    'Выбираем зелёный',
    'Поднимаем пингвинов',
    'Думаем что показать',
    'Воскрешаем деда',
    'Ищем таблетки',
    'Ща, ща',
    'Опять работа?',
  ];

  const generateName = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    const resultText = array[randomIndex];

    return resultText;
  };

  const dealText = generateName(deals);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const logout = () => {
    keycloak.logout();
  };

  const handleOpenCloseMobileMenuForSwipe = useSwipeable({
    onSwipedLeft: () => !isMenuOpen && setIsMenuOpen(true),
    onSwipedRight: () => {
      isMenuOpen && setIsMenuOpen(false);
      isBirthdayOpen && setIsBirthdayOpen(false);
    },
  });

  const handleCloseMobileMenuForBlurTouch = ({ target }, type) => {
    if (target.className === bem('blur')) {
      switch (type) {
        case 'MENU':
          setIsMenuOpen(false);
          break;

        case 'BIRTHDAYS':
          setIsBirthdayOpen(false);
          break;

        default:
          break;
      }
    }
  };

  return (
    <div {...handleOpenCloseMobileMenuForSwipe} className={bem()}>
      {statusUsersList === STATUS.DONE && (
        <>
          <DesktopQuery>
            <Scroll />
            <Header isFocused={isFocused} setIsFocused={setIsFocused} />
            <MainPosts isFocused={isFocused} isAdmin={isAdmin} />
            <Footer />
          </DesktopQuery>
          <TabletQuery>
            <MobileHeader
              setIsBirthdayOpen={setIsBirthdayOpen}
              setIsMenuOpen={setIsMenuOpen}
              isFocused={isFocused}
              setIsFocused={setIsFocused}
              isMenuOpen={isMenuOpen}
              isBirthdayOpen={isBirthdayOpen}
              tablet
            />
            {isMenuOpen && (
              <div className={bem('blur')} onClick={(e) => handleCloseMobileMenuForBlurTouch(e, 'MENU')}>
                <div className={bem('bg')}>
                  <Header isFocused={isFocused} setIsFocused={setIsFocused} tablet />
                </div>
              </div>
            )}
            {isBirthdayOpen && (
              <div className={bem('blur')} onClick={(e) => handleCloseMobileMenuForBlurTouch(e, 'BIRTHDAYS')}>
                <div className={bem('bg')}>
                  <BirthdaysList />
                </div>
              </div>
            )}
            <LittleTabletQuery>
              <MainPosts isFocused={isFocused} isAdmin={isAdmin} />
            </LittleTabletQuery>
            <BigMobileQuery>
              <MainPosts isFocused={isFocused} isAdmin={isAdmin} mobile />
            </BigMobileQuery>
          </TabletQuery>
        </>
      )}

      {statusUsersList === STATUS.LOADING && <p className={bem('loading-text')}>{dealText}</p>}

      {statusUsersList === STATUS.ERROR && <LOCAL_ERROR_BLOCK onExit={logout} />}
    </div>
  );
};
