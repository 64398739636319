import React from 'react';


const CopyIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8428 3.69655L14.0656 7.19189L12.65 11.7306L8.0115 12.774L4.78866 9.27867L6.20429 4.73994L10.8428 3.69655ZM7.11698 5.7298L6.10225 8.98318L8.41239 11.4887L11.7373 10.7408L12.752 7.48737L10.4419 4.9819L7.11698 5.7298Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.07457 2.27196L9.13795 4.401L8.30063 5.2125L6.6924 3.55309L3.35816 4.3031L2.34343 7.55648L4.65357 10.062L6.31601 9.688L6.5719 10.8256L4.25267 11.3473L1.02984 7.85197L2.44547 3.31324L7.07457 2.27196Z"
      fill={fill}
    />
  </svg>
);
export default CopyIcon;
