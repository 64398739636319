import React, { useState } from 'react';
import { Clusterer } from 'react-yandex-maps';
import ClusterIcon from '@src/assets/images/cluster-icon.svg';
import './ClustererLayoutEmployees.scss';

export const ClustererLayoutEmployees = ({ children, map }) => {
  const [usersByCity, setUsersByCity] = useState({});

  const clusterIconContentTemplate = '<div class="i-cluster-content">{{ properties.geoObjects.length }}</div>';
  const clusterIconContentLayout = map?.templateLayoutFactory?.createClass(clusterIconContentTemplate);

  const handleClick = (geoObjects) => {
    const groupedUsers = geoObjects.reduce((a, c) => {
      const { balloonContentHeader, balloonContentBody, balloonContentLdapId } = c.properties.getAll();

      a[balloonContentHeader] = [
        ...(a[balloonContentHeader] ?? []),
        { name: balloonContentBody, ldapId: balloonContentLdapId },
      ];

      return a;
    }, {});

    setUsersByCity(groupedUsers);
  };

  const mapUsersLayout = (users) =>
    users
      .map(
        (userName) =>
          `<a href="${window.location.origin}/users/${userName.ldapId}">
            <li class='i-balloon__item'>
                ${userName.name}
            </li>
          </a>`
      )
      .join('');

  // создаем новый шаблон для кластера
  // по аналогии с ишью на гитхабе https://github.com/gribnoysup/react-yandex-maps/issues/234
  const clusterBalloonContentLayout = () => {
    if (map) {
      const cities = Object.keys(usersByCity);
      const clusterTemplate = cities
        .map(
          (city) => `
          <div class='i-balloon'>
            <div class='i-balloon__title'>${city}</div>
            <ul class='i-balloon__list'>
              ${mapUsersLayout(usersByCity[city])}
            </ul>
          </div>
        `
        )
        .join('');

      const clusterLayout = map.templateLayoutFactory.createClass(clusterTemplate, {
        build() {
          clusterLayout.superclass.build.call(this);
        },
      });
      return clusterLayout;
    }
    return null;
  };

  const clusterIcons = [
    {
      href: ClusterIcon,
      size: [45, 45],
      offset: [-20, -20],
    },
    {
      href: ClusterIcon,
      size: [65, 65],
      offset: [-30, -30],
    },
  ];

  const clusterOptions = {
    iconLayout: 'cluster#icon',
    clusterIcons,
    clusterIconContentLayout,
    groupByCoordinates: false,
    clusterDisableClickZoom: true,
    clusterHideIconOnBalloonOpen: false,
    geoObjectHideIconOnBalloonOpen: false,
    minClusterSize: 1,
    clusterBalloonMaxHeight: 180,
    clusterBalloonContentLayout: clusterBalloonContentLayout(),
  };

  return (
    <Clusterer
      instanceRef={(ref) => {
        if (ref) {
          // переопределяем функцию createCluster, чтобы получить доступ к данным кластера и повесить обработчик
          // по аналогии с ишью на гитхабе https://github.com/gribnoysup/react-yandex-maps/issues/234
          ref.createCluster = function createCluster(center, geoObjects) {
            const clusterPlacemark = map.Clusterer.prototype.createCluster.call(this, center, geoObjects);
            clusterPlacemark.events.add('click', () => {
              handleClick(geoObjects);
            });
            return clusterPlacemark;
          };
        }
      }}
      options={clusterOptions}
    >
      {children}
    </Clusterer>
  );
};
