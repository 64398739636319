import { cn } from '@utils/bem-config';
import React, { useEffect } from 'react';
import data from '../../../utils/fake/creatorsData.json';
import { CreatorSection } from '../CreatorSection';
import './CreatorsList.scss';

const bem = cn('creators-list');

export function CreatorsList() {
  const html = document.querySelector('html');

  useEffect(() => {
    html.classList.add('on-page-creators-list');

    return () => {
      html.classList.remove('on-page-creators-list');
    };
  }, []);

  return (
    <div className={bem()}>
      {data.map((section) => (
        <CreatorSection title={section.heading} sectionUsers={section.list} key={section.id} />
      ))}
    </div>
  );
}
