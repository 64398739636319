import React, { useState } from 'react';
import { cn } from '@utils/bem-config';
import { limitOfNameCitySymbols } from '@utils/constants';
import './UsersSearchSelect.scss';

const bem = cn('users-search-select');

export const UsersSearchSelect = ({ data, handleAddTag, selectedValue, title, name }) => {
  const [selectMode, setSelectMode] = useState(false);

  return (
    <div className={`${bem('select-item')} ${(selectMode || selectedValue) && bem('item-active')}`}>
      <select
        value={selectedValue}
        name={name}
        onChange={(e) => handleAddTag(e)}
        onClick={() => setSelectMode(!selectMode)}
        onBlur={() => setSelectMode(false)}
        className={`${bem('select-btn')} ${(selectMode || selectedValue) && bem('select-active')}`}
      >
        <option value="">{title}</option>
        {data.map(
          (item) =>
            item && (
              <option key={item} value={item}>
                {item?.length > limitOfNameCitySymbols ? `${item.slice(0, limitOfNameCitySymbols)} ... ` : item}
              </option>
            )
        )}
      </select>
    </div>
  );
};
