import React, { useEffect } from 'react';
import { cn } from '@utils/bem-config';
import { UsersList } from '@src/components/common/UsersList';
import { UsersSearch } from '@src/components/common/UsersSearch';
import { selectSearchedUsersList } from '@src/components/common/UsersSearch/usersSearchSlice';
import { useSelector } from 'react-redux';
import { resetScroll } from '@utils/resetScroll';
import './UsersListPage.scss';

const bem = cn('users-list-page');

export const UsersListPage = ({ isFocused }) => {
  const users = useSelector(selectSearchedUsersList);

  useEffect(() => {
    resetScroll();
  }, []);
  return (
    <div className={bem()}>
      <header className={bem('header')}>
        <h1 className={bem('title')}>Сотрудники</h1>
        <p className={bem('subtitle')}>Количество: {users.length}</p>
      </header>

      <div className={bem('list')}>
        <UsersSearch isFocused={isFocused} />
        {!isFocused && <UsersList />}
      </div>
    </div>
  );
};
