import React from 'react';


const InfoIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 4L7.5 5L8.5 5L8.5 4L7.5 4ZM8.5 12L8.5 6.16667L7.5 6.16667L7.5 12L8.5 12Z"
      fill={fill}
    />
  </svg>
);
export default InfoIcon;
