import React from 'react';
import { useSelector } from 'react-redux';
import { cn } from '@utils/bem-config';
import { StatusSwitch } from '@src/hoc/StatusSwitch';
import { Error } from '@components/common/Error';
import { UserItem } from '@components/common/UserItem';
import { selectStatus, selectCurrentUser, getCurrentUser } from './myProfilePreviewSlice';
import './MyProfilePreview.scss';

const bem = cn('MyProfilePreview');

export const MyProfilePreview = () => {
  const status = useSelector(selectStatus);
  const currentUser = useSelector(selectCurrentUser);

  return (
    <div className={bem()}>
      <StatusSwitch
        status={status}
        ComponentOnDone={
          <UserItem
            userLdapId={currentUser?.ldapId}
            photo={currentUser?.photos?.avatar?.url}
            photoPosition="left"
            photoSize="m"
            lastName={currentUser?.lastName}
            firstName={currentUser?.firstName}
            secondLine={currentUser?.email}
          />
        }
        ComponentOnError={<Error action={getCurrentUser} />}
        ComponentOnLoading={<UserItem photoSize="m" secondLine isLoading />}
      />
    </div>
  );
};
