const emojiRanges = [
  '(?:\uD83C[\uDDE6-\uDDFF]){2}', // флаги
  '[\u0023-\u0039]\u20E3', // числа
  '(?:[\uD83D\uD83C\uD83E][\uDC00-\uDFFF]|[\u270A-\u270D\u261D\u26F9])\uD83C[\uDFFB-\uDFFF]', // цвет кожи
  '\uD83D[\uDC68\uDC69][\u200D\u200C].+?\uD83D[\uDC66-\uDC69](?![\u200D\u200C])', // семья
  '[\uD83D\uD83C\uD83E][\uDC00-\uDFFF]', // суррогатная пара
  '[\u3297\u3299\u303D\u2B50\u2B55\u2B1B\u27BF\u27A1\u24C2\u25B6\u25C0\u2600\u2705\u21AA\u21A9]', // обычные
  '[\u203C\u2049\u2122\u2328\u2601\u260E\u261d\u2620\u2626\u262A\u2638\u2639\u263a\u267B\u267F\u2702\u2708]',
  '[\u2194-\u2199]',
  '[\u2B05-\u2B07]',
  '[\u2934-\u2935]',
  '[\u2795-\u2797]',
  '[\u2709-\u2764]',
  '[\u2622-\u2623]',
  '[\u262E-\u262F]',
  '[\u231A-\u231B]',
  '[\u23E9-\u23EF]',
  '[\u23F0-\u23F4]',
  '[\u23F8-\u23FA]',
  '[\u25AA-\u25AB]',
  '[\u25FB-\u25FE]',
  '[\u2602-\u2618]',
  '[\u2648-\u2653]',
  '[\u2660-\u2668]',
  '[\u26A0-\u26FA]',
  '[\u2692-\u269C]',
];
// eslint-disable-next-line
const emojiAndSymbolsRegex = new RegExp(`${emojiRanges.join('|')}|[^a-zа-яё0-9\s]`, 'gmi');
const emojiRegex = new RegExp(emojiRanges.join('|'), 'gmi');

export const convertSymbolsToHTML = (text) =>
  text.replace(emojiAndSymbolsRegex, (c) => (c.match(emojiRegex) ? c : `&#${c.charCodeAt(0)};`));
