import React, { useEffect, useState } from 'react';
import { cn } from '@utils/bem-config';
import { message } from 'antd';
import { SvgIcon } from '@components/common/SvgIcon';
import { selectSendCommentStatus, sendComment } from '@components/common/PostsList/postsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '@src/hoc/StatusSwitch';
import { CommonTooltip } from '@components/common/CommonTooltip';
import './AddCommentForm.scss';

const bem = cn('add-comment');
export const AddCommentForm = ({ data, commentInputRef, commentsIsOpen }) => {
  const [commentText, setCommentText] = useState('');
  const dispatch = useDispatch();
  const sendCommentStatus = useSelector(selectSendCommentStatus);
  useEffect(() => {
    sendCommentStatus === STATUS.ERROR && commentText ? message.error('Ошибка добавления комментария') : '';
    sendCommentStatus === STATUS.DONE ? setCommentText('') : '';
  }, [sendCommentStatus]);
  const hasComments = data?.commentOutputList?.length > 0;
  const handleCommentSend = () => {
    if (commentText.trim()) {
      dispatch(
        sendComment({
          postId: data.postId,
          commentText,
        })
      );
    } else {
      commentInputRef?.current?.focus();
    }
  };

  const handleEnter = (e) => {
    if (e.which === 13) handleCommentSend();
  };
  return (
    <>
      {(hasComments || commentsIsOpen) && (
        <div className={bem()}>
          <input
            className={bem('input')}
            type="text"
            placeholder="Оставить комментарий..."
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            onKeyPress={handleEnter}
            ref={commentInputRef}
          />
          <CommonTooltip title="Отправить">
            <button
              className={bem('send-button', { disabled: !commentText.trim() })}
              type="button"
              onClick={handleCommentSend}
            >
              <SvgIcon width={16} height={16} type="send" className={bem('numbered-btn-icon', { type: 'sumbit' })} />
            </button>
          </CommonTooltip>
        </div>
      )}
    </>
  );
};
