import { createAsyncThunk } from '@reduxjs/toolkit';
import { api, request } from '@src/app/api';

export const getAvatarList = createAsyncThunk('getAvatarList', async ({ userLdapId }) => {
  const response = await request.get(api.photos(), {
    params: {
      ldapId: userLdapId,
      label: 'AVATAR',
    },
  });

  return { userLdapId, data: response.data };
});
