import React from 'react';


const UsersIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.27301 8.54334L2.99085 7.22573L2.99085 4.59052L5.27301 3.27291L7.55518 4.59052L7.55518 7.22573L5.27301 8.54334ZM6.53273 6.63542L6.53273 5.18083L5.27301 4.45353L4.0133 5.18083L4.0133 6.63542L5.27301 7.36271L6.53273 6.63542Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7261 7.86169L8.44398 6.54409L8.44398 3.90888L10.7261 2.59127L13.0083 3.90887L13.0083 6.54409L10.7261 7.86169ZM11.9859 5.95378L11.9859 4.49919L10.7261 3.77189L9.46643 4.49919L9.46643 5.95378L10.7261 6.68107L11.9859 5.95378Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.36302 7.27136H9.20824L9.07945 7.35722L7.03455 8.72049L6.99756 8.74515L7.31812 9.14585L8.68147 9.82749L7.90582 10.2707L10.4069 12.2716L10.7263 12.5271L11.0457 12.2716L14.4538 9.54505L14.9998 9.1083L14.418 8.72049L12.3731 7.35722L12.2443 7.27136H12.0896H9.36302ZM8.18342 9.18341L9.51781 8.29381H11.9348L13.2692 9.18341L10.7263 11.2177L8.18342 9.18341Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9102 7.953H3.75541L3.62662 8.03886L1.58172 9.40213L1 9.78994L1.54594 10.2267L4.95411 12.9532L5.27347 13.2087L5.59283 12.9532L9.00099 10.2267L9 10L8.5 9.5L8 9L7.81634 8.74505L6.92031 8.03886L6.79152 7.953H6.63673H3.9102ZM2.7306 9.86505L4.06499 8.97545H6.48195L7.81634 9.86505L5.27347 11.8993L2.7306 9.86505Z"
      fill={fill}
    />
  </svg>
);
export default UsersIcon;
