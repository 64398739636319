import firedUserAvatar from '@assets/images/firedUserAvatar.svg';
import firedUserBG from '@assets/images/firedUserBG.png';
import bgDefaultImage from '@assets/images/user-page-bg.jpg';
import { Button } from '@components/common/Button';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { Modal } from '@components/common/Modal';
import { TernaryForSkeleton } from '@components/common/Skeletons/Skeleton';
import { SvgIcon } from '@components/common/SvgIcon';
import { UserCropAvatar } from '@components/common/UserCropAvatar/UserCropAvatar';
import {
  deleteUserPicture,
  selectCurrentUser,
  updateUserBackground,
} from '@src/components/common/MyProfilePreview/myProfilePreviewSlice';
import { setUserSearchValue } from '@src/components/common/UsersSearch/usersSearchSlice';
import { STATUS } from '@src/hoc/StatusSwitch';
import { cn } from '@utils/bem-config';
import { maxAvatarAndBackgroundSize, maxAvatarAndBackgroundSizeKB } from '@utils/constants';
import { isFileTypeValid, validImagesType } from '@utils/validationParameters';
import { message } from 'antd';
import FsLightbox from 'fslightbox-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import './UserPage.scss';
import {
  getAvatarList,
  getUserByLdap,
  selectUserAvatarList,
  selectUserAvatarListStatus,
  selectUserData,
} from './userPageSlice';

const bem = cn('user-page');

export const UserPage = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // BEGIN: state
  const [deleteAvatar, setDeleteAvatar] = useState(false);
  const [deleteBackground, setDeleteBackground] = useState(false);
  const [toggleLightbox, setToggleLightbox] = useState(false);
  const [userAvatarCrop, setUserAvatarCrop] = useState(false);
  const [upImg, setUpImg] = useState();
  // END: state

  // BEGIN: refs
  const backgroundInputRef = useRef();
  const avatarInputRef = useRef();
  // END: refs

  // BEGIN: selectors
  /**
   * Данные пользователя, под которым авторизован.
   */
  const currentUser = useSelector(selectCurrentUser);
  const userAvatarList = useSelector(selectUserAvatarList);
  const userAvatarListStatus = useSelector(selectUserAvatarListStatus);
  const users = useSelector(selectUserData);
  // END: selectors

  const isCurrentUser = currentUser.ldapId === id;
  /**
   * Данные пользователя.
   * ___
   * Ищем пользователя в redux store. Возможно он был ранее загружен.
   * Если пользователь не находится, то ниже в коде подгружаем из базы.
   */
  const user = Boolean(users.length) && users.find((userItem) => userItem.ldapId === id);
  const userAvatar = user?.photos?.avatar?.url;
  const userBackground = user?.photos?.background?.url;
  const userAllAvatars = userAvatarList && userAvatarList.map((avatar) => avatar?.url).reverse();
  /**
   * Является ли сотрудник уволенным.
   */
  const isUserFired = Boolean(user?.fired);
  /**
   * Картинка для обложки.
   */
  const userBackgroundImage = isUserFired ? firedUserBG : userBackground || bgDefaultImage;

  useEffect(() => {
    if (!user) {
      dispatch(getUserByLdap({ userLdapId: id }));
    }
    user && dispatch(getAvatarList({ userLdapId: user?.ldapId }));
    dispatch(setUserSearchValue(''));
  }, [user]);

  const handlePictureDelete = (photoLabel, setStateAction) => {
    const path = photoLabel === 'AVATAR' ? 'avatar' : 'background';
    dispatch(
      deleteUserPicture({
        userLdapId: user?.ldapId,
        photoLabel,
        isAdmin: isAdmin && user?.photos?.[path]?.photoId,
      })
    );
    setStateAction(false);
  };

  const handleToggleLightbox = () => {
    setToggleLightbox(!toggleLightbox);
  };

  const handleAvatarClick = () => {
    userAvatar ? handleToggleLightbox() : '';
  };

  const selectFile = (refName) => {
    refName.current.click();
  };

  const changeUserPhoto = (e, updatePhotoAction, photoLabel) => {
    if (e.target.files?.length > 0) {
      const file = e.target.files[0];
      if (file.size <= maxAvatarAndBackgroundSizeKB) {
        isFileTypeValid(file)
          ? dispatch(
              updatePhotoAction({
                userLdapId: user.ldapId,
                file: e?.target?.files[0],
                userId: user.userId,
                isAdmin,
              })
            )
          : message.error(`Загружаемое изображение ${file.name} неправильного формата.`);
      } else {
        message.error(`Невозможно загрузить изображение больше ${maxAvatarAndBackgroundSize} Мб, прости`);
      }
      photoLabel === 'BACKGROUND' ? setDeleteBackground(false) : '';
    }
  };

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    if (file.size <= maxAvatarAndBackgroundSizeKB) {
      if (isFileTypeValid(file)) {
        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      } else {
        message.error(`Загружаемое изображение ${file.name} неправильного формата.`);
      }
    } else {
      message.error(`Невозможно загрузить изображение больше ${maxAvatarAndBackgroundSize} Мб, прости`);
    }
    e.target.value = null;
    setUserAvatarCrop(true);
  };

  const AvatarModalContent = (
    <div className={bem('buttons')}>
      <Button type="button" onClick={() => handlePictureDelete('AVATAR', setDeleteAvatar)}>
        Абсолютно
      </Button>
      <ButtonAnimated onClick={() => setDeleteAvatar(false)} type="cancel">
        Отмена
      </ButtonAnimated>
    </div>
  );

  const BackgroundModalContent = (
    <div className={bem('buttons')}>
      <div onClick={() => selectFile(backgroundInputRef)}>
        <Button type="button">
          <input
            className={bem('background-upload')}
            type="file"
            accept={validImagesType}
            ref={backgroundInputRef}
            onChange={(e) => changeUserPhoto(e, updateUserBackground, 'BACKGROUND')}
          />
          Изменить
        </Button>
      </div>
      <ButtonAnimated
        onClick={() => handlePictureDelete('BACKGROUND', setDeleteBackground)}
        type="cancel"
        disabled={!userBackground}
      >
        Удалить
      </ButtonAnimated>
    </div>
  );

  return (
    <div className={bem()}>
      <>
        <div className={bem('background-block')} style={{ backgroundImage: `url(${userBackgroundImage})` }}>
          {!isUserFired && (isCurrentUser || isAdmin) && (
            <div className={bem('background-overlay')} onClick={() => setDeleteBackground(!deleteBackground)}>
              <span>Редактировать обложку</span>
            </div>
          )}
        </div>
        <div className={bem('user-photo-wrapper')}>
          <img
            src={isUserFired ? firedUserAvatar : userAvatar}
            alt=""
            onClick={handleAvatarClick}
            className={bem('user-photo')}
          />
          {!isUserFired && (isCurrentUser || isAdmin) && (
            <>
              <div className={bem('user-photo-update')} onClick={() => selectFile(avatarInputRef)}>
                <SvgIcon type="photo-camera" fill="white" className={bem('user-photo-icon')} />
                <input
                  className={bem('avatar-upload')}
                  type="file"
                  onChange={onSelectFile}
                  ref={avatarInputRef}
                  accept={validImagesType}
                />
              </div>

              {userAvatar && (
                <div className={bem('user-photo-delete')} onClick={() => setDeleteAvatar(!deleteAvatar)}>
                  <SvgIcon type="delete" fill="white" className={bem('user-photo-icon')} />
                </div>
              )}
            </>
          )}
        </div>
        <UserCropAvatar
          userAvatarCrop={userAvatarCrop}
          setUserAvatarCrop={setUserAvatarCrop}
          user={user}
          upImg={upImg}
          setUpImg={setUpImg}
          isAdmin={isAdmin}
        />
        {deleteAvatar && userAvatar && (
          <Modal
            title="Вы уверены, что хотите удалить аватар?"
            content={AvatarModalContent}
            closeHandler={() => setDeleteAvatar(false)}
          />
        )}
        {deleteBackground && (
          <Modal
            title="Вы хотите изменить или удалить обложку?"
            content={BackgroundModalContent}
            closeHandler={() => setDeleteBackground(false)}
          />
        )}
        {userAvatarListStatus === STATUS.DONE && (
          <FsLightbox toggler={toggleLightbox} sources={userAllAvatars} type="image" />
        )}
        <div className={bem('user-heading-line')}>
          <div className={bem('user-full-name')}>
            <TernaryForSkeleton isLoading={!user} width="20rem" height={27}>
              {`${user?.lastName} ${user?.firstName}`}
            </TernaryForSkeleton>
          </div>
          <div className={bem('user-team')}>
            <TernaryForSkeleton isLoading={!user} width="4rem" height={18}>
              {isUserFired ? (
                <span>Бывший сотрудник {user.department ? `| ${user.department}` : ''}</span>
              ) : (
                <Link className={bem('team-link')} to={`/users?department=${user?.department?.[0]}`}>
                  {user?.department?.join(' | ') || 'Направление'}
                </Link>
              )}
            </TernaryForSkeleton>
          </div>
        </div>

        <div className={bem('info-wrapper')}>
          <hr className={bem('hr')} />

          <h4 className={bem('section-title')}>Основная информация</h4>

          <div className={bem('about-user-list')}>
            <div className={bem('information-item', { city: true })}>
              <SvgIcon type="location" className={bem('hide-comments-icon')} fill="var(--c-accent)" />
              <span className={bem('user-location')}>
                <TernaryForSkeleton isLoading={!user} width="8rem" height={12}>
                  {isUserFired
                    ? 'Не известно'
                    : user?.city || <span className={bem('link', { empty: true })}>Город не указан</span>}
                </TernaryForSkeleton>
              </span>
            </div>

            <div className={bem('information-item', { birthday: true })}>
              <SvgIcon type="date" className={bem('hide-comments-icon')} fill="var(--c-accent)" />
              <span className={bem('user-birthday')}>
                <TernaryForSkeleton isLoading={!user} width="8rem" height={12}>
                  {isUserFired ? (
                    'Не известно'
                  ) : user?.birthDate ? (
                    moment(user?.birthDate).format('DD MMMM')
                  ) : (
                    <span className={bem('link', { empty: true })}>Не указана</span>
                  )}
                </TernaryForSkeleton>
              </span>
            </div>
          </div>

          <hr className={bem('hr')} />
          <h4 className={bem('section-title')}>Контактная информация</h4>
          {/* {!isUserFired && ( */}
          <div className={bem('about-user-list')}>
            <div className={bem('about-user-list-item')}>
              <span className={bem('line-title')}>Почта:</span>
              <TernaryForSkeleton isLoading={!user} width="8rem" height={12}>
                {isUserFired ? (
                  <span className={bem('link')}> неизвестно </span>
                ) : user?.email ? (
                  <a href={`mailto:${user?.email}`} className={bem('link')}>
                    {user?.email}
                  </a>
                ) : (
                  <span className={bem('link', { empty: true })}>Не указано</span>
                )}
              </TernaryForSkeleton>
            </div>
            {/* Тут был skype */}
            <div className={bem('about-user-list-item')}>
              <span className={bem('line-title')}>Телеграмм:</span>
              <TernaryForSkeleton isLoading={!user} width="8rem" height={12}>
                {isUserFired ? (
                  <span className={bem('link')}> неизвестно </span>
                ) : user?.telegram ? (
                  <a
                    href={`https://t.me/${user?.telegram?.slice(1)}`}
                    className={bem('link')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {user?.telegram}
                  </a>
                ) : (
                  <span className={bem('link', { empty: true })}>Не указано</span>
                )}
              </TernaryForSkeleton>
            </div>
          </div>
          {/* )} */}
        </div>
      </>
    </div>
  );
};
