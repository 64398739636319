import { AppCard } from '@components/common/AppCard';
import { cn } from '@utils/bem-config';
import React from 'react';
import './GamesPage.scss';
import { useHistory } from 'react-router-dom';

const bem = cn('games-page');
export const GamesPage = () => {
  const history = useHistory();

  const gamesListDesktop = [
    {
      name: 'IT Snake',
      description: 'Змейка - какой ты it специалист',
      buttonData: {
        onClick: () => history.push('/apps/snake'),
        target: '_blank',
      },
    },
    {
      name: 'Ground 2D',
      description: 'Стреляй перестреляй',
      buttonData: {
        href: 'http://192.168.88.42:31113/',
        target: '_blank',
      },
    },
  ];

  const gamesListMobile = [
    {
      name: 'Teddy Doom',
      description: 'An exciting shooter with live toys',
      buttonData: {
        href: 'https://play.google.com/store/apps/details?id=com.Irlix.TeddyDoom',
        target: '_blank',
      },
    },
    {
      name: 'My Little Zombie',
      description: 'Zombie store simulator',
      buttonData: {
        href: 'https://play.google.com/store/apps/details?id=com.Irlix.MyLittleZombie',
        target: '_blank',
      },
    },
    {
      name: 'Rob Me More',
      description: 'All great heists go down in history',
      buttonData: {
        href: 'https://play.google.com/store/apps/details?id=com.irlix.robmemore',
        target: '_blank',
      },
    },
  ];

  return (
    <section className={bem()}>
      <p className={bem('title')}>Desktop</p>
      <div className={bem('wrapper')}>
        {gamesListDesktop.map((data) => (
          <AppCard key={data.name} {...data} />
        ))}
      </div>

      <p className={bem('title')}>Mobile</p>
      <div className={bem('wrapper')}>
        {gamesListMobile.map((data) => (
          <AppCard key={data.name} {...data} />
        ))}
      </div>
    </section>
  );
};
