import { cn } from '@utils/bem-config';
import React from 'react';
import './ServicesPage.scss';
import { AppCard } from '@components/common/AppCard';

const servicesList = [
  {
    name: 'Booking',
    description: 'Сервис бронирования переговорок',
    buttonData: {
      href: 'http://booking.lan/',
      target: '_blank',
    },
  },
  {
    name: 'Vacation',
    description: 'Сервис планирования отпусков',
    buttonData: {
      href: 'http://vacations.lan/',
      target: '_blank',
    },
  },
  {
    name: 'Value',
    description: 'Сервис оценки проектов',
    buttonData: {
      href: 'http://value.lan/',
      target: '_blank',
    },
  },
];

const bem = cn('services-page');
export const ServicesPage = () => (
  <div className={bem()}>
    {servicesList.map((data) => (
      <AppCard key={data.name} {...data} />
    ))}
  </div>
);
