import { cn } from '@utils/bem-config';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SvgIcon } from '@components/common/SvgIcon';
import { keycloak } from '@src/index';
import { Modal } from '@components/common/Modal';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { AddWish } from '@components/common/AddWish';
import './MainPageNavigation.scss';
import { useSelector } from 'react-redux';
import { selectRoles } from '@src/appSlice';

const bem = cn('main-page-navigation');

const MenuItem = ({ to, text, type = 'menuItem' }) => (
  <NavLink className={bem('nav-link')} to={to} activeClassName={bem('active')}>
    <SvgIcon type={type} className={bem('icon')} />
    {text}
  </NavLink>
);

export const MainPageNavigation = () => {
  const roles = useSelector(selectRoles);
  const isAdmin = new Set(roles).has('ADMIN');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const openModal = () => {
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setIsModalOpened(false);
  };
  const logout = () => {
    keycloak.logout();
  };
  const text = `Вы можете оставить <b>пожелание по улучшению</b> компании, процесов, соц сети Scroll,
 и других внутрених сервисов написав на почту
 <a href='mailto:social@irlix.com'>social@irlix.com</a>  или нажав кнопку “написать письмо” ниже.`;

  const ModalContent = (
    <div className={bem('blur-container')}>
      <div className={bem('modal')}>
        <div className={bem('buttons')}>
          <AddWish subject="Scroll social network" body="Write a letter..." />
          <ButtonAnimated onClick={closeModal} type="cancel">
            Отмена
          </ButtonAnimated>
        </div>
      </div>
    </div>
  );

  return (
    <nav className={bem()}>
      <MenuItem to="/feed" type="like" text="Лента" />
      <MenuItem to="/users" type="users" text="Сотрудники" />
      <MenuItem to="/geolocation" type="geolocation" text="География" />
      <MenuItem to="/apps" text="Приложения" type="apps" />

      <hr className={bem('delimiter')} />

      {/* <MenuItem to="/404" text="Магазин" type="coin" /> */}
      <MenuItem to="/settings" text="Настройки" type="settings" />
      {isAdmin && <MenuItem to="/administration" text="Администрирование" type="settings" />}

      <hr className={bem('delimiter')} />

      <a
        className={bem('nav-link')}
        href="https://wiki.yandex.ru/homepage/operations/onboarding/"
        target="_blank"
        rel="noreferrer"
      >
        <SvgIcon type="welcome" className={bem('icon', { type: 'welcome' })} /> Welcome
      </a>

      <a className={bem('nav-link')} href="https://wiki.yandex.ru" target="_blank" rel="noreferrer">
        <SvgIcon type="wiki" className={bem('icon', { type: 'wiki' })} /> Wiki
      </a>

      {/* <a className={bem('nav-link')} href="http://booking.lan/meeting-rooms" target="_blank" rel="noreferrer">
        <SvgIcon type="clock" className={bem('icon', { type: 'wiki' })} /> Booking
      </a> */}

      <hr className={bem('delimiter')} />

      <div className={bem('nav-link')} onClick={openModal}>
        <SvgIcon type="coin" className={bem('icon')} />
        Оставить пожелания
      </div>
      <MenuItem to="/creators" text="Создатели" type="owner" />

      <hr className={bem('delimiter')} />

      <button type="button" className={bem('logout')} onClick={logout}>
        <SvgIcon type="exit" className={bem('icon')} />
        Выйти
      </button>
      {isModalOpened && <Modal closeHandler={closeModal} content={ModalContent} text={text} className="liveWishes" />}
    </nav>
  );
};
