import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPost,
  getPost,
  selectActivePost,
  selectActivePostStatus,
} from '@components/common/PostsList/postsListSlice';
import { setUserSearchValue } from '@src/components/common/UsersSearch/usersSearchSlice';
import { Post } from '@components/common/Post';
import { Error } from '@components/common/Error';
import { StatusSwitch } from '@src/hoc/StatusSwitch';

export const PostPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const activePost = useSelector(selectActivePost);
  const activePostStatus = useSelector(selectActivePostStatus);

  useEffect(() => {
    dispatch(getPost(id));
    return dispatch(clearPost());
  }, [id]);

  useEffect(() => dispatch(setUserSearchValue('')), []);

  return (
    <StatusSwitch
      status={activePostStatus}
      ComponentOnDone={<Post key={id} data={activePost} postIsActive />}
      ComponentOnLoading={<Post isLoading />}
      ComponentOnError={<Error action={() => getPost(id)} />}
    />
  );
};
