import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api, request } from '@src/app/api';
import { STATUS } from '@src/hoc/StatusSwitch';
import {
  deleteUserPicture,
  updateUserAvatar,
  updateUserBackground,
  updateUserInformation,
} from '@components/common/MyProfilePreview/myProfilePreviewSlice';

const initialState = {
  allUsers: [],
  firedUser: [],
  user: null,
  usersCoordsStatus: STATUS.NO_STATUS,
  deleteUserStatus: STATUS.NO_STATUS,
  allUsersStatus: STATUS.NO_STATUS,
};

export const getAllUsers = createAsyncThunk('getAllUsers', async () => {
  const response = await request.get(api.usersAll());

  return response.data;
});

export const getFiredUser = createAsyncThunk('getUser', async (userLdapId, { getState }) => {
  const { firedUser } = getState().usersList;
  const isUser = firedUser.find((user) => user.ldapId === userLdapId);

  const response = await request.get(api.userByLdap(userLdapId));

  return { data: response.data, isUser };
});

export const getAddressGeo = async (ymapsApi, address) => {
  const result = {};

  for (const city of address) {
    const response = await ymapsApi?.geocode(city);
    const firstGeoObject = response.geoObjects.get(0);
    const cityCoord = firstGeoObject?.geometry.getCoordinates();

    if (cityCoord) {
      result[city] = cityCoord;
    }
  }

  return result;
};

export const setUserCoords = createAsyncThunk('setUserCoords', async ({ ymapsApi, uniqueCities }) => {
  const result = await getAddressGeo(ymapsApi, uniqueCities);

  return { data: result };
});

export const usersListSlice = createSlice({
  name: 'usersListSlice',
  initialState,
  reducers: {
    setCoordsStatus: (state, { payload }) => {
      state.usersCoordsStatus = payload;
    },
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.allUsersStatus = STATUS.LOADING;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      const users = action.payload;
      // объект users заключён в массив для того,
      // чтобы allUsers в chart  redux dev tools не занимал всю область видимости
      state.allUsers = [users];
      state.allUsersStatus = STATUS.DONE;
    },
    [getAllUsers.rejected]: (state) => {
      state.allUsersStatus = STATUS.ERROR;
    },
    [getFiredUser.pending]: (state) => {
      state.deleteUserStatus = STATUS.LOADING;
    },
    [getFiredUser.rejected]: (state) => {
      state.deleteUserStatus = STATUS.ERROR;
    },
    [getFiredUser.fulfilled]: (state, action) => {
      if (!action.payload.isUser) {
        state.firedUser = [...state.firedUser, action.payload.data];
        state.user = action.payload.data;
      }
    },
    [updateUserAvatar.fulfilled]: (state, action) => {
      const user = state.allUsers[0].find((userItem) => userItem.ldapId === action.payload.userLdapId);
      user ? (user.photos.avatar = action.payload.data) : '';
    },
    [updateUserBackground.fulfilled]: (state, action) => {
      const user = state.allUsers[0].find((userItem) => userItem.ldapId === action.payload.userLdapId);
      user ? (user.photos.background = action.payload.data) : '';
    },
    [updateUserInformation.fulfilled]: (state, action) => {
      const userIndex = state.allUsers[0].findIndex((user) => user.ldapId === action.payload.data.ldapId);
      state.allUsers[0][userIndex] = action.payload.data;
    },
    [setUserCoords.fulfilled]: (state, action) => {
      state.allUsers[0] = state.allUsers[0].map((user) => ({
        ...user,
        cityGeo: action.payload.data[user.city],
      }));
      state.usersCoordsStatus = STATUS.DONE;
    },
    [setUserCoords.pending]: (state) => {
      state.usersCoordsStatus = STATUS.LOADING;
    },
    [setUserCoords.rejected]: (state) => {
      state.usersCoordsStatus = STATUS.ERROR;
    },
    [deleteUserPicture.fulfilled]: (state, action) => {
      const user = state.allUsers[0].find((userItem) => userItem.ldapId === action.payload.userLdapId);
      user ? (user.photos = action.payload.data) : '';
    },
  },
});

export const { setCoordsStatus } = usersListSlice.actions;

export const selectAllUsers = (state) => state.usersList.allUsers;
export const selectUsersStatus = (state) => state.usersList.allUsersStatus;
export const firedUserState = (state) => state.usersList.firedUser;
export const selectUser = (state) => state.usersList.user;
export const selectUsersCoordsStatus = (state) => state.usersList.usersCoordsStatus;

export default usersListSlice.reducer;
