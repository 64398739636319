/* eslint-disable max-len */
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { DropdownMenuCategories } from '@components/common/DropdownMenuCategories/DropdownMenuCategories';
import { addPost, selectTagsList, setIsOpenYouTubeForm } from '@components/common/PostsList/postsListSlice';
import { SvgIcon } from '@components/common/SvgIcon';
import { cn } from '@utils/bem-config';
import { Divider, Input, message, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './NewYoutubeForm.scss';

const bem = cn('new-youtube-form');

export const NewYoutubeForm = () => {
  const dispatch = useDispatch();
  const [linkValue, setLinkValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [tagType, setTagType] = useState([]);
  const tags = useSelector(selectTagsList);
  const history = useHistory();
  const goToAdminPage = () => history.push('/administration');

  const handleSubmit = () => {
    let isValid;
    const description = descriptionValue ? `<p>${descriptionValue}</p></br>` : '';
    try {
      const v = new URL(linkValue).searchParams.get('v');
      if (v.length > 0) {
        isValid = true;
      } else {
        throw new Error('error length v');
      }
    } catch (error) {
      message.error('Ссылка на видео не соответствует требованиям URL');
      isValid = false;
    }
    const videoURL = isValid ? new URL(linkValue).searchParams.get('v') : '';
    const webContent = `${description} <iframe width="100%" height="261" src="https://www.youtube.com/embed/${videoURL}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    const telegramContent = `${descriptionValue}\nhttps://youtu.be/${videoURL}`;

    if (isValid) {
      dispatch(
        addPost({
          content: webContent,
          textContent: telegramContent,
          tags: tagType,
        })
      );
      dispatch(setIsOpenYouTubeForm());
    }
  };

  return (
    <div className={bem()}>
      <h3 className={bem('title')}>Прикрепить YouTube видео</h3>

      <Typography className={bem('typography')}>Ссылка на видео (URL)</Typography>
      <Input
        className={bem('input')}
        placeholder="https://www.youtube.com/watch?v=gqlnXRxV32o"
        value={linkValue}
        onChange={(e) => setLinkValue(e.currentTarget.value)}
        bordered={false}
      />

      <Typography className={bem('typography')}>Описание (если необходимо)</Typography>
      <Input
        className={bem('input')}
        placeholder="Играем в игры"
        value={descriptionValue}
        onChange={(e) => setDescriptionValue(e.currentTarget.value)}
        bordered={false}
      />

      <Divider className={bem('divider')} />
      <div className={bem('action-bar')}>
        <DropdownMenuCategories
          data={tags}
          currentData={tagType}
          setCurrentData={setTagType}
          functionOnClick={goToAdminPage}
        />
        <div className={bem('action-bar--item')}>
          <ButtonAnimated onClick={() => dispatch(setIsOpenYouTubeForm())}>Отмена</ButtonAnimated>
          <button onClick={handleSubmit} className={bem('submit-btn')}>
            <SvgIcon type="send" />
          </button>
        </div>
      </div>
    </div>
  );
};
