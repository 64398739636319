import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTag, deleteTag, getTags, selectTags } from '@components/common/PostsList/postsListSlice';
import { Input, message } from 'antd';
import { cn } from '@utils/bem-config';
import './TagsSettings.scss';
import { Button } from '@components/common/Button';
import { selectRoles } from '@src/appSlice';
import { SvgIcon } from '@components/common/SvgIcon';

const bem = cn('tags');
export const TagsSettings = () => {
  const dispatch = useDispatch();
  const tags = useSelector(selectTags);
  const [newTag, setNewTag] = useState('');
  const roles = useSelector(selectRoles);
  const isAdmin = new Set(roles).has('ADMIN');

  const handleChange = (e) => {
    setNewTag(`${e.target.value}`);
  };

  const handleNewTag = () => {
    if (newTag.length > 0) {
      const modifiedString = newTag
        .split('')
        .map((item, idx, arr) => {
          if (arr[idx - 1] === ' ' || idx === 0) {
            return item.toUpperCase();
          }
          return item;
        })
        .filter((item) => item !== ' ')
        .join('');
      const isExsist = tags.find((item) => item.name === modifiedString);
      if (!isExsist) {
        if (/[/=//!/////(//)//,//;//://!//"//$-//_/]/gm.test(newTag)) {
          message.error('Нельзя использовать =!/(),;:!"-_');
        } else {
          dispatch(
            addTag({
              name: modifiedString,
            })
          )
            .catch((rej) => message.error(rej))
            .then(() => {
              dispatch(getTags());
            });
        }
      } else {
        message.error('Тег уже существует');
      }
    }
    setNewTag('');
  };

  const onEnterPress = (e) => {
    if (newTag.length > 0 && e.keyCode === 13) {
      handleNewTag();
    }
  };

  const handleDeleteTag = (name) => {
    dispatch(deleteTag(name)).then(() => {
      dispatch(getTags());
    });
  };

  const clearSearch = () => {
    setNewTag('');
  };

  return (
    <div>
      {isAdmin && (
        <>
          <div className={bem('box')}>
            <Input
              onKeyDown={onEnterPress}
              onChange={handleChange}
              value={newTag}
              maxLength={68}
              className={bem('input')}
              bordered={false}
            />
            {newTag && (
              <button type="button" onClick={clearSearch} className={bem('inputClearBtn')}>
                <SvgIcon type="close" />
              </button>
            )}
            <Button onClick={handleNewTag} disabled={newTag.length === 0}>
              Добавить тег
            </Button>
          </div>

          {tags.map((item) => (
            <div key={`tagList-item-${item.id}`} className={bem('tagList')}>
              <div className={bem('tagList-item')}>
                <span className={bem('tagList-text')}>{`#${item.name}`}</span>
                <button onClick={() => handleDeleteTag(item.id)} className={bem('tagList-button')}>
                  Удалить
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
