import React from 'react';


const ExitIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4583 4.36966V11.1191L8.56138 14.4888L5.61513 12.8052L6.18139 11.8142L8.56138 13.1742L13.3169 10.4568L13.3169 5.032L8.56138 2.31453L6.18139 3.67452L5.61513 2.68357L8.56138 1L14.4583 4.36966ZM2.71197 7.36395L4.45254 5.0432L3.53947 4.3584L1 7.74436L3.53947 11.1303L4.45254 10.4455L2.99737 8.50529L7.80043 8.50529V7.36395H2.71197Z"
      fill={fill}
    />
  </svg>
);
export default ExitIcon;
