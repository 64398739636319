import React, { useState, useEffect } from 'react';
import { UsersSearchSelect } from '@components/common/UserSearchSelect';
// import { ParametersButton } from '@components/common/ParametresButton/ParametersButton';
import { cn } from '@utils/bem-config';
import { SvgIcon } from '@components/common/SvgIcon';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '@src/hooks/useDebounce';
import { selectAllUsers } from '@components/common/UsersList/usersListSlice';
import { maxValueUsersSearch } from '@utils/constants';
import { message } from 'antd';
import { searchUsersList, selectUserSearchValue } from './usersSearchSlice';
import './UsersSearch.scss';

const bem = cn('users-search');

export const UsersSearch = ({ isFocused }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = useHistory();
  const locationSearch = new URLSearchParams(location.search);
  const selectedQuery = (name) => (locationSearch?.getAll(name).length > 0 ? locationSearch?.getAll(name)[0] : '');

  // const [isParametersOpen, setIsParametersOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(selectedQuery('department'));
  const [selectedCity, setSelectedCity] = useState(selectedQuery('city'));

  const users = useSelector(selectAllUsers);
  const userSearchValue = useSelector(selectUserSearchValue);
  const [usersSearchValue, setUsersSearchValue] = useState(userSearchValue);
  const usersCity = users[0].map((user) => user.city).sort();
  const usersDepartment = users[0]
    .map((user) => user.department)
    .flat()
    .sort();

  const departmentsSet = Array.from(new Set(usersDepartment));
  const citiesSet = Array.from(new Set(usersCity));

  const debouncedUsersValue = useDebounce(usersSearchValue.trim(), 500);

  useEffect(() => {
    !isFocused && setUsersSearchValue(userSearchValue);
  }, [isFocused, userSearchValue]);

  useEffect(() => {
    dispatch(searchUsersList({ debouncedUsersValue, size: 1000, department: selectedDepartment, city: selectedCity }));

    if (selectedDepartment && selectedCity) {
      history.push(`?department=${selectedDepartment}&city=${selectedCity}`);
    } else if (selectedDepartment) {
      history.push(`?department=${selectedDepartment}`);
    } else if (selectedCity) {
      history.push(`?city=${selectedCity}`);
    } else {
      history.push('users');
    }
  }, [debouncedUsersValue, selectedDepartment, selectedCity]);

  // const toggleParameters = () => {
  //   setIsParametersOpen(!isParametersOpen);
  // };

  const handleChangeUsersSearch = (e) => {
    if (e.target.value.length <= maxValueUsersSearch) {
      setUsersSearchValue(e.target.value);
    } else {
      message.error('Недопустимое количество символов');
    }
  };

  const clearUsersSearch = () => {
    setUsersSearchValue('');
  };
  const handleAddTag = (e) => {
    switch (e.target.name) {
      case 'city':
        setSelectedCity(e.target.value);
        break;
      case 'department':
        setSelectedDepartment(e.target.value);
        break;
      default:
        break;
    }
  };
  return (
    <div className={bem()}>
      <div className={bem('box')}>
        <input
          type="text"
          value={usersSearchValue}
          onChange={handleChangeUsersSearch}
          className={bem('input')}
          placeholder="Поиск"
        />

        {usersSearchValue && (
          <button type="button" onClick={clearUsersSearch} className={bem('input-clear-btn')}>
            <SvgIcon type="close" />
          </button>
        )}
        {/* <ParametersButton toggleParameters={toggleParameters} isParametersOpen={isParametersOpen} /> */}
      </div>

      {/* {isParametersOpen && ( */}
      <div className={bem('parameters')}>
        <UsersSearchSelect
          data={citiesSet}
          handleAddTag={handleAddTag}
          selectedValue={selectedCity}
          name="city"
          title="Город"
        />
        <UsersSearchSelect
          data={departmentsSet}
          handleAddTag={handleAddTag}
          selectedValue={selectedDepartment}
          name="department"
          title="Направление"
        />
      </div>
      {/* )} */}
    </div>
  );
};
