import React from 'react';


const SearchIcon = ({ style = {}, width = '16', fill = 'var(--text-level-3)', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg className={className} width={width} height={height} viewBox={viewBox} style={style} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7511 10L15 14.2489L14.2489 15L10 10.7511L10.7511 10Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8259 4.0821L10.0755 6.83565L9.26549 7.49743L7.01588 4.74388L7.8259 4.0821Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26293 1.68565L12.7309 5.93541L10.7845 11.0636L5.37008 11.9421L1.90214 7.69231L3.84857 2.5641L9.26293 1.68565ZM4.61219 3.49987L3.09435 7.49888L5.79867 10.8129L10.0208 10.1278L11.5387 6.12884L8.83434 2.81485L4.61219 3.49987Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SearchIcon;
