import React from 'react';
import { cn } from '@utils/bem-config';
import { ButtonOrLink } from '@components/common/ButtonOrLink';
import './Button.scss';

const bem = cn('button');

const svgForBtn = (
  <svg
    className={bem('svg')}
    width="282"
    height="32"
    viewBox="0 0 282 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className={bem('triangle')} d="M0 0V32H28L0 0Z" fill="black" fillOpacity="0.39" />
    <path className={bem('triangle')} d="M93.9999 0L71.1604 32H93.9999V0Z" fill="black" fillOpacity="0.38" />
    <path className={bem('triangle')} d="M61.5 0L25.5 32H81V18.0645L61.5 0Z" fill="black" fillOpacity="0.15" />
    <path className={bem('triangle')} d="M46 32L15 0H61.7092L46 32Z" fill="black" fillOpacity="0.15" />
    <path className={bem('triangle')} d="M17.188 32L0 0H33L45.9732 32H17.188Z" fill="black" fillOpacity="0.15" />
    <path className={bem('triangle')} d="M126.188 32L109 0H142L154.973 32H126.188Z" fill="black" fillOpacity="0.15" />
    <path
      className={bem('triangle')}
      d="M68.313 0L59.313 32H91.4999L81.9999 0H68.313Z"
      fill="white"
      fillOpacity="0.15"
    />
    <path
      className={bem('triangle')}
      d="M94.1377 0L121.697 32H140.269L109.146 0H94.1377Z"
      fill="white"
      fillOpacity="0.15"
    />
    <path className={bem('triangle')} d="M188 0L215.559 32H234.132L203.009 0H188Z" fill="white" fillOpacity="0.15" />
    <path className={bem('triangle')} d="M94 0V32H122L94 0Z" fill="black" fillOpacity="0.39" />
    <path className={bem('triangle')} d="M188 0L179 32H188V0Z" fill="black" fillOpacity="0.38" />
    <path className={bem('triangle')} d="M155.5 0L119.5 32H175V18.0645L155.5 0Z" fill="black" fillOpacity="0.15" />
    <path className={bem('triangle')} d="M140 32L109 0H142L140 32Z" fill="black" fillOpacity="0.15" />
    <path
      className={bem('triangle')}
      d="M168.331 0L156.516 32H188.35L179.507 0H168.331Z"
      fill="white"
      fillOpacity="0.15"
    />
    <path className={bem('triangle')} d="M188 0V32H216L188 0Z" fill="black" fillOpacity="0.39" />
    <path className={bem('triangle')} d="M282 0L257.099 32H282V0Z" fill="black" fillOpacity="0.38" />
    <path className={bem('triangle')} d="M249.5 0L213.5 32H269V18.0645L249.5 0Z" fill="black" fillOpacity="0.15" />
    <path className={bem('triangle')} d="M234 32L203 0H250.074L234 32Z" fill="black" fillOpacity="0.15" />
    <path className={bem('triangle')} d="M266 0L257 32H279.5L270 0H266Z" fill="white" fillOpacity="0.15" />
  </svg>
);

export const Button = ({ onClick, children = 'Текст кнопки', type = 'button', disabled, ...props }) => (
  <ButtonOrLink className={bem()} type={type} disabled={disabled} onClick={onClick} {...props}>
    {svgForBtn}
    <div className={bem('text-block')}>
      <span className={bem('text')}>{children}</span>
    </div>
  </ButtonOrLink>
);
