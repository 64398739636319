import { useDispatch } from 'react-redux';
import { deletePost } from '@components/common/PostsList/postsListSlice';
import React, { useCallback, useEffect } from 'react';
import { Button } from '@components/common/Button';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { cn } from '@utils/bem-config';
import './DeletingPostForm.scss';
import { useHistory } from 'react-router-dom';

const bem = cn('deleting-form');

export const DeletingPostForm = ({ data, setIsDeletingPost, isDeletingPost, isDeletingFormSticky, postIsActive }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDeleteSubmit = async () => {
    await dispatch(deletePost(data?.postId));
    postIsActive && history.push('/feed');
  };
  const handleDeleteCancel = () => {
    setIsDeletingPost(false);
  };

  const memoizedKeyup = useCallback((e) => {
    if (e.key === 'Escape') handleDeleteCancel();
  }, []);

  useEffect(() => {
    if (isDeletingPost) {
      document.addEventListener('keyup', memoizedKeyup);
    } else {
      document.removeEventListener('keyup', memoizedKeyup);
    }
  }, [isDeletingPost]);
  return (
    <>
      {isDeletingPost && (
        <>
          <div className={bem('blur-container')}>
            <div className={bem('deleting-form', { sticky: isDeletingFormSticky })}>
              <p className={bem('deleting-form-title')}>Вы уверены что хотите удалить запись?</p>
              <div className={bem('deleting-form-btns')}>
                <Button type="button" onClick={handleDeleteSubmit}>
                  Абсолютно
                </Button>
                <ButtonAnimated onClick={handleDeleteCancel} type="cancel">
                  Отнюдь
                </ButtonAnimated>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
