import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cn } from '@utils/bem-config';
import { Tabs } from '@components/common/Tabs';
import './AdminSettings.scss';
import { TagsSettings } from '@components/pages/TagsSettings';

const bem = cn('settings');

export const AdminSettings = () => {
  function useQuery(location) {
    return new URLSearchParams(location.search ? location.search : '?tab=about');
  }
  const history = useHistory();
  const { location } = history;
  const queryParams = useQuery(location);
  useEffect(() => {
    history.push(`?${queryParams.toString()}`);
  }, []);
  const tabComponents = [
    {
      title: 'Теги',
      name: 'tags',
      component: <TagsSettings />,
      url: `${location.pathname}?tab=tags`,
    },
  ];
  const getTabIndex = (name) => {
    const foundIndex = tabComponents.findIndex((tab) => tab.name === name);
    const defaultIndex = 0;
    const activeTabIndex = foundIndex === -1 ? defaultIndex : foundIndex;
    return activeTabIndex;
  };
  const defaultTab = getTabIndex(queryParams.get('tab'));

  return (
    <div className={bem()}>
      <h1 className={bem('title')}>Администрирование</h1>
      <Tabs style={{ border: 'none' }} defaultTab={defaultTab} tabList={tabComponents} />
    </div>
  );
};
