import React from 'react';

const SettingsIcon = ({
  style = {},
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
  fill = 'var(--text-level-3)',
}) => (
  <svg
    className={className}
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24562 5.37652L6.90228 7.73275L3.63393 7.73275L1.99975 4.86638L3.63393 2L6.90228 2L8.24562 4.35624L13.3281 4.35624L13.3281 5.37652L8.24562 5.37652ZM7.3731 4.86638L6.3206 3.02028L4.21561 3.02028L3.16311 4.86638L4.21561 6.71247L6.3206 6.71247L7.3731 4.86638Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75389 10.6235L9.09723 8.26725L12.3656 8.26725L13.9998 11.1336L12.3656 14L9.09723 14L7.75389 11.6438L2.67139 11.6438L2.67139 10.6235L7.75389 10.6235ZM8.62642 11.1336L9.67891 12.9797L11.7839 12.9797L12.8364 11.1336L11.7839 9.28753L9.67891 9.28753L8.62642 11.1336Z"
      fill={fill}
    />
  </svg>
);

export default SettingsIcon;
