import axios from 'axios';
import { keycloak } from '@src/index';

const host = 'https://scroll.irlix.com/api';

export const api = {
  currentUser: () => `/employee`,
  user: (id) => `/employee/${id}`,
  userByLdap: (ldapId) => `/employee/ldap/${ldapId}`,
  usersAll: () => '/employees',
  usersSearch: () => '/employees/search',

  post: (id) => `/posts/${id}`,
  postDelete: (id) => `/posts/${id}`,
  postEdit: (id) => `/posts/${id}`,
  posts: () => '/posts',
  postsDeffered: () => '/posts/deferred',
  pinnedPosts: () => 'posts/pinned',
  fewPosts: () => '/posts/scroll',
  postsSearch: () => '/posts/search',
  postViews: (postId) => `/posts/${postId}/view`,
  pinPost: (postId) => `/posts/${postId}/pinned`,

  tags: () => '/tags',
  tagDelete: (id) => `/tags/${id}`,

  likes: () => '/posts/like',

  birthdays: () => '/employees/birthdays',

  vote: (id) => `/answer/vote/${id}`,
  deleteVote: (id) => `/answer/vote/${id}`,

  comments: () => '/comments',
  commentsSearch: () => '/comments/search',
  commentsDelete: (id) => `/comments/delete/${id}`,
  commentsRestore: (id) => `/comments/${id}`,

  photos: () => '/photo',
  postPhotosUser: (id) => `/photo/${id}`,
  onePhoto: (id) => `/photo/${id}`, // get, put, delete()

  offices: () => '/offices',
};

export const request = axios.create({
  baseURL: host,
  // timeout: 6000,
});

request.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth-token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

request.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (error.response.status === 403) keycloak.onTokenExpired();
    return Promise.reject(error);
  }
);
