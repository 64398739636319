import { Button } from '@components/common/Button';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { LinkInline } from '@components/common/LinkInline';
import { Modal } from '@components/common/Modal';
import { cn } from '@utils/bem-config';
import { DesktopQuery, TabletQuery } from '@utils/responsive';
import React, { useState } from 'react';
import './UIPage.scss';

const bem = cn('UIPage');

export const UIPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModalHandler = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeModalHandler = () => {
    setIsModalOpen(false);
  };

  const ModalContent = <p>Hello modal window !</p>;

  return (
    <section className={bem()}>
      <h1>ui page</h1>

      <hr />

      <h1>h1</h1>
      <h2>h2</h2>
      <h3>h3</h3>
      <h4>h4</h4>

      <hr />

      <DesktopQuery> DesktopQuery </DesktopQuery>
      <TabletQuery> TabletQuery </TabletQuery>

      <hr />

      <h3>Кнопки</h3>
      <Button />
      <Button type="a" />
      <Button disabled />

      <h3>Кнопки анимационные</h3>
      <ButtonAnimated type="cancel">Отмена Отмена Отмена Отмена Отмена</ButtonAnimated>
      <ButtonAnimated type="cancel">Отмена</ButtonAnimated>

      <hr />

      <h3>Ссылки</h3>
      <p>
        <LinkInline href="/">Ссылка</LinkInline>
        <LinkInline href="/" disabled>
          Ссылка disabled
        </LinkInline>
      </p>
      <p>
        <LinkInline colored href="/">
          Ссылка цветная
        </LinkInline>
        <LinkInline colored href="/" disabled>
          Ссылка цветная disabled
        </LinkInline>
      </p>

      <hr />

      <h3>Модалка</h3>
      <ButtonAnimated onClick={openModalHandler}>Кнопка для портала</ButtonAnimated>
      {isModalOpen ? <Modal closeHandler={closeModalHandler} ModalContent={ModalContent} /> : ''}
    </section>
  );
};
