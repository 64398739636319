import { Post } from '@components/common/Post';
import { cn } from '@utils/bem-config';
import React from 'react';
import './PostsResult.scss';

const bem = cn('posts-result');

export const PostsResult = ({ searchedPosts }) => (
  <div className={bem()}>
    {searchedPosts?.length === 0 && 'Постов не найдено'}
    {searchedPosts?.length > 0 && (
      <>
        {searchedPosts.map((post) => (
          <Post key={post?.postId} data={post} />
        ))}
      </>
    )}
  </div>
);
