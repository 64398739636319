import { TernaryForSkeleton } from '@components/common/Skeletons/Skeleton';
import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import { cn } from '@utils/bem-config';
import './PostHeader.scss';
import { PostAvatarHeader } from '@components/common/PostAvatarHeader';
import { BigMobileQuery } from '@utils/responsive';

const bem = cn('post-header');
export const PostHeader = ({ data, user, url, isLoading, postIsActive, mobile }) => (
  <header className={bem({ mobile })}>
    <BigMobileQuery>
      <PostAvatarHeader url={url} isLoading={isLoading} userPhoto={user?.photos?.avatar?.url} mobile={mobile} />
    </BigMobileQuery>
    <div className={bem('user-name-container')}>
      <TernaryForSkeleton isLoading={isLoading} width="13rem">
        <Link to={url} className={bem('user-name')}>
          {user ? `${user?.lastName} ${user?.firstName}` : 'Имя'}
        </Link>
      </TernaryForSkeleton>
      <div className={bem('date')}>
        {postIsActive ? (
          <span className={bem('date-active')}>
            <TernaryForSkeleton isLoading={isLoading} width="9rem">
              {moment.parseZone(data?.date).local().format('DD MMMM YYYY в HH:mm')}
            </TernaryForSkeleton>
          </span>
        ) : (
          <Link
            to={{
              pathname: `/feed/${data?.postId}`,
              state: { fromScroll: true },
            }}
            className={bem('date-link')}
          >
            <TernaryForSkeleton isLoading={isLoading} width="9rem">
              {moment.parseZone(data?.date).local().format('DD MMMM в HH:mm')}
            </TernaryForSkeleton>
          </Link>
        )}
      </div>
    </div>
  </header>
);
