import { SvgIcon } from '@components/common/SvgIcon';
import { TernaryForSkeleton } from '@components/common/Skeletons/Skeleton';
import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@utils/bem-config';
import { addLikes, addViews } from '@components/common/PostsList/postsListSlice';
import { useDebounce } from '@src/hooks/useDebounce';
import { useDispatch } from 'react-redux';
import { viewedPostStorageItemName } from '@utils/constants';
import { formatLikes } from '@src/utils/formatLikes';
import { CommonTooltip } from '@components/common/CommonTooltip';
import './FeedbackPostButtons.scss';
import { LikeButtonAnimated } from 'animated-likes-counter';

const bem = cn('feedback-buttons');
export const FeedbackPostButtons = ({ data, commentInputRef, isLoading, setCommentsIsOpen }) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const [likes, setLikes] = useState(data?.likes);
  const [numberOfLikesToAdd, setNumberOfLikesToAdd] = useState(0);
  const debouncedNumberOfLikesToAdd = useDebounce(numberOfLikesToAdd, 500);
  const dispatch = useDispatch();
  const viewsRef = useRef();

  useEffect(() => {
    (() => {
      if (debouncedNumberOfLikesToAdd) {
        dispatch(addLikes({ postId: data.postId, debouncedNumberOfLikesToAdd }));
      }
      setNumberOfLikesToAdd(0);
    })();
  }, [debouncedNumberOfLikesToAdd]);

  useEffect(() => {
    setLikes(data?.likes);
  }, [data?.likes]);

  const options = {
    root: null,
    rootMargin: '5px',
    threshold: 0.5,
  };

  const callback = (entries, observer) => {
    !localStorage.getItem(viewedPostStorageItemName)
      ? localStorage.setItem(viewedPostStorageItemName, JSON.stringify([]))
      : '';
    if (!JSON.parse(localStorage.getItem(viewedPostStorageItemName))?.includes(data.postId)) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          localStorage.setItem(
            viewedPostStorageItemName,
            JSON.stringify([...JSON.parse(localStorage.getItem(viewedPostStorageItemName)), data.postId])
          );
          observer.unobserve(entry.target);
          dispatch(addViews({ postId: data.postId }));
        }
      });
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    const element = viewsRef.current;
    if (data) {
      observer.observe(element);
    }
    return () => {
      observer.unobserve(element);
    };
  }, []);

  const handleLike = () => {
    if (numberOfLikesToAdd >= 49) {
      setDisabledButton(true);
      setTimeout(() => {
        setDisabledButton(false);
      }, 1000);
    }
    setNumberOfLikesToAdd((prevState) => prevState + 1);
    setLikes((prevState) => prevState + 1);
  };

  const handleComment = () => {
    setCommentsIsOpen(true);
    commentInputRef?.current && commentInputRef.current.focus();
  };

  return (
    <div className={bem()}>
      <div className={bem('numbered-block')}>
        <CommonTooltip title={likes >= 1000 ? likes : ''}>
          <LikeButtonAnimated
            disabledButton={disabledButton}
            handleLike={handleLike}
            likes={
              <TernaryForSkeleton isLoading={isLoading} width="2rem">
                <span className={bem('count')}>{formatLikes(likes)}</span>
              </TernaryForSkeleton>
            }
          />
        </CommonTooltip>

        <CommonTooltip title="Комментарии">
          <button type="button" className={bem('numbered-btn', { type: 'comment' })} onClick={handleComment}>
            <SvgIcon type="comment" className={bem('numbered-btn-icon', { active: true })} />
            <TernaryForSkeleton isLoading={isLoading} width="2rem">
              <span className={bem('count')}>{data?.commentCount}</span>
            </TernaryForSkeleton>
          </button>
        </CommonTooltip>

        <CommonTooltip title="Взгляды">
          <div className={bem('numbered-btn', { type: 'view' })} ref={viewsRef}>
            <SvgIcon type="views" className={bem('numbered-btn-icon')} />
            <TernaryForSkeleton isLoading={isLoading} width="2rem">
              <span className={bem('count')}>{data?.views}</span>
            </TernaryForSkeleton>
          </div>
        </CommonTooltip>
      </div>
      {data?.isPinned && (
        <CommonTooltip title="Пост закреплен">
          <div className={bem('numbered-btn', { type: 'pinned' })}>
            <SvgIcon type="pinned" className={bem('numbered-btn-icon')} />
            <TernaryForSkeleton isLoading={isLoading} width="2rem">
              <span className={bem('count')}>Пост закреплен</span>
            </TernaryForSkeleton>
          </div>
        </CommonTooltip>
      )}
    </div>
  );
};
