import AvatarIcon from '@assets/images/avatar-default-icon.svg';
import FiredUserCommentAvatar from '@assets/images/firedUserCommentAvatar.svg';
import { deleteComment, restoreComment } from '@src/components/common/PostsList/postsListSlice';
import { firedUserState, getFiredUser, selectAllUsers } from '@src/components/common/UsersList/usersListSlice';
import { DeleteButton } from '@components/common/DeleteButton';
import { cn } from '@utils/bem-config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './UserItemComment.scss';
import { CommonTooltip } from '@components/common/CommonTooltip';

const bem = cn('commentator');
const UserItemComment = ({ comment, commentId, currentUserLdapId, currentUserRoles }) => {
  const dispatch = useDispatch();
  const [isCommentDeleted, setIsCommentDeleted] = useState(false);
  const UsersList = useSelector(selectAllUsers);
  const firedUsersStore = useSelector(firedUserState);
  const user = UsersList[0].find((userItem) => userItem.ldapId === comment.ldapId);
  const pageUrl = `/users/${comment.ldapId}`;

  useEffect(() => {
    comment.deleted ? setIsCommentDeleted(true) : setIsCommentDeleted(false);
  }, [comment.deleted]);

  const handleDelete = () => {
    dispatch(deleteComment(commentId));
  };

  const handleRestore = () => {
    dispatch(restoreComment(commentId));
  };

  const handleCheckUser = () => {
    comment.fired &&
      !firedUsersStore.find((item) => item.ldapId === comment.ldapId) &&
      dispatch(getFiredUser(comment.ldapId));
  };

  return (
    <div className={bem(isCommentDeleted ? { status: 'deleted' } : '')}>
      {isCommentDeleted ? (
        <div className={bem('comment-restore')}>
          <span className={bem('comment-restore-text')}>Комментарий удалён.</span>
          <button type="button" className={bem('comment-restore-button')} onClick={handleRestore}>
            Восстановить
          </button>
        </div>
      ) : (
        <>
          <Link onClick={handleCheckUser} className={bem('avatar')} to={pageUrl}>
            <img
              src={user?.photos?.avatar?.url || (comment.fired && FiredUserCommentAvatar) || AvatarIcon}
              className={bem('photo')}
              alt="user"
            />
          </Link>
          <div className={bem('content')}>
            <header className={bem('content-header')}>
              <Link onClick={handleCheckUser} className={bem('name-link')} to={pageUrl}>
                {comment.username}
              </Link>
              {(user?.ldapId === currentUserLdapId || currentUserRoles?.includes('ADMIN')) && (
                <CommonTooltip title="Удалить комментарий">
                  <div className={bem('comment-delete-container')}>
                    <DeleteButton onClick={handleDelete} />
                  </div>
                </CommonTooltip>
              )}
            </header>
            <div className={bem('comment')}>{comment.body}</div>
            <span className={bem('date')}>{moment.parseZone(comment.date).local().format('DD MMMM в HH:mm')}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default UserItemComment;
