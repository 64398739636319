import { useState, useEffect } from 'react';
import { selectAllUsers } from '@components/common/UsersList/usersListSlice';
import { useSelector } from 'react-redux';

export const useHuntedUser = (postContent) => {
  const [isTypeAt, setIsTypeAt] = useState(false);
  const [foundUsersList, setFoundUsersList] = useState([]);
  const [huntedUser, setHuntedUser] = useState('');
  const allUsers = useSelector(selectAllUsers)[0];

  const getCurrentPosition = () => {
    const range = document.getSelection().getRangeAt(0).cloneRange();
    range.setStart(range.startContainer, Math.max(0, range.startOffset - 1));
    range.setEnd(range.startContainer, range.startOffset + 1);
    const positions = range.getBoundingClientRect();
    const { scrollY } = window;

    return { left: positions.left, top: positions.bottom + scrollY };
  };

  const resetFoundUsers = () => {
    setIsTypeAt(false);
    setFoundUsersList([]);
  };

  const filterFoundUsers = (text) => {
    const foundMatch = text.match(/@[а-яА-Я]*/g);
    const requiredUser = foundMatch[foundMatch.length - 1].slice(1);

    setHuntedUser(requiredUser);

    const foundUser = allUsers
      .filter((user) => user.firstName.includes(requiredUser) || user.lastName.includes(requiredUser))
      .sort((a, b) => (a.lastName < b.lastName ? -1 : 1));

    if (foundUser.length > 0) {
      setFoundUsersList(foundUser.slice(0, 10));
      setIsTypeAt(true);
    } else {
      foundUsersList.length && resetFoundUsers();
    }
  };

  useEffect(() => {
    if (postContent && postContent.includes('@')) {
      const stringExcludeBrackets = postContent.match(/[^<>]+/g);
      const newPostContent = stringExcludeBrackets.join(' ');
      const indexLastMatch = newPostContent.lastIndexOf('@');
      const sliceStringFromMatch = newPostContent.slice(indexLastMatch - 1);
      const symbolBeforeSting = sliceStringFromMatch[0];

      if (symbolBeforeSting.trim()) {
        foundUsersList.length && resetFoundUsers();
        return;
      }

      filterFoundUsers(postContent);
    } else {
      foundUsersList.length && resetFoundUsers();
    }
  }, [postContent]);

  return { isTypeAt, foundUsersList, huntedUser, resetFoundUsers, getCurrentPosition };
};
