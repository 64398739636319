import {
  defaultViewPortWidth,
  defaultViewPortHeight,
  velocity,
  transitionsBezierCurve,
  rawPaths,
} from '../consts/svgTemplate';
import { getNumberWithAccuracy } from './getNumberWithAccurancy';
import { pathRegExp } from './regExp';
import { getRandomInt } from './getRandomInt';
import { shuffleArray } from './shuffleArray';

const getPathLine = (lineRule) => {
  const coordinates = lineRule.coordinates.join(' ');

  return `${lineRule.type} ${coordinates} \n`;
};

const getPath = (twoLinesFigure) =>
  twoLinesFigure.figurePathCommands.reduce((path, lineRule) => `${path} ${getPathLine(lineRule)}`, '');

const getTwoLinesFigureCenter = (figurePathCommands) => {
  const startPoint = {
    x: Number(figurePathCommands[0].coordinates[0]),
    y: Number(figurePathCommands[0].coordinates[1]),
  };
  const endPoint = {
    x: Number(figurePathCommands[1].coordinates[4]),
    y: Number(figurePathCommands[1].coordinates[5]),
  };

  return {
    x: getNumberWithAccuracy(startPoint.x + (endPoint.x - startPoint.x) / 2),
    y: getNumberWithAccuracy(startPoint.y + (endPoint.y - startPoint.y) / 2),
  };
};

const getHorizontalQuarter = (defaultFigureCenterX) => Math.ceil((4 * defaultFigureCenterX) / defaultViewPortWidth);

const countSpaces = (targetViewPort) => (targetViewPort - defaultViewPortWidth) / 3;

const getViewPortOffset = (quarter, targetViewPort) => {
  const noKeyFramesSpace = countSpaces(targetViewPort, defaultViewPortWidth);

  return getNumberWithAccuracy((quarter - 1) * noKeyFramesSpace);
};

const getTransformedFigure = (twoLinesFigure, offset) => {
  const transformedFigurePathCommands = [
    {
      type: 'M',
      coordinates: [...twoLinesFigure.figurePathCommands[0].coordinates],
    },
    {
      type: 'C',
      coordinates: [...twoLinesFigure.figurePathCommands[1].coordinates],
    },
    {
      type: 'C',
      coordinates: [...twoLinesFigure.figurePathCommands[2].coordinates],
    },
  ];

  transformedFigurePathCommands[0].coordinates[0] = getNumberWithAccuracy(
    transformedFigurePathCommands[0].coordinates[0] + offset
  );
  for (let i = 0; i < 3; i += 1) {
    transformedFigurePathCommands[1].coordinates[i * 2] = getNumberWithAccuracy(
      transformedFigurePathCommands[1].coordinates[i * 2] + offset
    );
    transformedFigurePathCommands[2].coordinates[i * 2] = getNumberWithAccuracy(
      transformedFigurePathCommands[2].coordinates[i * 2] + offset
    );
  }

  return {
    figureCenter: {
      x: getNumberWithAccuracy(twoLinesFigure.figureCenter.x + offset),
      y: twoLinesFigure.figureCenter.y,
    },
    figurePathCommands: transformedFigurePathCommands,
  };
};

const getFigureForViewPort = (twoLinesFigure, viewPortOffsets) => {
  const figureHorizontalQuarter = getHorizontalQuarter(twoLinesFigure.figureCenter.x);

  return getTransformedFigure(twoLinesFigure, viewPortOffsets[figureHorizontalQuarter - 1]);
};

const getTwoLinesFigureByPoints = (points) => {
  const figurePathCommands = [
    {
      type: 'M',
      coordinates: [getNumberWithAccuracy(Number(points[0])), getNumberWithAccuracy(Number(points[1]))],
    },
  ];
  for (let i = 0; i < 2; i += 1) {
    const coordinates = [];
    for (let j = 0; j < 6; j += 1) {
      coordinates.push(getNumberWithAccuracy(Number(points[i * 6 + j + 2])));
    }
    figurePathCommands.push({
      type: 'C',
      coordinates: [...coordinates],
    });
  }
  return {
    figurePathCommands,
    figureCenter: getTwoLinesFigureCenter(figurePathCommands),
  };
};

const getSvgTemplate = (paths) =>
  paths.map((path) => {
    const figurePoints = Array.from(path.matchAll(pathRegExp))[0].slice(1);
    return getTwoLinesFigureByPoints(figurePoints);
  });

const getIntervalBetweenKeyframes = (svgTemplate) => {
  const intervals = [];
  const keyframesAmount = svgTemplate.length;
  for (let i = 0; i < keyframesAmount - 1; i += 1) {
    const horizontalDiff = svgTemplate[i].figureCenter.x - svgTemplate[i + 1].figureCenter.x;
    const verticalDiff = svgTemplate[i].figureCenter.x - svgTemplate[i + 1].figureCenter.x;
    intervals.push(getNumberWithAccuracy(Math.sqrt(horizontalDiff ** 2 + verticalDiff ** 2)));
  }
  return intervals;
};

const getTransitionKeyTimes = (svgTemplate) => {
  const intervals = getIntervalBetweenKeyframes(svgTemplate);
  const intervalsAmount = intervals.length;
  const absolutePeriods = intervals.map((interval) => getNumberWithAccuracy(interval / velocity));
  const totalPeriod = getNumberWithAccuracy(absolutePeriods.reduce((accumulator, interval) => accumulator + interval));

  const keyTimes = [];
  for (let i = 0; i <= intervalsAmount; i += 1) {
    keyTimes.push(getNumberWithAccuracy(i / intervalsAmount));
  }

  const keyTimesResult = keyTimes.join(';');
  return {
    totalPeriod,
    keyTimesResult,
  };
};

const getRandomSvgPaths = () => {
  const svgPaths = rawPaths.split('\n');
  const randomPathIndex = getRandomInt(svgPaths.length);
  const randomPath = svgPaths.splice(randomPathIndex, 1);
  shuffleArray(svgPaths);
  return [...randomPath, ...svgPaths, ...randomPath];
};

/**
 * Генерирует данные необходимые для svg анимации
 *
 * @param targetViewPort должен быть больше чем defaultViewPortWidth
 * @returns {{targetViewPort, initialPath, defaultViewPortHeight: number, animatePath}}
 */
export const generateSvgAnimation = (targetViewPort) => {
  const viewport = `0 0 ${targetViewPort} ${defaultViewPortHeight}`;

  const randomSvgPaths = getRandomSvgPaths();
  const svgTemplate = getSvgTemplate(randomSvgPaths);

  const isViewPortWider = targetViewPort > defaultViewPortWidth;

  const viewPortOffsets = [];
  if (isViewPortWider) {
    viewPortOffsets.push(...[1, 2, 3, 4].map((quarter) => getViewPortOffset(quarter, targetViewPort)));
  }

  const resultSvgTemplate = isViewPortWider
    ? svgTemplate.map((twoLineFigure) => getFigureForViewPort(twoLineFigure, viewPortOffsets))
    : svgTemplate;

  const initialPath = getPath(resultSvgTemplate[0]);
  const animatePath = resultSvgTemplate.reduce(
    (accumulator, twoPathFigure) => `${accumulator}${getPath(twoPathFigure)};`,
    ''
  );

  const { totalPeriod: dur, keyTimesResult: keyTimes } = getTransitionKeyTimes(resultSvgTemplate);

  const keySplines = new Array(resultSvgTemplate.length - 1).fill(transitionsBezierCurve).join('');

  const initialFill = `hsla(${getRandomInt(360)}, 50%, 50%, .4)`;

  const emptyMiddleArray = new Array(resultSvgTemplate.length - 2).fill(null);
  const middleFill = emptyMiddleArray.map(() => `hsl(${getRandomInt(360)}, 100%, 50%, .4)`).join('');

  const animateFill = `${initialFill}${middleFill}${initialFill}`;

  return {
    height: defaultViewPortHeight,
    width: targetViewPort,
    viewport,
    dur,
    keyTimes,
    keySplines,
    initialPath,
    animatePath,
    initialFill,
    animateFill,
  };
};
