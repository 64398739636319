import React from 'react';
import { cn } from '@src/utils/bem-config';
import './DropdownFoundUsers.scss';

const bem = cn('dropdown-found-users');

export const DropdownFoundUsers = ({ dropDownPosition, huntedUser, users, modifyPostContent }) => (
  <ul style={{ left: dropDownPosition.left, top: dropDownPosition.top }} className={bem('list')}>
    {!huntedUser && <div>Введите имя или фамилию сотрудника</div>}
    {huntedUser &&
      users.map((user) => (
        <li key={user.userId} id={user.ldapId} className={bem('item')} onClick={modifyPostContent}>
          {user.lastName} {user.firstName}
        </li>
      ))}
  </ul>
);
