import React from 'react';

const AvatarIcon = ({
  style = {},
  fillInner = '',
  fillOuter = '',
  width = '32',
  height = '32',
  className = '',
  viewBox = '0 0 32 32',
}) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.25977 26.8699C7.18221 30.0251 11.3604 32 15.9999 32C24.8365 32 31.9999 24.8354 31.9999 15.9975C31.9999 7.29411 25.0528 0.213514 16.4022 0L23.266 9.27635L16.2106 25.5075L4.25977 26.8699Z" fill={fillInner} />
    <path d="M12.6739 0.341187C5.43363 1.87225 0 8.30019 0 15.9974C0 18.8702 0.756903 21.5663 2.08214 23.8969L14.0126 22.5367L19.5948 9.69479L12.6739 0.341187Z" fill={fillOuter} />
  </svg >
);

export default AvatarIcon;
