import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '@utils/bem-config';
import { Error } from '@components/common/Error';
import { useInterval } from '@src/hooks/useInterval';
import { Post } from '@components/common/Post';
import { Button } from '@components/common/Button';
import { STATUS, StatusSwitch } from '@src/hoc/StatusSwitch';
import { PostsListOnDone } from '@components/common/PostsListOnDone';
import { postToLoadingCount } from '@utils/constants';
import { resetScroll } from '@utils/resetScroll';
import {
  addPostStatus,
  checkNewPosts,
  getFewPosts,
  getMorePosts,
  getPinnedPosts,
  pinPosts,
  pinPostsStatus,
  selectLastPostID,
  selectNewPosts,
  selectPosts,
  selectStatus,
  setIsEditPost,
  setIsNotification,
  updatePosts,
} from './postsListSlice';
import './PostsList.scss';

const bem = cn('posts-list');

export const PostsList = () => {
  const dispatch = useDispatch();
  const lastPostID = useSelector(selectLastPostID);
  const posts = useSelector(selectPosts);
  const pinsPosts = useSelector(pinPosts);
  const newPosts = useSelector(selectNewPosts);
  const status = useSelector(selectStatus);
  const pinStatus = useSelector(pinPostsStatus);
  const postAddingStatus = useSelector(addPostStatus);
  const offsetForStartLoading = 300;
  const currentFavIcon = useSelector(setIsNotification);
  const delay = 60000;

  useEffect(() => {
    dispatch(getPinnedPosts());
  }, []);

  useEffect(() => {
    resetScroll();
    !lastPostID && dispatch(getFewPosts({ size: postToLoadingCount }));
    dispatch(setIsEditPost(null));
  }, []);

  useInterval(() => {
    dispatch(checkNewPosts());
  }, delay);

  useEffect(() => {
    const favIcon = document.getElementById('favicon');
    favIcon.href = currentFavIcon ? 'favicon-32x32-Orange.png' : 'favicon-32x32.png';
  }, [currentFavIcon]);

  const updatePostList = () => {
    dispatch(updatePosts({ size: postToLoadingCount }));
  };

  const handleScroll = useCallback(() => {
    const clientHeight = document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : document.body.clientHeight;
    const documentHeight = document.documentElement.scrollHeight
      ? document.documentElement.scrollHeight
      : document.body.scrollHeight;
    const scrollTop = window.pageYOffset
      ? window.pageYOffset
      : document.documentElement.scrollTop
      ? document.documentElement.scrollTop
      : document.body.scrollTop;

    if (documentHeight - clientHeight - offsetForStartLoading <= scrollTop) {
      window.removeEventListener('scroll', handleScroll);
      if (newPosts.length + pinsPosts.length >= postToLoadingCount) {
        dispatch(getMorePosts({ size: postToLoadingCount }));
      }
    }
  }, [lastPostID]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastPostID]);
  return (
    <div className={bem()}>
      {postAddingStatus === STATUS.LOADING ? <Post isLoading /> : ''}

      {currentFavIcon && (
        <Button type="button" onClick={updatePostList}>
          Обновить ленту
        </Button>
      )}

      <StatusSwitch
        status={pinStatus}
        ComponentOnDone={
          <>
            {pinsPosts?.length > 0 && <span className={bem('text-between-posts')}>Закрепленные посты</span>}
            <PostsListOnDone posts={pinsPosts} pinsLength={pinsPosts.length} />
            {pinsPosts?.length > 0 && <span className={bem('text-between-posts')}>Новые посты</span>}
          </>
        }
        ComponentOnLoading={
          <>
            <Post isLoading />
            <Post isLoading />
          </>
        }
        ComponentOnError={<Error action={getFewPosts} actionParams={{ size: postToLoadingCount }} />}
      />
      <StatusSwitch
        status={status}
        ComponentOnDone={
          posts?.length > 0 ? <PostsListOnDone posts={posts} pinsLength={pinsPosts.length} /> : 'Нет постов'
        }
        ComponentOnLoading={
          <>
            <Post isLoading />
            <Post isLoading />
          </>
        }
        ComponentOnError={<Error action={getFewPosts} actionParams={{ size: postToLoadingCount }} />}
      />
    </div>
  );
};
