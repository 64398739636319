import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS } from '@src/hoc/StatusSwitch';
import { api, request } from '@src/app/api';
import { message } from 'antd';
import { getAvatarList } from '@src/components/pages/UserPage/getAvatarListAction';
import { changeAvatarInBirthday, deleteAvatarInBirthday } from '@components/common/BirthdaysList/birthdaysListSlice';

const initialState = {
  currentUser: {},
  currentUserStatus: STATUS.NO_STATUS,
  userInformationStatus: STATUS.NO_STATUS,
  userPicturesStatus: STATUS.NO_STATUS,
};

export const getCurrentUser = createAsyncThunk('getCurrentUser', async () => {
  const response = await request.get(api.currentUser());

  return response.data;
});

export const deleteUserPicture = createAsyncThunk(
  'deleteUserPicture',
  async ({ userLdapId, photoLabel, isAdmin }, { dispatch, getState }) => {
    const { currentUser } = getState().storeMyProfilePreview;
    const path = photoLabel === 'AVATAR' ? 'avatar' : 'background';
    const photoId = currentUser?.photos?.[path]?.photoId;

    await request.delete(api.onePhoto(isAdmin || photoId), {
      params: { id: isAdmin || photoId },
    });
    const { data } = await request.get(isAdmin ? api.userByLdap(userLdapId) : api.currentUser());

    photoLabel === 'AVATAR' && dispatch(deleteAvatarInBirthday({ userLdapId, photoId }));
    dispatch(getAvatarList({ userLdapId }));
    return { userLdapId, data: data.photos, photoLabel };
  }
);

export const updateUserAvatar = createAsyncThunk(
  'updateUserAvatar',
  async ({ userLdapId, file, userId, isAdmin }, { dispatch }) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await request.post(isAdmin ? api.postPhotosUser(userId) : api.photos(), formData, {
      params: { label: 'AVATAR' },
      headers: { contentType: 'multipart/form-data' },
    });

    dispatch(changeAvatarInBirthday({ userId, url: response.data.url, photoId: response.data.photoId }));
    dispatch(getAvatarList({ userLdapId }));
    return { userLdapId, data: response.data };
  }
);

export const updateUserBackground = createAsyncThunk(
  'updateUserBackground',
  async ({ userLdapId, file, userId, isAdmin }) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await request.post(isAdmin ? api.postPhotosUser(userId) : api.photos(), formData, {
      params: { label: 'BACKGROUND' },
      headers: { contentType: 'multipart/form-data' },
    });
    return { userLdapId, data: response.data };
  }
);

export const updateUserInformation = createAsyncThunk(
  'updateUserInformation',
  async ({ data, notification }, { rejectWithValue }) => {
    try {
      const response = await request.put(api.currentUser(), data, {
        headers: {
          contentType: 'application/json',
        },
      });
      return { data: response.data, notification };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const MyProfilePreviewSlice = createSlice({
  name: 'getCurrentUser',
  initialState,
  extraReducers: {
    [getCurrentUser.pending]: (state) => {
      state.currentUserStatus = STATUS.LOADING;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
      state.currentUserStatus = STATUS.DONE;
    },
    [getCurrentUser.rejected]: (state) => {
      state.currentUserStatus = STATUS.ERROR;
    },
    [updateUserInformation.fulfilled]: (state, action) => {
      state.currentUser = action.payload.data;
      state.userInformationStatus = STATUS.DONE;
      message.success(`Изменено: ${action.payload.notification}`);
    },
    [updateUserInformation.pending]: (state) => {
      state.userInformationStatus = STATUS.LOADING;
    },
    [updateUserInformation.rejected]: (state, response) => {
      message.error(response.payload.debugMessage);
      state.userInformationStatus = STATUS.ERROR;
    },
    [updateUserAvatar.fulfilled]: (state, action) => {
      if (state.currentUser.ldapId === action.payload.userLdapId) {
        state.currentUser.photos.avatar = action.payload.data;
        state.userAvatarStatus = STATUS.DONE;
      }
    },
    [updateUserAvatar.pending]: (state) => {
      state.userAvatarStatus = STATUS.LOADING;
    },
    [updateUserAvatar.rejected]: (state) => {
      state.userAvatarStatus = STATUS.ERROR;
    },
    [updateUserBackground.fulfilled]: (state, action) => {
      if (state.currentUser.ldapId === action.payload.userLdapId) {
        state.currentUser.photos.background = action.payload.data;
        state.userBackgroundStatus = STATUS.DONE;
      }
    },
    [updateUserBackground.pending]: (state) => {
      state.userBackgroundStatus = STATUS.LOADING;
    },
    [updateUserBackground.rejected]: (state) => {
      state.userBackgroundStatus = STATUS.ERROR;
    },

    [deleteUserPicture.fulfilled]: (state, action) => {
      if (state.currentUser.ldapId === action.payload.userLdapId) {
        state.currentUser.photos = action.payload.data;
        state.userPicturesStatus = STATUS.DONE;
      }
    },
    [deleteUserPicture.pending]: (state) => {
      state.userPicturesStatus = STATUS.LOADING;
    },
    [deleteUserPicture.rejected]: (state) => {
      message.error('Ошибка при удалении изображения!');
      state.userPicturesStatus = STATUS.ERROR;
    },
  },
});

export const selectCurrentUser = (state) => state.storeMyProfilePreview.currentUser;
export const selectStatus = (state) => state.storeMyProfilePreview.currentUserStatus;
export const selectUserInformationStatus = (state) => state.storeMyProfilePreview.userInformationStatus;

export default MyProfilePreviewSlice.reducer;
