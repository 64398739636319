import { cn } from '@utils/bem-config';
import React, { useCallback, useEffect, useState } from 'react';
import './ReactCropComponent.scss';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const bem = cn('crop-component');

export const ReactCropComponent = ({ upImg, setResult }) => {
  const [imgRef, setImgRef] = useState();
  const [crop, setCrop] = useState({ unit: 'px', width: 50, aspect: 1 });

  const onLoad = useCallback((image) => {
    setImgRef(image);
  }, []);

  const getCroppedImg = (image) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        0.95
      );
    });
  };

  async function makeClientCrop() {
    if (imgRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imgRef);
      setResult(croppedImageUrl);
    }
  }

  useEffect(() => {
    makeClientCrop();
  }, [imgRef]);

  return (
    <>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={() => makeClientCrop()}
        circularCrop
        ruleOfThirds
        minHeight={50}
        className={bem('element')}
      />
    </>
  );
};
