import { useMediaQuery } from 'react-responsive';

export const DesktopQuery = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

export const TabletQuery = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  return isMobile ? children : null;
};
export const LittleTabletQuery = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 768 });
  return isMobile ? children : null;
};
export const BigMobileQuery = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
export const MobileQuery = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 321 });
  return isMobile ? children : null;
};
export const LittleMobileQuery = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 320 });
  return isMobile ? children : null;
};

// export const TabletQuery = ({ children }) => {
//   const isTablet = useMediaQuery({ maxWidth: 1023 });
//   return isTablet ? children : null;
// };

// export const TabletBigQuery = ({ children }) => {
//   const isTabletBig = useMediaQuery({ maxWidth: 1279 });
//   return isTabletBig ? children : null;
// };

// export const MacbookQuery = ({ children }) => {
//   const isMacbook = useMediaQuery({ maxWidth: 1439 });
//   return isMacbook ? children : null;
// };

// $mobile: 767px;
// $tablet: 1024px - 1;
// $tabletBig: 1280px - 1;
// $macbook: 1440px - 1;
// $desktopL: 1920px - 1;
