import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  userLdapId: '',
  roles: [],
  tokenStatus: 'no-status',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addToken: (state, actions) => {
      state.token = `Bearer ${actions.payload}`;
    },
    addRoles: (state, actions) => {
      state.roles = actions.payload;
    },
    addUserId: (state, actions) => {
      state.userLdapId = actions.payload;
    },
  },
});

export const { addToken, addRoles, addUserId } = appSlice.actions;

export const selectToken = (state) => state.app.token;
export const selectRoles = (state) => state.app.roles;
export const selectUserLdapId = (state) => state.app.userLdapId;

export default appSlice.reducer;
