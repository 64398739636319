import React from 'react';
import { cn } from '@utils/bem-config';
import './AppCard.scss';
import { LinkAnimated } from '@components/common/ButtonAnimated';
// import { Button } from '@components/common/Button';

const bem = cn('app-card');

export const AppCard = ({ name = 'Название', description = 'Описание', buttonName = 'Открыть', buttonData }) => (
  <article className={bem()}>
    <h1 className={bem('name')}>{name}</h1>
    <p className={bem('description')}>{description}</p>
    <div className={bem('button')}>
      <LinkAnimated type="a" {...buttonData}>
        {buttonName}
      </LinkAnimated>

      {/* <Button type="a" {...buttonData}>
        Открыть
      </Button> */}
    </div>
  </article>
);
