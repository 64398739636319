import React from 'react';


const WikiIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.56629 2H11.4338L14.8624 8.00004L11.4338 14.0001H4.56629L1.1377 8.00004L4.56629 2ZM5.24022 3.1613L2.47522 8.00004L5.24022 12.8388H10.7599L13.5249 8.00004L10.7599 3.1613H5.24022Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22542 11.0968L7.22542 7.22583L8.38672 7.22583L8.38672 11.0968L7.22542 11.0968Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22542 6.4516L7.22542 4.9032L8.38672 4.9032L8.38672 6.4516L7.22542 6.4516Z"
      fill={fill}
    />
  </svg>
);
export default WikiIcon;
