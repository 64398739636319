import React from 'react';
import { STATUS } from '.';

/**
 *
 * @param {{
 * status: STATUS,
 * ComponentOnNoStatus: React.Component,
 * ComponentOnDone: React.Component,
 * ComponentOnLoading: React.Component,
 * ComponentOnError: React.Component,
 * }} props
 * @returns
 */

const NoStatus = () => <>no status</>;

export const StatusSwitch = ({
  status,
  ComponentOnNoStatus = <NoStatus />,
  ComponentOnDone,
  ComponentOnLoading,
  ComponentOnError,
}) => (
  <>
    {status === STATUS.NO_STATUS && ComponentOnNoStatus}
    {status === STATUS.DONE && ComponentOnDone}
    {status === STATUS.LOADING && ComponentOnLoading}
    {status === STATUS.ERROR && ComponentOnError}
  </>
);
