import React from 'react';


const IconClock = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 4.56619L14 11.4338L7.99992 14.8624L1.99984 11.4338L1.99984 4.56619L7.99992 1.13757L14 4.56619ZM12.8387 5.24012L7.99992 2.47511L3.16114 5.24012L3.16114 10.7598L7.99992 13.5248L12.8387 10.7598L12.8387 5.24012Z" fill="#A8ADB8" />
    <line x1="8.29314" y1="8.16251" x2="6.05696" y2="6.39201" stroke="#A8ADB8" />
    <line x1="7.71842" y1="8.15616" x2="11.3731" y2="5.66551" stroke="#A8ADB8" />
  </svg>

);
export default IconClock;
