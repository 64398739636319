import React from 'react';


const WikiFillIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} xmlns="http://www.w3.org/2000/svg" fill="none">
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5025 1.87964H4.49732L1 7.99995L4.49732 14.1203H11.5025L14.9998 7.99995L11.5025 1.87964Z"
    />
    <path d="M8 6V13" stroke="white" strokeWidth="1.33" />
    <path d="M8 3V4.5" stroke="white" strokeWidth="1.33" />
  </svg>
);
export default WikiFillIcon;
