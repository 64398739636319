import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@utils/bem-config';
import './CreatorItem.scss';

const bem = cn('creators-item');

export function CreatorItem({ user }) {
  const pageUrl = user.ldapId ? `/users/${user.ldapId}` : '404';

  return (
    <div className={bem()}>
      <Link className={bem('name-link')} to={pageUrl}>
        {user.name}
      </Link>
      <p className={bem('department')}>{user.department}</p>
    </div>
  );
}
