import React from 'react';

const ViewsIcon = ({
  style = {},
  stroke = 'var(--text-level-3)',
  fill = 'var(--text-level-3)',
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}) => (
  <svg width={width} height={height} viewBox={viewBox} style={style} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="2.5" stroke={stroke}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.81386 12.6517L1.59125 8.00006L4.81386 3.34842H11.2706L14.4172 8.00006L11.2706 12.6517H4.81386ZM11.7758 13.582H4.31537L0.448242 8.00006L4.31537 2.41809H11.7758L15.5517 8.00006L11.7758 13.582Z" fill={fill}/>
  </svg>
);

export default ViewsIcon;

