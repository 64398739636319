import React from 'react';

const Cake = ({
  style = {},
  stroke = 'var(--c-accent)',
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}) => (
  <svg
    className={className}
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0567 7.034H8.70444V5.69312H7.36356V7.034H4.01133C2.8984 7.034 2 7.9324 2 9.04534V15.7498H2.67044H13.3976H14.068V9.04534C14.068 7.9324 13.1696 7.034 12.0567 7.034ZM12.7271 14.4089H3.34089V12.3976C3.94429 12.3909 4.52087 12.1495 4.94996 11.7204L5.68074 11.003L6.39812 11.7204C7.2764 12.5987 8.80501 12.592 9.67659 11.7204L10.4007 11.003L11.118 11.7204C11.5471 12.1495 12.1237 12.3909 12.7271 12.3976V14.4089ZM12.7271 11.3919C12.3852 11.3852 12.0634 11.2578 11.822 11.0097L10.394 9.58169L8.95921 11.0097C8.46308 11.5059 7.59821 11.5059 7.10208 11.0097L5.67404 9.58169L4.23928 11.0097C4.00463 11.2511 3.68282 11.3852 3.34089 11.3919V9.04534C3.34089 8.67659 3.64259 8.37489 4.01133 8.37489H12.0567C12.4254 8.37489 12.7271 8.67659 12.7271 9.04534V11.3919Z"
      fill={stroke} />
    <path
      d="M9.18071 2.99122C9.30809 3.19235 9.37514 3.42701 9.37514 3.68178L8.03425 5.02267L6.69336 3.68178C6.69336 3.42701 6.7604 3.19235 6.88779 2.99122L8.03425 1L9.18071 2.99122Z"
      fill={stroke} />
  </svg>
);

export default Cake;
