import AvatarIcon from '@components/common/SvgIcon/AvatarIcon';
import { cn } from '@utils/bem-config';
import React from 'react';
import { Link } from 'react-router-dom';
import './PostAvatarHeader.scss';

const bem = cn('post-avatar-header');

export const PostAvatarHeader = ({ isLoading, userPhoto, url, mobile }) => {
  const fill = isLoading
    ? {
        fillInner: 'var(--text-level-3)',
        fillOuter: 'var(--base-skeleton-color)',
      }
    : {
        fillInner: 'var(--c-accent)',
        fillOuter: 'var(--c-accent-second)',
      };

  return (
    <Link to={url} className={bem({ mobile })}>
      {userPhoto && <img src={userPhoto} className={bem('photo')} alt="user" />}
      {!userPhoto && <AvatarIcon className={bem('photo')} {...fill} />}
    </Link>
  );
};
