import { useEffect, useRef } from 'react';

const modalWrapID = 'modal';
const modalInnerClassName = 'inner-wrapper';

const createRootElement = (id) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElem) => {
  document.body.insertBefore(rootElem, document.body.nextSibling);
};
export const usePortal = (id = modalWrapID, modalContentClassName = modalInnerClassName) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);
    if (!existingParent) {
      addRootElement(parentElem);
    }
    parentElem.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      if (!parentElem.childElementCount) {
        parentElem.remove();
      }
    };
  }, [id]);
  const getRootElem = () => {
    if (!rootElemRef.current) {
      const contentElem = document.createElement('div');
      contentElem.setAttribute('class', modalContentClassName);
      rootElemRef.current = contentElem;
    }
    return rootElemRef.current;
  };

  return getRootElem();
};
