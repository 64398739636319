import { MainPageNavigation } from '@components/common/MainPageNavigation';
import { MyProfilePreview } from '@src/components/common/MyProfilePreview';
import { cn } from '@utils/bem-config';
import React, { useEffect } from 'react';
import './Header.scss';
import { Search } from '@components/common/Search';
import { useLocation } from 'react-router-dom';

const bem = cn('header');

export const Header = ({ isFocused, setIsFocused, tablet }) => {
  const currentPathname = useLocation().pathname;
  useEffect(() => {
    setIsFocused(false);
  }, [currentPathname]);

  return (
    <header className={bem()}>
      <div className={bem('relative-container')}>
        <div className={bem('sticky-container')}>
          <div className={bem('upper-block', { tablet })}>
            {!tablet && <Search isFocused={isFocused} setIsFocused={setIsFocused} tablet={tablet} />}
            <MyProfilePreview />
          </div>
          <div className={bem('navigation', { tablet })}>
            <MainPageNavigation />
          </div>
        </div>
      </div>
    </header>
  );
};
