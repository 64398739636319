import React, { useEffect } from 'react';
import { CreatorsList } from '@src/components/common/CreatorsList';
import { resetScroll } from '@utils/resetScroll';
import { cn } from '@utils/bem-config';
import audio from './Mneskin.mp3';
import { version } from '../../../../package.json';
import './CreatorsPage.scss';

const bem = cn('creators');

export function CreatorsPage() {
  useEffect(() => {
    resetScroll();
  }, []);
  return (
    <section>
      <h1 className={bem('title')}>Создатели</h1>

      <audio className={bem('audio-control')} src={audio} controls>
        <track kind="captions" />
      </audio>

      <CreatorsList />

      <div className={bem('version')}>
        <p>Scroll v{version}</p>
        <p>Designed by Irlix in Russia</p>
      </div>
    </section>
  );
}
