import { Button } from '@components/common/Button';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { DropdownMenuCategories } from '@components/common/DropdownMenuCategories/DropdownMenuCategories';
import { PhotoNewPost } from '@components/common/PhotoNewPost';
import {
  addPostWithPicture,
  deletePicture,
  editPost,
  editPostStatus,
  editPostWithPicture,
  filesCount,
  selectTagsList,
} from '@components/common/PostsList/postsListSlice';
import { insertUserLinkInText } from '@src/utils/insertUserLinkInText';
import { useHuntedUser } from '@src/hooks/useHuntedUser';
import { ReactQuillEditor } from '@components/common/ReactQuillEditor';
import { SvgIcon } from '@components/common/SvgIcon';
import { STATUS } from '@src/hoc/StatusSwitch';
import { cn } from '@utils/bem-config';
import { maxImageSize, maxImageSizeKB } from '@utils/constants';
import { isObject } from '@utils/utils';
import { isFileTypeValid } from '@utils/validationParameters';
import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './EditablePostContent.scss';
import { DropdownFoundUsers } from '@components/common/DropdownFoundUsers/DropdownFoundUsers';
import { DropdownPublication } from '@components/common/DropdownPublication/DropdownPublication';
import moment from 'moment';

const bem = cn('editable-post');

export const EditablePostContent = ({ data, activateViewMode, editMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pictures, setPictures] = useState(data?.pictures);
  const [files, setFiles] = useState([]);
  const selectTag = data?.tagOutput[0]?.name ? [data?.tagOutput[0]?.name] : [];
  const [tagType, setTagType] = useState(selectTag);
  const [content, setContent] = useState(data?.content);
  const [publicationDate, setPublicationDate] = useState(data?.date);
  const [dropDownPosition, setDropDownPosition] = useState({});
  const { isTypeAt, foundUsersList, huntedUser, resetFoundUsers, getCurrentPosition } = useHuntedUser(content);
  const tags = useSelector(selectTagsList);
  const postEditStatus = useSelector(editPostStatus);
  const countOfFiles = useSelector(filesCount);
  const contentInputRef = useRef(null);
  const fileInput = useRef(null);
  const textContent = contentInputRef?.current?.getEditor()?.getText();
  const currentDate = moment();

  useEffect(() => {
    contentInputRef.current.focus();
  }, []);

  const handleDateChange = (value) => {
    setPublicationDate(value);
  };

  const handleSubmit = () => {
    if (textContent?.trim()) {
      if (pictures.length === 0 && files.length === 0) {
        dispatch(
          editPost({
            id: data?.postId,
            content,
            textContent,
            tags: tagType,
            publicationDate,
          })
        );
      } else if (postEditStatus !== STATUS.LOADING) {
        dispatch(
          editPostWithPicture({
            id: data?.postId,
            content,
            textContent,
            tags: tagType,
            publicationDate,
            pictures,
            files,
            maxImageSize,
            maxImageSizeKB,
          })
        );
      } else if (pictures.length === 0 && files) {
        dispatch(
          addPostWithPicture({
            content,
            textContent,
            tags: tagType,
            publicationDate,
            files,
            maxImageSize,
            maxImageSizeKB,
          })
        );
      }
    }
    activateViewMode();
  };

  const getCurrentSymbolPosition = () => {
    const rect = getCurrentPosition();
    setDropDownPosition({ left: rect.left, top: rect.top });
  };

  const handleCtrlEnter = (e) => {
    if (e.key === '@') getCurrentSymbolPosition();

    if (e.which === 13 && e.ctrlKey) handleSubmit();
  };
  const changeContent = (value) => {
    setContent(value);
  };
  const handleAddFiles = () => {
    fileInput.current.click();
  };

  const deletePicturesHandler = (pictureId) => {
    const arrayFiles = [...pictures];
    pictures.forEach((picture, index) => {
      if (picture.pictureId === pictureId) {
        arrayFiles.splice(index, 1);
        setPictures(arrayFiles);
      }
    });
    dispatch(deletePicture(pictureId));
  };

  const deleteFilesHandler = (name) => {
    const arrayImages = [...files];
    files.forEach((file, index) => {
      if (file.name === name) {
        arrayImages.splice(index, 1);
        setFiles(arrayImages);
      }
    });
  };

  const updateFilesState = (key) => {
    const file = fileInput.current.files[key];
    setFiles((prevState) => [...prevState, file]);
  };

  const savePicture = () => {
    if (pictures.length + fileInput?.current?.files.length <= countOfFiles) {
      if (fileInput?.current?.files) {
        Array.from(fileInput.current.files).forEach((file, keyOfFile) => {
          if (!isFileTypeValid(file)) {
            message.error(`Загружаемое изображение ${file.name} неправильного формата.`);
            return;
          }
          if (isObject(file) && file) {
            updateFilesState(keyOfFile);
          }
        });
      }
    } else {
      message.error(`Максимальное количество изображений в посте: ${countOfFiles}`);
    }
  };
  const goToAdminPage = () => {
    history.push('/administration');
  };

  const modifyPostContent = (e) => {
    const modifyContent = insertUserLinkInText(huntedUser, content, e.target.id, e.target.innerText);
    setContent(modifyContent);
    resetFoundUsers();
  };

  return (
    <form className={bem()}>
      <div className={bem('top-line-container')}>
        <hr className={bem('hr-line', ['top-line-hr'])} />
        <span className={bem('top-line-title')}>Редактирование</span>
      </div>

      <div className={bem('column')}>
        <ReactQuillEditor
          contentInputRef={contentInputRef}
          handleChange={changeContent}
          postContent={content}
          handleCtrlEnter={handleCtrlEnter}
        />
        {isTypeAt && (
          <DropdownFoundUsers
            dropDownPosition={dropDownPosition}
            huntedUser={huntedUser}
            users={foundUsersList}
            modifyPostContent={modifyPostContent}
          />
        )}
        {(files.length || pictures.length) > 0 && (
          <div className={bem('photos-part')}>
            {pictures.map((item) => (
              <PhotoNewPost
                key={item.pictureId}
                photo={item}
                deleteHandler={() => deletePicturesHandler(item.pictureId)}
                editMode={editMode}
                isPicture
              />
            ))}
            {files.map((item) => (
              <PhotoNewPost
                key={item.size}
                photo={item}
                deleteHandler={() => deleteFilesHandler(item.name)}
                editMode={editMode}
              />
            ))}
          </div>
        )}
        <div className={bem('actions-bar')}>
          <div className={bem('theme-button')}>
            <DropdownMenuCategories
              data={tags}
              currentData={tagType}
              setCurrentData={setTagType}
              functionOnClick={goToAdminPage}
            />
            {moment(data?.date) > currentDate && (
              <>
                <div className={bem('vert-line')} />
                <DropdownPublication functionOnSelect={handleDateChange} pickedDate={data?.date} />
              </>
            )}
            <input
              className={bem('input-file')}
              accept="image/*"
              multiple
              id="contained-button-file"
              type="file"
              ref={fileInput}
              onChange={savePicture}
            />
            <div className={bem('vert-line')} />
            <button type="button" className={bem('add-photo-button')} value="Прикрепить фото" onClick={handleAddFiles}>
              <SvgIcon type="addImage" />
            </button>
          </div>
          <div className={bem('btn-container')}>
            <ButtonAnimated onClick={() => activateViewMode()} type="cancel">
              Отмена
            </ButtonAnimated>
            <Button type="button" onClick={handleSubmit}>
              Ок
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
