import { ButtonAnimated } from '@components/common/ButtonAnimated';
import {
  changeAccentColorToCSS,
  changeAccentColorToLocalStorage,
  changeTheme,
  selectTypeOfTheme,
} from '@components/common/SwitchTheme/switchThemeSlice';
import { cn } from '@src/utils/bem-config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SwitchTheme.scss';

const bem = cn('switch-theme');

export const SwitchTheme = () => {
  const dispatch = useDispatch();
  const typeOfTheme = useSelector(selectTypeOfTheme);
  const [haveAccentColor, setHaveAccentColor] = useState(false);

  const changeColor = (e) => {
    const rgbArr = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e.target.value);
    const RGB = {
      r: parseInt(rgbArr[1], 16),
      g: parseInt(rgbArr[2], 16),
      b: parseInt(rgbArr[3], 16),
    };

    const result = {
      color: `rgb(${RGB.r}, ${RGB.g}, ${RGB.b})`,
      colorSecond: `rgb(${Math.round(RGB.r / 1.5)}, ${Math.round(RGB.g / 1.5)}, ${Math.round(RGB.b / 1.5)})`,
      colorTransparent: `rgba(${Math.round(RGB.r / 1.5)} ${Math.round(RGB.g / 1.5)} ${Math.round(RGB.b / 1.5)} / 10%)`,
    };

    changeAccentColorToCSS(result);

    return result;
  };

  const acceptAccentColor = (e) => {
    const { color, colorSecond, colorTransparent } = changeColor(e);
    changeAccentColorToLocalStorage({ color, colorSecond, colorTransparent });
    setHaveAccentColor(true);
  };

  const resetAccentColor = () => {
    changeAccentColorToCSS(null);
    changeAccentColorToLocalStorage(null);
    setHaveAccentColor(false);
  };

  useEffect(() => {
    const storageColor = localStorage.getItem('accentColor');

    if (storageColor) {
      setHaveAccentColor(true);
    }
  }, []);

  return (
    <div className={bem()}>
      <p className={bem('title')}>Тема</p>
      <div className={bem('block')}>
        <div
          className={bem('item', { active: typeOfTheme === 'light' })}
          onClick={() => dispatch(changeTheme({ value: 'light' }))}
        >
          <div className={bem('figure', { type: 'light' })} />
          <span>Светлая</span>
        </div>
        <div
          className={bem('item', { active: typeOfTheme === 'dark' })}
          onClick={() => dispatch(changeTheme({ value: 'dark' }))}
        >
          <div className={bem('figure', { type: 'dark' })} />
          <span>Темная</span>
        </div>
        <div
          className={bem('item', { active: typeOfTheme === 'auto' })}
          onClick={() => dispatch(changeTheme({ value: 'auto' }))}
          title="Автоматическая как на устройстве"
        >
          <div className={bem('figure', { type: 'auto' })} />
          <span>Авто</span>
        </div>
      </div>
      <p className={bem('title')}>Основной цвет</p>
      <div className={bem('block')}>
        <input
          className={bem('color-picker')}
          type="color"
          name="color"
          id="color_id"
          onInput={changeColor}
          onBlur={acceptAccentColor}
        />
      </div>

      <ButtonAnimated onClick={resetAccentColor} type="cancel" disabled={!haveAccentColor}>
        Сбросить основной цвет
      </ButtonAnimated>
    </div>
  );
};
