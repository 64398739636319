import React from 'react';


const ShareIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.35064 2.02087L12.3272 2.03599L13.8023 4.62129L12.301 7.19148L9.32445 7.17636L8.76755 6.20037L7.03519 7.35528L7.58293 8.31522L7.06539 9.2012L8.61917 10.1723L9.09706 9.35421L12.0736 9.36932L13.5488 11.9546L12.0474 14.5248L9.07087 14.5097L7.5957 11.9244L8.11455 11.0362L6.56078 10.0651L6.08158 10.8854L3.10505 10.8703L1.62988 8.28499L3.13124 5.7148L6.10776 5.72992L6.53839 6.48462L8.27076 5.32971L7.84928 4.59106L9.35064 2.02087ZM9.00397 4.59693L9.90688 6.17931L11.7287 6.18856L12.6476 4.61543L11.7447 3.03304L9.9229 3.02379L9.00397 4.59693ZM8.75039 11.9303L9.6533 13.5126L11.4751 13.5219L12.3941 11.9488L11.4912 10.3664L9.66932 10.3571L8.75039 11.9303ZM3.68748 9.87324L2.78457 8.29085L3.7035 6.71772L5.52534 6.72697L6.42825 8.30936L5.50931 9.88249L3.68748 9.87324Z"
      fill={fill}
    />
  </svg>
);

export default ShareIcon;
