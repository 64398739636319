import React from 'react';

const Pinned = ({
  style = {},
  fill = 'var(--text-level-3)',
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16'
}) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.51868 1C9.58438 0.999883 9.64947 1.01272 9.71021 1.03777C9.77095 1.06282 9.82616 1.0996 9.87268 1.146L14.8227 6.096C14.9164 6.18976 14.9691 6.31692 14.9691 6.4495C14.9691 6.58208 14.9164 6.70924 14.8227 6.803C14.3427 7.283 13.7507 7.391 13.3197 7.391C13.1427 7.391 12.9847 7.373 12.8597 7.352L9.72568 10.486C9.80818 10.8184 9.86171 11.1573 9.88568 11.499C9.93168 12.201 9.85368 13.186 9.16568 13.874C9.07191 13.9677 8.94476 14.0204 8.81218 14.0204C8.6796 14.0204 8.55244 13.9677 8.45868 13.874L5.62968 11.046L2.44768 14.228C2.25268 14.423 1.22868 15.13 1.03368 14.935C0.838678 14.74 1.54568 13.715 1.74068 13.521L4.92268 10.339L2.09468 7.51C2.00094 7.41624 1.94828 7.28908 1.94828 7.1565C1.94828 7.02392 2.00094 6.89676 2.09468 6.803C2.78268 6.115 3.76768 6.036 4.46968 6.083C4.81134 6.10694 5.15027 6.16047 5.48268 6.243L8.61668 3.11C8.59052 2.95772 8.57714 2.80351 8.57668 2.649C8.57668 2.219 8.68468 1.627 9.16568 1.146C9.25938 1.05253 9.38633 1.00003 9.51868 1ZM9.64068 3.112V3.11V3.112ZM9.64068 3.11V3.112C9.66956 3.19997 9.67344 3.29423 9.6519 3.38428C9.63036 3.47433 9.58424 3.55662 9.51868 3.622L5.98368 7.156C5.918 7.2214 5.83543 7.26726 5.74519 7.28845C5.65496 7.30964 5.56061 7.30533 5.47268 7.276H5.47068L5.45668 7.272C5.36154 7.2435 5.26549 7.21815 5.16868 7.196C4.91712 7.13732 4.66133 7.09853 4.40368 7.08C3.98168 7.052 3.56768 7.088 3.22868 7.23L8.73868 12.739C8.87968 12.399 8.91568 11.986 8.88768 11.564C8.8622 11.2067 8.79782 10.8533 8.69568 10.51L8.69168 10.497V10.496C8.66215 10.4079 8.65774 10.3134 8.67893 10.223C8.70013 10.1325 8.74609 10.0498 8.81168 9.984L12.3477 6.449C12.4158 6.38049 12.5022 6.33318 12.5967 6.31277C12.6911 6.29236 12.7894 6.29974 12.8797 6.334L12.9757 6.356C13.0627 6.373 13.1837 6.39 13.3197 6.39C13.4337 6.39 13.5497 6.379 13.6627 6.35L9.61768 2.306C9.58868 2.419 9.57768 2.536 9.57768 2.649C9.57817 2.80438 9.59901 2.95903 9.63968 3.109L9.64068 3.11Z"
      fill={fill} />
  </svg>
);

export default Pinned;
