import '@src/styles/antd.css';
import '@src/styles/normalize.css';
import '@src/styles/base.scss';
import '@src/styles/css-variables.scss';

import Keycloak from 'keycloak-js';
import moment from 'moment';
import 'moment/locale/ru';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';

moment.locale('ru');

console.log('Start app');

const initOptions = {
  url: 'https://auth.irlix.com/auth',
  realm: 'freeipa-realm',
  'public-client': true,
  clientId: 'irlix-social-front',
  onLoad: 'login-required',
};

const keycloak = Keycloak(initOptions);

console.log('keycloak start');
keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      localStorage.clear();
      window.location.reload();
    } else {
      console.log('Authenticated, keycloak = ', keycloak);
      localStorage.setItem('auth-token', keycloak?.token);
      const roles = keycloak?.tokenParsed?.resource_access['irlix-social-front']?.roles;

      ReactDOM.render(
        <React.StrictMode>
          <Provider store={store}>
            <App token={keycloak?.token} roles={roles} />
          </Provider>
        </React.StrictMode>,
        document.getElementById('root')
      );
    }
  })

  .catch((e) => {
    console.log('Authenticated Failed', e);
    console.log('keycloak error', keycloak);
  });

// отлавливаем момент истечения основного токена
keycloak.onTokenExpired = () => {
  console.log(`expired ${new Date()}`);
  keycloak
    // пробуем обновить основной токен через рефреш-токен
    .updateToken(5)
    .then((refreshed) => {
      if (refreshed) {
        // при успешном обновлении токена
        console.log(`refreshed ${new Date()}`);
      } else {
        // токен ещё не истек
        console.log(`not refreshed ${new Date()}`);
      }
    })
    // при истечении рефреш-токена разлогиниваем пользователя
    .catch(() => {
      console.error(`Failed to refresh token ${new Date()}`);
      keycloak.logout();
    });
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { keycloak };
