import React from 'react';

const AppsIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2878 3.77097L14.5116 6.18275L13.5348 9.31446L10.3343 10.0344L8.11051 7.62262L9.08729 4.49091L12.2878 3.77097ZM9.94995 5.42651L9.35209 7.34333L10.7132 8.81951L12.6721 8.37886L13.27 6.46203L11.9089 4.98586L9.94995 5.42651Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.14476 8.17942L9.36851 10.5912L8.39173 13.7229L5.19119 14.4428L2.96744 12.0311L3.94423 8.89935L7.14476 8.17942ZM4.80689 9.83496L4.20903 11.7518L5.57012 13.228L7.52906 12.7873L8.12692 10.8705L6.76584 9.3943L4.80689 9.83496Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.67503 1.56675L7.89878 3.97852L6.922 7.11023L3.72146 7.83017L1.49771 5.41839L2.4745 2.28668L5.67503 1.56675ZM3.33716 3.22228L2.7393 5.13911L4.10039 6.61528L6.05934 6.17463L6.6572 4.25781L5.29611 2.78163L3.33716 3.22228Z"
      fill={fill}
    />
  </svg>
);
export default AppsIcon;
