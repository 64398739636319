import React from 'react';


const SubmitIcon = ({ style = {}, fill = 'var(--text-level-3)', stroke = '', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M2.56861 2.0415C2.50284 2.0086 2.42916 1.99483 2.35595 2.00174C2.28275 2.00865 2.21295 2.03596 2.1545 2.08058C2.09605 2.1252 2.05129 2.18532 2.02532 2.25411C1.99935 2.32291 1.99321 2.39761 2.00759 2.46972L3.10998 6.28054C3.13053 6.35156 3.17071 6.41533 3.22589 6.46454C3.28107 6.51374 3.34902 6.54637 3.42191 6.55869L7.89275 7.30749C8.10332 7.34914 8.10332 7.65086 7.89275 7.6925L3.42191 8.44131C3.34902 8.45363 3.28107 8.48626 3.22589 8.53546C3.17071 8.58467 3.13053 8.64844 3.10998 8.71946L2.00759 12.5303C1.99321 12.6024 1.99935 12.6771 2.02532 12.7459C2.05129 12.8147 2.09605 12.8748 2.1545 12.9194C2.21295 12.964 2.28275 12.9914 2.35595 12.9983C2.42916 13.0052 2.50284 12.9914 2.56861 12.9585L12.7832 7.85122C12.8483 7.81856 12.9031 7.76841 12.9414 7.70638C12.9797 7.64436 13 7.5729 13 7.5C13 7.4271 12.9797 7.35564 12.9414 7.29362C12.9031 7.23159 12.8483 7.18144 12.7832 7.14878L2.56861 2.0415Z"
      fill={fill}
      stroke={stroke}
    />
  </svg>
);

export default SubmitIcon;
