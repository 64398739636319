import React from 'react';
import preloader from '@assets/images/preloader-img.svg';
import { cn } from '@utils/bem-config';
import './Preloader.scss';

const bem = cn('preloader');

export const Preloader = ({ mode }) => (
  <div className={bem({ mode })}>
    <div className={bem('text')}>Загрузка</div>
    <img src={preloader} alt="Loading Img" />
  </div>
);
