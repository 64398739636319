import React from 'react';
import { cn } from '@src/utils/bem-config';
import { Link } from 'react-router-dom';
import './UserItem.scss';
import { TernaryForSkeleton } from '@src/components/common/Skeletons/Skeleton';
import AvatarIcon from '@components/common/SvgIcon/AvatarIcon';
import HBIcon from '@assets/images/hb.svg';

const bem = cn('user-item');

// TODO - вынести в прелоадер фотки с размером в пропсах и тд
function UserPhoto({ photo, pageUrl, photoSize, isLoading, isBirthday = false }) {
  const fill = isLoading
    ? {
        fillInner: 'var(--text-level-3)',
        fillOuter: 'var(--base-skeleton-color)',
      }
    : isBirthday
    ? {
        fillInner: '#FF8B72',
        fillOuter: '#FFC672',
      }
    : {
        fillInner: 'var(--c-accent)',
        fillOuter: 'var(--c-accent-second)',
      };

  return (
    <Link className={bem('photo-link', { isBirthday })} to={pageUrl}>
      {photo && <img src={photo} className={bem('photo', { size: photoSize })} alt="user" />}
      {!photo && <AvatarIcon className={bem('photo', { size: photoSize })} {...fill} />}
      {isBirthday && <img src={HBIcon} className={bem('hb-icon')} alt="happy birthday" />}
    </Link>
  );
}

/**
 *
 * @param {{
 * userLdapId: string,
 * photo?: string,
 * photoPosition?: 'left' | 'right',
 * photoSize?: 's' | 'm' | 'l',
 * fullName: string,
 * secondLine?: string | boolean,
 * isLoading?: boolean
 * }} props
 */
export const UserItem = ({
  userLdapId,
  photo,
  photoPosition = 'left',
  photoSize = 's',
  lastName,
  firstName,
  secondLine,
  isLoading = false,
  isBirthday = false,
}) => {
  const pageUrl = `/users/${userLdapId}`;

  return (
    <div className={bem({ position: photoPosition, gap: photoSize })}>
      {photoPosition === 'left' && (
        <UserPhoto photo={photo} pageUrl={pageUrl} photoSize={photoSize} isLoading={isLoading} />
      )}
      <div className={bem('user-info', { align: photoPosition })}>
        <div>
          <TernaryForSkeleton isLoading={isLoading} width="10rem">
            <Link className={bem('name-link')} to={pageUrl}>
              {`${lastName} ${firstName}`}
            </Link>
          </TernaryForSkeleton>
        </div>
        {secondLine && (
          <div className={bem('user-info-line', { float: photoPosition })}>
            <TernaryForSkeleton isLoading={isLoading} width="7rem">
              <div className={bem('second-line', { isBirthday })}>{isBirthday ? 'сегодня' : secondLine}</div>
            </TernaryForSkeleton>
          </div>
        )}
      </div>
      {photoPosition === 'right' && (
        <UserPhoto
          photo={photo}
          pageUrl={pageUrl}
          photoSize={photoSize}
          isLoading={isLoading}
          isBirthday={isBirthday}
        />
      )}
    </div>
  );
};
