import React from 'react';
import AddIcon from './AddIcon';
import AddImageIcon from './AddImageIcon';
import AppsIcon from './AppsIcon';
import ArrowIcon from './ArrowIcon';
import AvatarIcon from './AvatarIcon';
import BurgerIcon from './BurgerIcon';
import Cake from "./Cake";
import CloseIcon from './CloseIcon';
import CoinDollarIcon from './CoinDollarIcon';
import CoinIcon from './CoinIcon';
import CommentIcon from './CommentIcon';
import CopyIcon from './CopyIcon';
import DateIcon from './DateIcon';
import DeleteIcon from './DeleteIcon';
import DotsIcon from './DotsIcon';
import ExclamationPoint from "./ExclamationPoint";
import ExitIcon from './ExitIcon';
import GeolocationIcon from './GeolocationIcon';
import HeaderLogo from "./HeaderLogo";
import HeaderLogoScroll from "./HeaderLogoScroll";
import IconClock from "./IconClock";
import InfoIcon from './InfoIcon';
import LikeIcon from './LikeIcon';
import LocationIcon from './LocationIcon';
import MailIcon from './MailIcon';
import MenuIcon from './MenuIcon';
import OwnerIcon from './OwnerIcon';
import PhotoCameraIcon from './PhotoCameraIcon';
import Pinned from "./Pinned";
import PollIcon from './PollIcon';
import SearchIcon from './SearchIcon';
import SendIcon from './SendIcon';
import SettingsIcon from './SettingsIcon';
import ShareIcon from './ShareIcon';
import SubmitIcon from './SubmitIcon';
import UserIcon from './UserIcon';
import UsersIcon from './UsersIcon';
import ViewsIcon from './ViewsIcon';
import WelcomeIcon from './WelcomeIcon';
import WikiFillIcon from './WikiFillIcon';
import WikiIcon from './WikiIcon';
import YoutubeIcon from './YoutubeIcon';

export const SvgIcon = (props) => {
  switch (props.type) {
    case 'menuItem':
      return <MenuIcon {...props} />;
    case 'settings':
      return <SettingsIcon {...props} />;
    case 'comment':
      return <CommentIcon {...props} />;
    case 'like':
      return <LikeIcon {...props} />;
    case 'share':
      return <ShareIcon {...props} />;
    case 'submit':
      return <SubmitIcon {...props} />;
    case 'views':
      return <ViewsIcon {...props} />;
    case 'avatar':
      return <AvatarIcon {...props} />;
    case 'arrow':
      return <ArrowIcon {...props} />;
    case 'search':
      return <SearchIcon {...props} />;
    case 'send':
      return <SendIcon {...props} />;
    case 'wiki':
      return <WikiIcon {...props} />;
    case 'wiki-fill':
      return <WikiFillIcon {...props} />;
    case 'mail':
      return <MailIcon {...props} />;
    case 'apps':
      return <AppsIcon {...props} />;
    case 'exit':
      return <ExitIcon {...props} />;
    case 'addImage':
      return <AddImageIcon {...props} />;
    case 'delete':
      return <DeleteIcon {...props} />;
    case 'close':
      return <CloseIcon {...props} />;
    case 'coin':
      return <CoinIcon {...props} />;
    case 'coin-dollar':
      return <CoinDollarIcon {...props} />;
    case 'info':
      return <InfoIcon {...props} />;
    case 'add':
      return <AddIcon {...props} />;
    case 'copy':
      return <CopyIcon {...props} />;
    case 'owner':
      return <OwnerIcon {...props} />;
    case 'user':
      return <UserIcon {...props} />;
    case 'users':
      return <UsersIcon {...props} />;
    case 'burger':
      return <BurgerIcon {...props} />;
    case 'photo-camera':
      return <PhotoCameraIcon {...props} />;
    case 'welcome':
      return <WelcomeIcon {...props} />;
    case 'geolocation':
      return <GeolocationIcon {...props} />;
    case 'dots':
      return <DotsIcon {...props} />;
    case 'cake':
      return <Cake {...props} />;
    case 'header-logo':
      return <HeaderLogo {...props} />;
    case 'header-logo-scroll':
      return <HeaderLogoScroll {...props} />;
    case 'exclamation-point':
      return <ExclamationPoint {...props} />;
    case 'pinned':
      return <Pinned {...props} />;
    case 'clock':
      return <IconClock {...props} />;
    case 'date':
      return <DateIcon {...props} />;
    case 'location':
      return <LocationIcon {...props} />;
    case 'youtube':
      return <YoutubeIcon {...props} />;
    case 'poll':
      return <PollIcon {...props} />;
    default:
      return null;
  }
};
