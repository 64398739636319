import { Error } from '@components/common/Error';
import { UserItem } from '@components/common/UserItem';
import { StatusSwitch } from '@src/hoc/StatusSwitch';
import { cn } from '@utils/bem-config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './BirthdaysList.scss';
import {
  getBirthdays,
  selectBirthdays,
  selectStatus,
  selectRequestFirstDayBirthdays,
  selectRequestLastDayBirthdays,
} from './birthdaysListSlice';

const bem = cn('birthdays-list');
/**
 * Максимум пользователей, которых показываем по умолчанию в списке именинников.
 * Остальных скрываем под кнопкой "Показать ещё".
 */
const birthdayUserCount = 5;

const ComponentOnDone = ({ birthdays }) => {
  const [collapsed, setCollapsed] = useState(true);
  const showAllHandler = () => {
    setCollapsed(!collapsed);
  };

  const birthdaysSort = [...birthdays].sort(
    (a, b) => moment(b.birthDate).format('DD MMMM') - moment(a.birthDate).format('DD MMMM')
  );
  const birthdayListForRender = collapsed ? birthdaysSort.slice(0, birthdayUserCount) : birthdaysSort;

  return (
    <>
      {birthdays.length === 0 && (
        <div className={bem('length-zero-text')}>В ближайшее время Дней рождения не предвидится</div>
      )}

      {birthdays.length > 0 && (
        <>
          <div className={bem('container')}>
            {birthdayListForRender.map((item) => (
              <div key={item.ldapId} className={bem('birthday-container')}>
                <UserItem
                  isBirthday={moment().format('DD MMMM') === moment(item.birthDate).format('DD MMMM')}
                  userLdapId={item.ldapId}
                  photo={item.photos && item.photos.length > 0 && item.photos[item.photos.length - 1].url}
                  photoPosition="right"
                  photoSize="s"
                  lastName={item?.lastName}
                  firstName={item?.firstName}
                  secondLine={moment(item.birthDate).format('DD MMMM')}
                />
              </div>
            ))}
          </div>
          {birthdays.length > birthdayUserCount && (
            <button type="button" className={bem('show-all-button')} onClick={showAllHandler}>
              {collapsed ? 'Показать всех' : 'Скрыть'}
            </button>
          )}
        </>
      )}
    </>
  );
};

const ComponentOnLoading = () =>
  [0, 1, 2, 3, 4].map((v) => (
    <div className={bem('birthday-container')} key={v}>
      <UserItem photoPosition="right" secondLine isLoading />
    </div>
  ));

export const BirthdaysList = () => {
  const dispatch = useDispatch();

  const birthdays = useSelector(selectBirthdays);
  const status = useSelector(selectStatus);
  const requestFirstDayBirthdays = useSelector(selectRequestFirstDayBirthdays);
  const requestLastDayBirthdays = useSelector(selectRequestLastDayBirthdays);

  useEffect(() => {
    const firstDay = moment().format('YYYY-MM-DD');
    const lastDay = moment().add(30, 'days').format('YYYY-MM-DD');

    if (firstDay !== requestFirstDayBirthdays || lastDay !== requestLastDayBirthdays) {
      dispatch(getBirthdays({ firstDay, lastDay }));
    }
  }, []);

  return (
    <div className={bem()}>
      <h3 className={bem('heading')}>Дни рождения</h3>
      <StatusSwitch
        status={status}
        ComponentOnDone={<ComponentOnDone birthdays={birthdays} />}
        ComponentOnLoading={<ComponentOnLoading />}
        ComponentOnError={<Error action={getBirthdays} />}
      />
    </div>
  );
};
