import { SvgIcon } from '@components/common/SvgIcon';
import { cn } from '@utils/bem-config';
import React from 'react';
import './VoteButon.scss';

const bem = cn('vote');

export const VoteButton = ({ typeIcon = 'poll', title = false, onButtonClick = () => null }) => (
  <div className={bem()}>
    <button title={title} onClick={onButtonClick} className={bem('button')}>
      <SvgIcon width={24} height={24} type={typeIcon} fill="currentcolor" />
    </button>
  </div>
);
