import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cn } from '@utils/bem-config';
import { Tabs } from '@components/common/Tabs';
import { GeographyOffices } from '@components/pages/Geography/GeographyOffices';
import { GeographyEmployees } from '@components/pages/Geography/GeographyEmployees';
import './Geography.scss';

const bem = cn('geography');

export const Geography = () => {
  function useQuery(location) {
    return new URLSearchParams(location.search ? location.search : '?tab=employees');
  }

  const history = useHistory();
  const { location } = history;
  const queryParams = useQuery(location);

  useEffect(() => {
    history.push(`?${queryParams.toString()}`);
  }, []);

  const tabComponents = [
    {
      title: 'Сотрудники',
      name: 'employees',
      component: <GeographyEmployees />,
      url: `${location.pathname}?tab=employees`,
    },
    {
      title: 'Офисы',
      name: 'offices',
      component: <GeographyOffices />,
      url: `${location.pathname}?tab=offices`,
    },
  ];

  const getTabIndex = (name) => {
    const foundIndex = tabComponents.findIndex((tab) => tab.name === name);
    const defaultIndex = 0;
    const activeTabIndex = foundIndex === -1 ? defaultIndex : foundIndex;
    return activeTabIndex;
  };

  const defaultTab = getTabIndex(queryParams.get('tab'));

  return (
    <div className={bem()}>
      <h1 className={bem('title')}>География</h1>
      <Tabs defaultTab={defaultTab} tabList={tabComponents} />
    </div>
  );
};
