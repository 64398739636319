import React from 'react';


const PhotoCameraIcon = ({ style = {}, width = '16', fill = 'var(--text-level-3)', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} height={height} style={style} viewBox={viewBox} className={className}  fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9574 5.56508H10.7347L10.0596 4.58691C9.8049 4.22009 9.35911 4 8.90059 4H6.0985C5.63997 4 5.19419 4.22009 4.93945 4.58691L4.2644 5.56508H3.04167C2.26473 5.56508 1.64062 6.16421 1.64062 6.91007V11.6542C1.64062 12.4001 2.26473 12.9992 3.04167 12.9992H11.9574C12.7344 12.9992 13.3585 12.4001 13.3585 11.6542V6.91007C13.3585 6.16421 12.7344 5.56508 11.9574 5.56508ZM7.49954 11.8988C5.7546 11.8988 4.34082 10.5416 4.34082 8.86643C4.34082 7.1913 5.7546 5.84631 7.49954 5.84631C9.24448 5.84631 10.6583 7.20353 10.6583 8.87865C10.6583 10.5416 9.24448 11.8988 7.49954 11.8988ZM11.7791 7.4603C11.7664 7.4603 11.7536 7.4603 11.7282 7.4603H11.2187C10.9894 7.44807 10.8111 7.26466 10.8238 7.04457C10.8366 6.83671 11.0022 6.67776 11.2187 6.66553H11.7282C11.9574 6.6533 12.1485 6.82448 12.1612 7.04457C12.1739 7.26466 12.0084 7.44807 11.7791 7.4603Z" fill={fill}/>
    <path d="M7.49987 7.18945C6.53187 7.18945 5.74219 7.94754 5.74219 8.87681C5.74219 9.80607 6.53187 10.5519 7.49987 10.5519C8.46786 10.5519 9.25755 9.79385 9.25755 8.86458C9.25755 7.93531 8.46786 7.18945 7.49987 7.18945Z" fill={fill}/>
  </svg>
);

export default PhotoCameraIcon;
