import React from 'react';


const UserIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9999 4.56624V11.4337L7.99996 14.8622L2 11.4337V4.56624L7.99996 1.1377L13.9999 4.56624ZM12.8386 5.24016L7.99996 2.4752L3.16128 5.24016L3.16128 10.7598L4.01623 11.2483L6.27579 9.74192H7.17392L5.40797 8.72235L5.40797 5.72932L8.00001 4.2328L10.5921 5.72931V8.72235L8.82611 9.74192H9.72415L11.9837 11.2483L12.8386 10.7598V5.24016ZM10.8564 11.8924L9.37254 10.9032H6.6274L5.14351 11.8925L7.99996 13.5247L10.8564 11.8924ZM9.43077 8.05188L9.43077 6.39978L8.00001 5.57373L6.56925 6.39978L6.56925 8.05188L8.00001 8.87793L9.43077 8.05188Z"
      fill={fill}
    />
  </svg>
);
export default UserIcon;
