import {
  getThemeFromLocalStorage,
  getAccentColorFromLocalStorage,
  selectTypeOfTheme,
  switchThemeEvent,
} from '@components/common/SwitchTheme/switchThemeSlice';
import { MainPage } from '@components/pages/MainPage';
import '@components/pages/MainPage/MainPage.scss';
import { NoMatchPage } from '@components/pages/NoMatchPage';
import { UIPage } from '@components/pages/UIPage';
import { addRoles, addToken, addUserId, selectToken } from '@src/appSlice';
import { getCurrentUser } from '@src/components/common/MyProfilePreview/myProfilePreviewSlice';
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { store } from './app/store';

function App({ token, roles }) {
  const dispatch = useDispatch();

  const reduxToken = useSelector(selectToken);
  const typeOfTheme = useSelector(selectTypeOfTheme);

  useEffect(() => {
    dispatch(getThemeFromLocalStorage()); // получаем тему при первом запуске
    dispatch(getAccentColorFromLocalStorage()); // получаем цвет при первом запуске
    dispatch(addToken(token));
    dispatch(addRoles(roles));
    dispatch(getCurrentUser()).then((data) => {
      dispatch(addUserId(data?.payload?.ldapId));
    });
  }, []);

  useEffect(() => {
    const matchMedia = window?.matchMedia('(prefers-color-scheme: dark)');

    if (typeOfTheme === 'auto') {
      matchMedia.addEventListener('change', switchThemeEvent);
    }

    return () => {
      matchMedia.removeEventListener('change', switchThemeEvent);
    };
  }, [typeOfTheme]);

  return (
    <Provider store={store}>
      {reduxToken !== null && (
        <BrowserRouter>
          <Switch>
            <Route exact path="/404">
              <NoMatchPage title="Irlix" subtitle="Когда не нашёл нужного" productName="404" />
            </Route>

            <Route exact path="/ui">
              <UIPage />
            </Route>

            <Route path="/">
              <MainPage />
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </Provider>
  );
}

export default App;
