import React from 'react';

const MenuIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg className={className} width={width} style={style} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4.56619L14 11.4338L7.99992 14.8624L1.99984 11.4338L1.99984 4.56619L7.99992 1.13757L14 4.56619ZM12.8387 5.24012L7.99992 2.47511L3.16114 5.24012L3.16114 10.7598L7.99992 13.5248L12.8387 10.7598L12.8387 5.24012Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49257 5.37566L5.24091 7.69827L4.56592 6.75328L7.81757 4.43066L8.49257 5.37566ZM10.0519 9.93549V6.06447H11.2132V9.93549H10.0519ZM5.58751 9.04943L8.83917 10.9849L8.24518 11.9828L4.99352 10.0473L5.58751 9.04943Z"
      fill={fill}
    />
  </svg>
);

export default MenuIcon;
