import { STATUS } from '@src/hoc/StatusSwitch';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, request } from '@src/app/api';
import { getAvatarList } from '@src/components/pages/UserPage/getAvatarListAction';
import {
  deleteUserPicture,
  updateUserAvatar,
  updateUserBackground,
} from '@components/common/MyProfilePreview/myProfilePreviewSlice';

export { getAvatarList };

const initialState = {
  avatarList: [],
  avatarListStatus: [],
  usersStatus: STATUS.NO_STATUS,
  /**
   * Данные пользователей, полученных с помощью запроса getUserByLdap.
   * Получаем и собираем данные в массиве, чтобы не запрашивать повторно.
   */
  usersData: [],
  /**
   * Статус загрузки данных для детальной страницы пользователя. (Для профиля).
   */
  userDataStatus: STATUS.NO_STATUS,
};

export const getUserByLdap = createAsyncThunk('getUserByLdap', async ({ userLdapId }) => {
  const response = await request.get(api.userByLdap(userLdapId));
  return response.data;
});

export const userPageSlice = createSlice({
  name: 'userPage',
  initialState,
  extraReducers: {
    [getAvatarList.fulfilled]: (state, action) => {
      state.avatarList = action.payload.data;
      state.avatarListStatus = STATUS.DONE;
    },
    [getAvatarList.pending]: (state) => {
      state.avatarListStatus = STATUS.LOADING;
    },
    [getAvatarList.rejected]: (state) => {
      state.avatarListStatus = STATUS.ERROR;
    },

    [getUserByLdap.fulfilled]: (state, action) => {
      state.usersData = [...state.usersData, action.payload];
      state.userDataStatus = STATUS.DONE;
    },
    [getUserByLdap.pending]: (state) => {
      state.userDataStatus = STATUS.LOADING;
    },
    [getUserByLdap.rejected]: (state) => {
      state.userDataStatus = STATUS.ERROR;
    },

    [updateUserAvatar.fulfilled]: (state, action) => {
      const usersData = state.usersData.find((userItem) => userItem.ldapId === action.payload.userLdapId);
      usersData ? (usersData.photos.avatar = action.payload.data) : '';
    },

    [updateUserBackground.fulfilled]: (state, action) => {
      const usersData = state.usersData.find((userItem) => userItem.ldapId === action.payload.userLdapId);
      usersData ? (usersData.photos.background = action.payload.data) : '';
    },

    [deleteUserPicture.fulfilled]: (state, action) => {
      const usersData = state.usersData.find((userItem) => userItem.ldapId === action.payload.userLdapId);
      usersData ? (usersData.photos = action.payload.data) : '';
    },
  },
});

export const selectUserAvatarList = (state) => state.usersPage.avatarList;
export const selectUserAvatarListStatus = (state) => state.usersPage.avatarListStatus;
export const selectUserDataStatus = (state) => state.usersPage.userDataStatus;
export const selectUserData = (state) => state.usersPage.usersData;

export default userPageSlice.reducer;
