import React from 'react';
import { Button } from '@components/common/Button';
import { email } from '@utils/constants';

export const AddWish = ({ subject, body }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  const href = `mailto:${email}${params}`;
  return (
    <Button type="a" target="_blank" rel="noreferrer" href={href}>
      Написать письмо
    </Button>
  );
};
