import React from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@src/utils/bem-config';
import './Error.scss';

const bem = cn('error');

export const Error = ({ action, actionParams }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(action(actionParams));
  };

  return (
    <div className={bem()}>
      <div className={bem('title')}>Что-то пошло не так...</div>
      <button type="button" className={bem('button')} onClick={handleClick}>
        Обновить
      </button>
    </div>
  );
};
