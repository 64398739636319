import React from 'react';


const MailIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.60059 3.43347H1.10059V3.93347V12.7331V13.2331H1.60059H14.4H14.9V12.7331V3.93347V3.43347H14.4H1.60059ZM2.10059 5.16797V11.1357L5.5939 8.22462L2.10059 5.16797ZM2.65815 12.2331H13.3431L9.49284 9.02454L8.00047 10.3304L6.50823 9.02466L2.65815 12.2331ZM13.9 11.1352V5.16829L10.4072 8.2245L13.9 11.1352ZM12.9176 4.43347H3.08338L8.00047 8.73592L12.9176 4.43347Z"
      fill={fill}
    />
  </svg>
);
export default MailIcon;
