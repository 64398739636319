import { cn } from '@utils/bem-config';
import React from 'react';
import './MainPosts.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Feed } from '@components/pages/Feed';
import { PostPage } from '@components/pages/PostPage';
import { UsersListPage } from '@components/pages/UsersListPage';
import { SearchPage } from '@components/pages/SearchPage';
import { Settings } from '@components/pages/Settings';
import { AdminSettings } from '@components/pages/AdminSettings';
import { Geography } from '@components/pages/Geography';
import { UserPage } from '@components/pages/UserPage';
import { CreatorsPage } from '@components/pages/CreatorsPage';
import { AppsPage } from '@components/pages/AppsPage';
import { AppPage } from '@components/pages/AppPage';

const bem = cn('main');

const MainPostsComponent = ({ isFocused, isAdmin, tablet, mobile }) => (
  <main className={bem({ tablet, mobile })}>
    <Switch>
      <Route exact path="/">
        <Redirect to="/feed" />
      </Route>

      <Route exact path="/feed">
        <Feed mobile={mobile} />
      </Route>

      <Route path="/feed/:id">
        <PostPage />
      </Route>

      <Route exact path="/users">
        <UsersListPage isFocused={isFocused} />
      </Route>

      <Route exact path="/search">
        <SearchPage isFocused={isFocused} />
      </Route>

      <Route exact path="/settings">
        <Settings />
      </Route>

      <Route exact path="/apps">
        <AppsPage />
      </Route>

      <Route exact path="/apps/snake">
        <AppPage title="IT Snake" route="/apps?tab=games" />
      </Route>

      <Route exact path="/administration">
        {isAdmin ? <AdminSettings /> : <Redirect to="/feed" />}
      </Route>

      <Route exact path="/geolocation">
        <Geography />
      </Route>

      <Route path="/users/:id">
        <UserPage isAdmin={isAdmin} />
      </Route>

      <Route path="/creators">
        <CreatorsPage />
      </Route>

      <Route path="*">
        <Redirect to="/404" />
      </Route>
    </Switch>
  </main>
);
export const MainPosts = React.memo(MainPostsComponent);
