import React from "react";


export const GeolocationIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8425 3.69655L14.0653 7.19189L12.6497 11.7306L8.01125 12.774L4.78842 9.27867L6.20405 4.73994L10.8425 3.69655ZM7.11674 5.7298L6.10201 8.98318L8.41215 11.4887L11.737 10.7408L12.7518 7.48737L10.4416 4.9819L7.11674 5.7298Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.07469 2.27202L9.13807 4.40107L8.30075 5.21256L6.69252 3.55315L3.35828 4.30316L2.34355 7.55654L4.65369 10.062L6.31613 9.68807L6.57203 10.8257L4.25279 11.3474L1.02996 7.85203L2.44559 3.3133L7.07469 2.27202Z"
      fill={fill}
    />
  </svg>
);
export default GeolocationIcon;
