import { cn } from '@utils/bem-config';
import React from 'react';
import './ParametersButton.scss';
import { SvgIcon } from '@components/common/SvgIcon';

const bem = cn('parameters-btn');

export const ParametersButton = ({ toggleParameters, isParametersOpen }) => (
  <button type="button" onClick={toggleParameters} className={bem({ active: isParametersOpen })}>
    <SvgIcon type="settings" fill="var(--c-accent)" />
  </button>
);
