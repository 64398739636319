import React, { useEffect } from 'react';
import { Post } from '@components/common/Post';
import { Error } from '@components/common/Error';
import {
  getDefferedPosts,
  selectDefferedPosts,
  selectDefferedPostsStatus,
} from '@components/common/PostsList/postsListSlice';
import { postToLoadingCount } from '@utils/constants';
import { StatusSwitch } from '@src/hoc/StatusSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '@utils/bem-config';
import { DefferedPostsListOnDone } from '@components/common/DefferedPostsListOnDone';

const bem = cn('posts-list');

export const PostsListDelayed = () => {
  const dispatch = useDispatch();
  const defferedPosts = useSelector(selectDefferedPosts);
  const status = useSelector(selectDefferedPostsStatus);

  useEffect(() => {
    dispatch(getDefferedPosts());
  }, []);

  return (
    <div className={bem()}>
      <StatusSwitch
        status={status}
        ComponentOnDone={defferedPosts?.length > 0 ? <DefferedPostsListOnDone posts={defferedPosts} /> : 'Нет постов'}
        ComponentOnLoading={
          <>
            <Post isLoading />
            <Post isLoading />
          </>
        }
        ComponentOnError={<Error action={getDefferedPosts} actionParams={{ size: postToLoadingCount }} />}
      />
    </div>
  );
};
