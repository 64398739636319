import { Tabs } from '@components/common/Tabs';
import { GamesPage } from '@components/pages/GamesPage';
import { ServicesPage } from '@components/pages/ServicesPage';
import { cn } from '@utils/bem-config';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './AppsPage.scss';

const bem = cn('apps-page');

export const AppsPage = () => {
  function useQuery(location) {
    return new URLSearchParams(location.search ? location.search : '?tab=about');
  }

  const history = useHistory();
  const { location } = history;
  const queryParams = useQuery(location);
  useEffect(() => {
    history.push(`?${queryParams.toString()}`);
  }, []);
  const tabComponents = [
    {
      title: 'Сервисы',
      name: 'services',
      component: <ServicesPage />,
      url: `${location.pathname}?tab=services`,
    },
    {
      title: 'Игры',
      name: 'games',
      component: <GamesPage />,
      url: `${location.pathname}?tab=games`,
    },
  ];
  const getTabIndex = (name) => {
    const foundIndex = tabComponents.findIndex((tab) => tab.name === name);
    const defaultIndex = 0;
    const activeTabIndex = foundIndex === -1 ? defaultIndex : foundIndex;
    return activeTabIndex;
  };
  const defaultTab = getTabIndex(queryParams.get('tab'));

  return (
    <div className={bem()}>
      <h1 className={bem('title')}>Приложения</h1>
      <Tabs defaultTab={defaultTab} tabList={tabComponents} />
    </div>
  );
};
