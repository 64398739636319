import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  typeOfTheme: null,
  appTheme: null,
};

const changeThemeHtml = (themeValue) => {
  document.documentElement.dataset.theme = themeValue;
};

export const switchThemeEvent = (e) => {
  const systemTheme = e.matches ? 'dark' : 'light';
  changeThemeHtml(systemTheme);
};

const createAppThemeFromTypeOfTheme = (value) => {
  const systemTheme = window?.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

  const changeTypeOfTheme = (themeType) => {
    localStorage.setItem('themeType', themeType);
  };

  switch (value) {
    case 'light':
      changeThemeHtml('light');
      changeTypeOfTheme('light');
      return { typeOfTheme: 'light', appTheme: 'light' };

    case 'dark':
      changeThemeHtml('dark');
      changeTypeOfTheme('dark');
      return { typeOfTheme: 'dark', appTheme: 'dark' };

    case 'auto':
      changeTypeOfTheme('auto');
      changeThemeHtml(systemTheme);
      return { typeOfTheme: 'auto', appTheme: systemTheme };

    default:
      changeTypeOfTheme('auto');
      changeThemeHtml(systemTheme);
      return { typeOfTheme: 'auto', appTheme: systemTheme };
  }
};

export const changeTheme = createAsyncThunk('changeTheme', async ({ value }) => createAppThemeFromTypeOfTheme(value));

export const changeAccentColorToCSS = (colors) => {
  if (colors) {
    document.documentElement.style.setProperty('--c-accent', colors.color);
    document.documentElement.style.setProperty('--c-accent-second', colors.colorSecond);
    document.documentElement.style.setProperty('--c-accent-transparent', colors.colorTransparent);
  } else if (colors === null) {
    document.documentElement.style.removeProperty('--c-accent');
    document.documentElement.style.removeProperty('--c-accent-second');
    document.documentElement.style.removeProperty('--c-accent-transparent');
  }
};

export const changeAccentColorToLocalStorage = (colors) => {
  if (colors) {
    localStorage.setItem('accentColor', colors.color);
    localStorage.setItem('accentColorSecond', colors.colorSecond);
    localStorage.setItem('accentColorTransparent', colors.colorTransparent);
  } else if (colors === null) {
    localStorage.removeItem('accentColor');
    localStorage.removeItem('accentColorSecond');
    localStorage.removeItem('accentColorTransparent');
  }
};

const switchThemeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    getThemeFromLocalStorage: (state) => {
      const themeType = localStorage.getItem('themeType');

      if (themeType) {
        const theme = createAppThemeFromTypeOfTheme(themeType);
        state.typeOfTheme = theme.typeOfTheme;
        state.appTheme = theme.appTheme;
      } else {
        const theme = createAppThemeFromTypeOfTheme('auto');
        state.typeOfTheme = theme.typeOfTheme;
        state.appTheme = theme.appTheme;
      }
    },
    getAccentColorFromLocalStorage: () => {
      console.log('getAccentColorFromLocalStorage');
      const accentColor = localStorage.getItem('accentColor');
      const accentColorSecond = localStorage.getItem('accentColorSecond');
      const accentColorTransparent = localStorage.getItem('accentColorTransparent');

      if (accentColor && accentColorSecond && accentColorTransparent) {
        changeAccentColorToCSS({
          color: accentColor,
          colorSecond: accentColorSecond,
          colorTransparent: accentColorTransparent,
        });
      } else {
        // используем то что уже есть
      }
    },
  },
  extraReducers: {
    [changeTheme.fulfilled]: (state, action) => {
      state.typeOfTheme = action.payload.typeOfTheme;
      state.appTheme = action.payload.appTheme;
    },
  },
});

export const { getThemeFromLocalStorage, getAccentColorFromLocalStorage } = switchThemeSlice.actions;

export const selectTypeOfTheme = (state) => state.theme.typeOfTheme;
export const selectAppTheme = (state) => state.theme.appTheme;

export default switchThemeSlice.reducer;
