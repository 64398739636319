import React from 'react';

const AddIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11.4338L2 4.56633L7.99996 1.13778L13.9999 4.56633L13.9999 11.4338L7.99996 14.8623L2 11.4338ZM3.16128 10.7598L7.99996 13.5248L12.8386 10.7598L12.8386 5.24025L7.99996 2.47529L3.16128 5.24025L3.16128 10.7598Z"
      fill={fill}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.0968 8.58059H4.90332V7.41931H11.0968V8.58059Z" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.58072 4.9033L8.58072 11.0968L7.41943 11.0968L7.41943 4.9033L8.58072 4.9033Z"
      fill={fill}
    />
  </svg>
);
export default AddIcon;
