import React, { useState } from 'react';
import { cn } from '@utils/bem-config';
import './Tabs.scss';
import { Link } from 'react-router-dom';

const bem = cn('tabs');

export const Tabs = ({ tabList, defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab ?? 0);

  return (
    <>
      {/* <div className={bem('wrapper')}> */}
      <div className={bem()}>
        {tabList.map((tab, i) => (
          <Link
            to={tab.url}
            className={bem('item', { active: i === activeTab })}
            onClick={() => setActiveTab(i)}
            key={tab.title}
          >
            {tab.title}
          </Link>
        ))}
        {/* </div> */}
        {/* {tabList[activeTab].additionalComponent} */}
      </div>
      {tabList[activeTab].component}
    </>
  );
};
