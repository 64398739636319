import React from 'react';
import { cn } from '@utils/bem-config';
import './NoMatchPage.scss';

const bem = cn('NoMatchPage');

export const NoMatchPage = ({ title = '', subtitle = '', productName = '' }) => (
  <div className={bem()}>
    <div className={bem('container')}>
      <div className={bem('column', { left: true })}>
        <div className={bem('titles-container')}>
          <div className={bem('titles')}>
            <h1 className={bem('title')}>
              {title}
              <span className={bem('project-name')}>{productName}</span>
            </h1>
            <h2 className={bem('subtitle')}>{subtitle}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
);
