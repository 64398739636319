import UserItemComment from '@components/common/UserItemComment/UserItemComment';
import React from 'react';
import { cn } from '@utils/bem-config';
import { getComments } from '@components/common/PostsList/postsListSlice';
import { useDispatch } from 'react-redux';
import { countOfCommentsPreview } from '@utils/constants';
import './CommentsList.scss';
import { SvgIcon } from '@components/common/SvgIcon';

const bem = cn('comments-list');

export const CommentsList = ({ data, commentsIsOpen, setCommentsIsOpen, currentUserLdapId, currentUserRoles }) => {
  const dispatch = useDispatch();
  const hasComments = data?.commentOutputList?.length > 0;
  const isManyComments = data?.commentOutputList?.length > 2;
  const hasOverCapComments = data?.commentCount > countOfCommentsPreview;
  const lastComments =
    data?.commentOutputList && hasOverCapComments
      ? data?.commentOutputList.slice(data?.commentOutputList.length - countOfCommentsPreview)
      : [];

  const handleToggleComments = () => {
    if (!commentsIsOpen) {
      dispatch(
        getComments({
          postId: data?.postId,
        })
      );
    }
    setCommentsIsOpen(!commentsIsOpen);
  };
  const mappingComments = commentsIsOpen || lastComments.length === 0 ? data?.commentOutputList : lastComments;
  return (
    <>
      {hasComments && (
        <>
          <hr className={bem('hr-line')} />
          <div className={bem({ open: commentsIsOpen })}>
            <div className={bem('container')}>
              {mappingComments.map((e) => (
                <UserItemComment
                  key={e.commentId}
                  name="Имя"
                  commentId={e.commentId}
                  currentUserLdapId={currentUserLdapId}
                  currentUserRoles={currentUserRoles}
                  comment={e}
                />
              ))}
            </div>

            {commentsIsOpen && isManyComments && (
              <div className={bem('hide-comments-wrapper')}>
                <button className={bem('hide-comments-button')} type="button" onClick={handleToggleComments}>
                  <SvgIcon type="arrow" className={bem('hide-comments-icon')} />
                </button>
              </div>
            )}
          </div>
        </>
      )}

      {hasOverCapComments && !commentsIsOpen && (
        <div className={bem('show-comments-block')}>
          <button className={bem('show-comments-button')} type="button" onClick={handleToggleComments}>
            Показать все комментарии
          </button>
        </div>
      )}
    </>
  );
};
