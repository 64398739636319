import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cn } from '@utils/bem-config';
import { Tabs } from '@components/common/Tabs';
import { NotificationsSettings } from '@components/pages/NotificationsSettings';
import './Settings.scss';
import { AboutMeSettings } from '@components/pages/AboutMeSettings';
import { SwitchTheme } from '@components/common/SwitchTheme';

const bem = cn('settings');

export const Settings = () => {
  function useQuery(location) {
    return new URLSearchParams(location.search ? location.search : '?tab=about');
  }

  const history = useHistory();
  const { location } = history;
  const queryParams = useQuery(location);
  useEffect(() => {
    history.push(`?${queryParams.toString()}`);
  }, []);
  const tabComponents = [
    {
      title: 'О себе',
      name: 'about',
      component: <AboutMeSettings />,
      url: `${location.pathname}?tab=about`,
    },
    {
      title: 'Уведомления',
      name: 'notifications',
      component: <NotificationsSettings />,
      url: `${location.pathname}?tab=notifications`,
    },
    {
      title: 'Персонализация',
      name: 'personalization',
      component: <SwitchTheme />,
      url: `${location.pathname}?tab=personalization`,
    },
  ];
  const getTabIndex = (name) => {
    const foundIndex = tabComponents.findIndex((tab) => tab.name === name);
    const defaultIndex = 0;
    const activeTabIndex = foundIndex === -1 ? defaultIndex : foundIndex;
    return activeTabIndex;
  };
  const defaultTab = getTabIndex(queryParams.get('tab'));

  return (
    <div className={bem()}>
      <h1 className={bem('title')}>Настройки</h1>
      <Tabs defaultTab={defaultTab} tabList={tabComponents} />
    </div>
  );
};
