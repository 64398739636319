import React, { useState } from 'react';
import { Input, Typography, Divider, message } from 'antd';
import { SvgIcon } from '@components/common/SvgIcon';
import { DropdownMenuCategories } from '@components/common/DropdownMenuCategories/DropdownMenuCategories';
import { useDispatch, useSelector } from 'react-redux';
import { addPost, selectTagsList, setIsOpenVoteForm } from '@components/common/PostsList/postsListSlice';
import { useHistory } from 'react-router-dom';
import { cn } from '@utils/bem-config';
import './NewVoteForm.scss';
import { ButtonAnimated } from '@components/common/ButtonAnimated';

const bem = cn('new-vote-form');

export const NewVoteForm = () => {
  const dispatch = useDispatch();
  const [questionValue, setQuestionValue] = useState('');
  const [inputValues, setInputValues] = useState({ 0: { text: '', id: '0' } });
  const [counter, setCounter] = useState(1);
  const [tagType, setTagType] = useState([]);

  const tags = useSelector(selectTagsList);
  const history = useHistory();
  const goToAdminPage = () => history.push('/administration');
  const amoutVotes = Object.values(inputValues).length;

  const handleOnChange = (e) => {
    const newAnswerValue = {};
    newAnswerValue[e.target.id] = { text: e.target.value, id: e.target.id };
    const keys = Object.keys(inputValues);
    let isNextProp = false;
    keys.forEach((key, index) => {
      if (e.target.id === key) {
        isNextProp = !!inputValues[keys[index + 1]];
      }
    });
    setInputValues({ ...inputValues, ...newAnswerValue });
    if (keys.length < 8 && !isNextProp) {
      setCounter(counter + 1);
      setInputValues((prev) => ({ ...prev, [`${counter}`]: { text: '', id: counter } }));
    }
  };

  const handleSubmit = () => {
    const answers = Object.values(inputValues)
      .map((item) => item.text)
      .filter((item) => item !== '' && !item.match(/^\s*$/gi));

    if (answers.length === 0 || Object.values(inputValues)[0].text === '') {
      message.error('Должен существовать хотя бы один вариант ответа');
    } else {
      dispatch(
        addPost({
          content: 'vote',
          textContent: '',
          tags: tagType,
          vote: {
            description: questionValue,
            answers,
          },
        })
      );
      dispatch(setIsOpenVoteForm());
    }
  };

  const deleteAnswerHandler = (id) => {
    const inputValuesHandler = { ...inputValues };
    delete inputValuesHandler[`${id}`];
    const keys = Object.keys(inputValues);
    if (keys.length < 8) {
      setCounter(counter + 1);
      setInputValues(inputValuesHandler);
    } else {
      setInputValues({ ...inputValuesHandler, [`${counter}`]: { text: '', id: counter } });
    }
  };

  return (
    <div className={bem()}>
      <Typography className={bem('typography')}>Вопрос</Typography>
      <Input
        className={bem('input')}
        placeholder="Задайте вопрос"
        value={questionValue}
        onChange={(e) => setQuestionValue(e.currentTarget.value)}
        bordered={false}
      />
      <Typography className={bem('typography')}>Варианты ответа</Typography>
      <div className={bem('answers--container')}>
        {Object.keys(inputValues).map((item) => (
          <div className={bem('answers--container__item')} key={item}>
            <Input
              className={bem('input--answers')}
              placeholder="Ваш вариант"
              onChange={handleOnChange}
              id={item}
              value={inputValues[item].text}
              key={item}
              bordered={false}
            />
            {Object.keys(inputValues).length !== 1 && Boolean(inputValues[item].text) && (
              <button className={bem('close-btn')} onClick={() => deleteAnswerHandler(inputValues[item].id)}>
                <SvgIcon type="close" />
              </button>
            )}
            <Divider className={bem('divider')} key={`${inputValues[item].id}-divider`} />
          </div>
        ))}
      </div>
      <Typography className={bem('left-answers')}>
        {8 - amoutVotes
          ? `Вы можете добавить еще ${8 - amoutVotes} ${amoutVotes >= 4 ? 'варианта' : 'вариантов'}`
          : 'Вы добавили максимальное количество вариантов'}
      </Typography>
      <Divider className={bem('divider')} />
      <div className={bem('action-bar')}>
        <DropdownMenuCategories
          data={tags}
          currentData={tagType}
          setCurrentData={setTagType}
          functionOnClick={goToAdminPage}
        />
        <div className={bem('action-bar--item')}>
          <ButtonAnimated onClick={() => dispatch(setIsOpenVoteForm())}>Отмена</ButtonAnimated>
          <button onClick={handleSubmit} className={bem('submit-btn')}>
            <SvgIcon type="send" />
          </button>
        </div>
      </div>
    </div>
  );
};
