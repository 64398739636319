import React from 'react';

const PollIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} xmlns="http://www.w3.org/2000/svg">
    <rect width="2" height="5" transform="matrix(1 -8.01375e-08 -9.53703e-08 -1 3 14)" fill={fill} />
    <rect width="2" height="8" transform="matrix(1 -8.01375e-08 -9.53703e-08 -1 7 14)" fill={fill} />
    <rect width="2" height="11" transform="matrix(1 -8.01375e-08 -9.53703e-08 -1 11 14)" fill={fill} />
  </svg>
);

export default PollIcon;
