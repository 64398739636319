const cutNumber = (number, degree) => {
  const dividedNumber = number / 1.0e3 ** degree;
  const result = parseInt(dividedNumber * 10, 10) / 10;
  return Number.isInteger(result) ? result.toFixed(1) : result;
};

export const formatLikes = (likes) => {
  switch (true) {
    case likes >= 1.0e6:
      return `${cutNumber(likes, 2)}M`;
    case likes >= 1.0e4:
      return `${cutNumber(likes, 1)}k`;
    default:
      return likes;
  }
};
