import { TernaryForSkeleton } from '@components/common/Skeletons/Skeleton';
import { SvgIcon } from '@components/common/SvgIcon';
import { useDebounce } from '@src/hooks/useDebounce';
import { cn } from '@utils/bem-config';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { limitOfSearchedPostsSymbols } from '@utils/constants';
import { CommonTooltip } from '@components/common/CommonTooltip';
import { getTags, selectTagsList } from '@components/common/PostsList/postsListSlice';
import {
  searchPosts,
  searchUsers,
  selectSearchedPosts,
  selectSearchedUsers,
  setRecentSearchValue,
  setTagValue,
} from './searchSlice';

import './Search.scss';

const bem = cn('search');

export const Search = ({ isFocused, setIsFocused, tablet }) => {
  const dispatch = useDispatch();
  const tagsList = useSelector(selectTagsList);
  const searchedUsers = useSelector(selectSearchedUsers);
  const searchedPosts = useSelector(selectSearchedPosts);
  const [isSearching, setIsSearching] = useState(false);
  const [tags, setTags] = useState('');
  const [isActiveTab, setIsActiveTab] = useState(false);
  const history = useHistory();
  const searchRef = useRef();
  const postsTitleRef = useRef();
  const postsRef = useRef();
  const usersTitleRef = useRef();
  const usersRef = useRef();
  const tagsTitleRef = useRef();
  const tagsRef = useRef();
  const [searchValue, setSearchValue] = useState('');

  const trimmedSearchValue = searchValue?.trim();
  const debouncedSearchValue = useDebounce(trimmedSearchValue, 500);
  const debouncedFocus = useDebounce(isFocused, 500);

  const isEmptySearchResult =
    !searchedUsers.results.length && !searchedPosts.results.length && trimmedSearchValue && !isSearching;

  const isSearchResult = searchedUsers.results.length > 0 || searchedPosts.results.length > 0 || tags.length > 0;

  const handleUnFocus = () => {
    setIsFocused(false);
  };

  const closeSearch = () => {
    handleUnFocus();
    searchRef.current.blur();
  };

  const escCloseHandler = (e) => {
    if (e.keyCode === 27) {
      closeSearch();
    }
  };

  const getNavigationElements = () =>
    [
      searchRef.current ?? [],
      tagsTitleRef.current ?? [],
      tagsRef.current?.childElementCount > 0 ? [...tagsRef.current?.childNodes] : [],
      usersTitleRef.current ?? [],
      usersRef.current?.childElementCount > 0 ? [...usersRef.current?.childNodes] : [],
      postsTitleRef.current ?? [],
      postsRef.current?.childElementCount > 0 ? [...postsRef.current?.childNodes] : [],
    ].flat();

  const changeActiveTab = (arrowType) => {
    const navigationElements = getNavigationElements();
    if (navigationElements.length > 0) {
      setIsActiveTab(true);
      const currentActiveTabIndex = navigationElements.findIndex((item) => document.activeElement === item);
      if (currentActiveTabIndex >= 0) {
        const indexToChange = arrowType === 'ArrowDown' ? currentActiveTabIndex + 1 : currentActiveTabIndex - 1;
        if (navigationElements[indexToChange]) {
          navigationElements[indexToChange].focus();
          navigationElements[currentActiveTabIndex].blur();
        }
      } else {
        navigationElements[0]?.focus();
      }
    }
  };

  const arrowNavigation = (e) => {
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      changeActiveTab(e.code);
    }
  };

  const creatorsPageScrollHandler = () => {
    if (document.querySelector('html').classList.contains('isScrolled') && history.location.pathname === '/creators') {
      closeSearch();
    }
  };

  useEffect(() => {
    if (isFocused) {
      document.addEventListener('keydown', escCloseHandler);
      document.addEventListener('keydown', arrowNavigation);
      document.addEventListener('scroll', creatorsPageScrollHandler);
    }
    dispatch(getTags());
    return () => {
      document.removeEventListener('keydown', escCloseHandler);
      document.removeEventListener('keydown', arrowNavigation);
      document.removeEventListener('scroll', creatorsPageScrollHandler);
    };
  }, [isFocused]);

  useEffect(() => {
    (async () => {
      let isRequestEmpty = false;
      if (isFocused) {
        dispatch(setRecentSearchValue(trimmedSearchValue));
        if (!trimmedSearchValue) {
          isRequestEmpty = true;
        }
        await dispatch(searchUsers({ searchValue: trimmedSearchValue, size: 3, emptyRequest: isRequestEmpty }));
        await dispatch(searchPosts({ searchValue: trimmedSearchValue, size: 3, emptyRequest: isRequestEmpty }));
      }
    })();
    setTags('');
  }, [trimmedSearchValue, isFocused]);

  useEffect(() => {
    setIsSearching(false);
  }, [debouncedSearchValue]);

  useEffect(() => {
    setIsSearching(true);
    if (trimmedSearchValue.length > 0 && trimmedSearchValue[0] === '#') {
      const tagsWithHash = tagsList.map((item) => `#${item}`);
      const searchTags = tagsWithHash.filter(
        (item) => trimmedSearchValue.length > 0 && item.includes(trimmedSearchValue)
      );
      setTags(searchTags);
    }
  }, [trimmedSearchValue]);

  const handleSearchClear = () => {
    setSearchValue('');
    trimmedSearchValue[0] === '#' && dispatch(setTagValue(true));
    dispatch(setRecentSearchValue(trimmedSearchValue[0] === '#' ? '' : trimmedSearchValue));
    handleUnFocus();
  };

  const resetFocusTabs = () => {
    if (isActiveTab) {
      const allElements = getNavigationElements();
      setIsActiveTab(false);
      allElements.forEach((item, index) => {
        allElements[index].blur();
      });
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  function handleChangeSearch(e) {
    setSearchValue(e.target.value);
  }

  const openItem = (pushPath) => {
    history.push(pushPath);
  };

  const showFirstSection = () => {
    if (trimmedSearchValue.length > 0 && trimmedSearchValue[0] === '#') {
      openItem('/search');
    } else if (searchedUsers.results.length) {
      openItem('/users');
    } else {
      openItem('/search');
    }
  };

  const handleKeypressSearch = (e) => {
    if (e.which === 13 && (searchedUsers.results.length || searchedPosts.results.length)) {
      e.target.blur();
      showFirstSection();
      handleSearchClear();
    }
  };

  const handleGlobalSearch = () => {
    showFirstSection();
    handleSearchClear();
  };

  const handleSearchStart = () => {
    searchRef.current.focus();
  };

  const handleSearchInputOnClick = (e) => {
    e.stopPropagation();
  };

  const handleTagOnClick = (tag) => {
    dispatch(setTagValue(tag.slice(1)));
    dispatch(setRecentSearchValue(''));
    setSearchValue('');
    dispatch(setRecentSearchValue(''));
    handleUnFocus();
  };

  const selectSearchedWords = (searchedItemText, isUsersSearch) => {
    const searchedItemTextLower = searchedItemText?.toLowerCase();

    if (searchedItemText && searchedItemText.length >= trimmedSearchValue.length) {
      let searchValueWords = new Array(trimmedSearchValue);

      if (isUsersSearch) {
        searchValueWords = trimmedSearchValue.split(' ');
      }
      const itemTextWithSelect = searchedItemText;

      const allText = searchedItemText.toLowerCase().split(' ');

      let strSearchValue = null;
      const arraySearchValue = [];
      const isSearchValueFirstName =
        searchValueWords.length === 2 && allText[1] === trimmedSearchValue.toLowerCase().split(' ')[0];

      if (isSearchValueFirstName) {
        searchValueWords.forEach((word) => {
          const selectionStartIndex = searchedItemTextLower?.indexOf(word.toLowerCase());
          const selectionEndIndex = selectionStartIndex + word.length;
          const beforeValue = itemTextWithSelect?.substring(0, selectionStartIndex);
          const value = itemTextWithSelect?.substring(selectionStartIndex, selectionEndIndex);
          const afterValue = itemTextWithSelect?.substring(selectionEndIndex);
          arraySearchValue.push(`${beforeValue}<b>${value}</b>${afterValue}`);
        });
        const reverseArray = arraySearchValue.reverse().join(' ').split(' ');
        return `${reverseArray[0]} ${reverseArray[reverseArray.length - 1]}`;
      }

      searchValueWords = trimmedSearchValue.split(/\b/gm);
      searchValueWords.forEach((word) => {
        const selectionStartIndex = searchedItemTextLower?.indexOf(word.toLowerCase());
        const selectionEndIndex = selectionStartIndex + word.length;

        const beforeValue = itemTextWithSelect?.substring(0, selectionStartIndex);
        const value = itemTextWithSelect?.substring(selectionStartIndex, selectionEndIndex);
        const afterValue = itemTextWithSelect?.substring(selectionEndIndex);
        strSearchValue = `${beforeValue}<b>${value}</b>${afterValue}`;
      });
      return strSearchValue;
    }
  };
  const SearchedUsersSection = () => (
    <>
      <Link
        to="/users"
        className={bem('section-title')}
        onMouseMove={resetFocusTabs}
        ref={usersTitleRef}
        onClick={handleGlobalSearch}
      >
        {searchedUsers.title}
      </Link>
      <nav className={bem('section-list')} ref={usersRef}>
        {searchedUsers.results.slice(0, 3).map((item) => {
          const searchResultWithSelection = selectSearchedWords(`${item.lastName} ${item.firstName}`, true);
          return (
            <Link
              className={bem('section-item')}
              key={item.ldapId}
              onMouseMove={resetFocusTabs}
              to={`/users/${item.ldapId}`}
              onClick={handleSearchClear}
            >
              {ReactHtmlParser(searchResultWithSelection)}
            </Link>
          );
        })}
      </nav>
    </>
  );

  const SearchedPostsSection = ({ searchedContent }) => (
    <>
      <Link
        to="/search"
        className={bem('section-title')}
        onMouseMove={resetFocusTabs}
        ref={postsTitleRef}
        onClick={handleGlobalSearch}
      >
        {searchedContent.title}
      </Link>
      <nav className={bem('section-list')} ref={postsRef}>
        {searchedContent.results.slice(0, 3).map((item) => {
          const searchResultWithSelection = selectSearchedWords(item?.textContent);
          const textContent = item?.textContent;
          return (
            <Link
              className={bem('section-item')}
              key={item.postId}
              onMouseMove={resetFocusTabs}
              to={`/feed/${item.postId}`}
              onClick={handleSearchClear}
            >
              {textContent?.length > limitOfSearchedPostsSymbols
                ? ReactHtmlParser(`${searchResultWithSelection.slice(0, limitOfSearchedPostsSymbols)}...`)
                : ReactHtmlParser(searchResultWithSelection)}
            </Link>
          );
        })}
      </nav>
    </>
  );

  const SearchedTagsSection = () => (
    <>
      <Link
        to="/search"
        className={bem('section-title')}
        onMouseMove={resetFocusTabs}
        ref={tagsTitleRef}
        onClick={handleGlobalSearch}
      >
        Тэги
      </Link>
      <nav className={bem('section-list')} ref={usersRef}>
        {tags.slice(0, 3).map((tag) => {
          const searchResultWithSelection = selectSearchedWords(tag);
          return (
            tag && (
              <Link
                className={bem('section-item')}
                key={tag}
                onClick={() => handleTagOnClick(tag)}
                to="/search"
                onMouseMove={resetFocusTabs}
              >
                {ReactHtmlParser(searchResultWithSelection)}
              </Link>
            )
          );
        })}
      </nav>
    </>
  );
  return (
    <div className={bem({ tablet })}>
      <input
        ref={searchRef}
        type="text"
        className={bem('search-input', { tablet })}
        placeholder="Поиск"
        onFocus={handleFocus}
        value={searchValue}
        onChange={handleChangeSearch}
        onKeyPress={handleKeypressSearch}
        onClick={handleSearchInputOnClick}
      />
      {!isFocused ? (
        <div className={bem('search-icon-wrapper')} onClick={handleSearchStart}>
          <SvgIcon type="search" />
        </div>
      ) : (
        <>
          <div className={bem('bg-blur')} onClick={handleUnFocus} />
          <CommonTooltip title="Закрыть">
            <button type="button" className={bem('cancel-btn')} onClick={handleSearchClear}>
              <SvgIcon type="close" />
            </button>
          </CommonTooltip>
          <>
            {isEmptySearchResult && (
              <div className={bem('results-block')}>
                <div className={bem('results-content')}>
                  <p>Ничего не найдено</p>
                </div>
              </div>
            )}
          </>
          {debouncedFocus
            ? isSearchResult && (
                <div className={bem('results-block')}>
                  <div className={bem('results-content')}>
                    <TernaryForSkeleton isLoading={isSearching} times={2}>
                      {tags.length !== 0 && <SearchedTagsSection />}
                      {searchedUsers.results.length !== 0 && <SearchedUsersSection />}
                      {searchedPosts.results.length !== 0 && <SearchedPostsSection searchedContent={searchedPosts} />}
                    </TernaryForSkeleton>
                  </div>
                </div>
              )
            : null}
        </>
      )}
    </div>
  );
};
