import { SearchSlice } from '@components/common/Search/searchSlice';
import switchThemeSlice from '@components/common/SwitchTheme/switchThemeSlice';
import usersSearchSlice from '@components/common/UsersSearch/usersSearchSlice';
import userPageSlice from '@components/pages/UserPage/userPageSlice';
import { configureStore } from '@reduxjs/toolkit';
import appSlice from '@src/appSlice';
import birthdaysListSlice from '@src/components/common/BirthdaysList/birthdaysListSlice';
import MyProfilePreviewSlice from '@src/components/common/MyProfilePreview/myProfilePreviewSlice';
import postsListSlice from '@src/components/common/PostsList/postsListSlice';
import usersListSlice from '@src/components/common/UsersList/usersListSlice';
import geographyOfficesSlice from '@src/components/pages/Geography/GeographyOffices/geographyOfficesSlice';

export const store = configureStore({
  reducer: {
    app: appSlice,
    birthdaysList: birthdaysListSlice,
    officesList: geographyOfficesSlice,
    postsList: postsListSlice,
    search: SearchSlice,
    storeMyProfilePreview: MyProfilePreviewSlice,
    theme: switchThemeSlice,
    usersList: usersListSlice,
    usersPage: userPageSlice,
    usersSearch: usersSearchSlice,
  },
});
