import { cn } from '@utils/bem-config';
import React from 'react';
import './Skeleton.scss';

const bem = cn('skeleton');

export const Skeleton = ({ width, height }) => <i className={bem({ type: 'text' })} style={{ width, height }} />;

export const TernaryForSkeleton = ({ children, isLoading = false, width, height, times = 1 }) => {
  const timesArray = new Array(times).fill(null);
  const renderTimes = timesArray.map(() => <Skeleton key={Math.random()} width={width} height={height} />);

  return <>{isLoading ? <>{renderTimes}</> : <>{children}</>}</>;
};
