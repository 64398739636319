import { Button } from '@components/common/Button';
import { ButtonAnimated } from '@components/common/ButtonAnimated';
import { Modal } from '@components/common/Modal';
import { cn } from '@utils/bem-config';
import React, { useState } from 'react';
import './UserCropAvatar.scss';
import { useDispatch } from 'react-redux';
import { updateUserAvatar } from '@components/common/MyProfilePreview/myProfilePreviewSlice';
import { ReactCropComponent } from '@components/common/ReactCropComponent/ReactCropComponent';

const bem = cn('crop');

export const UserCropAvatar = ({ userAvatarCrop, setUserAvatarCrop, user, upImg, setUpImg, isAdmin }) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState();
  const cancelHandler = () => {
    setUpImg('');
    setUserAvatarCrop(false);
  };

  const changeUserPhoto = () => {
    const file = new File([result], 'avatar.jpg', {
      type: 'image/jpeg',
    });
    dispatch(updateUserAvatar({ userLdapId: user?.ldapId, file, userId: user.userId, isAdmin }));
    cancelHandler();
  };

  const AvatarCropModalContent = (
    <>
      <ReactCropComponent upImg={upImg} setResult={setResult} />

      <div className={bem('form-btns')}>
        <Button type="button" onClick={changeUserPhoto}>
          Сохранить
        </Button>
        <ButtonAnimated onClick={cancelHandler} type="cancel">
          Отмена
        </ButtonAnimated>
      </div>
    </>
  );

  return (
    <>
      {userAvatarCrop && upImg && (
        <Modal title="Фотография на вашей странице" content={AvatarCropModalContent} closeHandler={cancelHandler} />
      )}
    </>
  );
};
