import React from 'react';


const SendIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
<svg width={width} style={style} height={height} viewBox={viewBox} className={className} xmlns="http://www.w3.org/2000/svg">
  <path d="M1 2L15 8.00002L1 14L2.18357 9.26575L4.08217 8.00002L2.18357 6.73429L1 2Z" fill={fill}/>
</svg>
);

export default SendIcon;
