import React, { useEffect } from 'react';
import { cn } from '@utils/bem-config';
import './MobileHeader.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { SvgIcon } from '@components/common/SvgIcon';
import { Search } from '@components/common/Search';
import { BigMobileQuery, LittleTabletQuery } from '@utils/responsive';

const bem = cn('mobile-header');

export const MobileHeader = ({
  isFocused,
  setIsFocused,
  setIsBirthdayOpen,
  setIsMenuOpen,
  tablet,
  isBirthdayOpen,
  isMenuOpen,
}) => {
  const currentPathname = useLocation().pathname;

  const toggleMenu = () => {
    if (isBirthdayOpen) {
      setIsBirthdayOpen(false);
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleBirthday = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
    setIsBirthdayOpen(!isBirthdayOpen);
  };

  useEffect(() => {
    setIsBirthdayOpen(false);
    setIsMenuOpen(false);
  }, [currentPathname]);

  return (
    <div className={bem()}>
      <NavLink to="/feed" className={bem('logo-link')}>
        <LittleTabletQuery>
          <SvgIcon type="header-logo-scroll" />
        </LittleTabletQuery>
        <BigMobileQuery>
          <SvgIcon type="header-logo" />
        </BigMobileQuery>
      </NavLink>
      <Search isFocused={isFocused} setIsFocused={setIsFocused} tablet={tablet} />
      <div className={bem('block-icons')}>
        <button type="button" onClick={toggleBirthday} className={bem('cake-btn')}>
          <SvgIcon type="cake" stroke={isBirthdayOpen ? 'var(--c-accent)' : 'var(--text-level-3)'} />
        </button>
        <button type="button" onClick={toggleMenu} className={bem('burger-btn')}>
          <SvgIcon type="burger" stroke={isMenuOpen ? 'var(--c-accent)' : 'var(--text-level-3)'} />
        </button>
      </div>
    </div>
  );
};
