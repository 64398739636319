import { TernaryForSkeleton } from '@components/common/Skeletons/Skeleton';
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { cn } from '@utils/bem-config';
import ReactHtmlParser from 'react-html-parser';
import './PostContent.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTagValue, setRecentSearchValue } from '@components/common/Search/searchSlice';
import { linksReg } from '@utils/regularExpressions';
import { convertSymbolsToHTML } from '@utils/convertSymbols';
import { Vote } from '@components/common/Vote';
import { putVote } from '@components/common/PostsList/postsListSlice';

const bem = cn('post-content');
export const PostContent = ({ isLoading, data, dataSelectedVoice }) => {
  const [toggleLightbox, setToggleLightbox] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const pictures = data?.pictures;
  const dispatch = useDispatch();

  const handleTagsSearch = (tagValue) => {
    dispatch(setRecentSearchValue(''));
    dispatch(setTagValue(tagValue));
  };

  const handleToggleLightbox = (slide) => {
    setToggleLightbox(!toggleLightbox);
    setCurrentSlide(slide + 1);
  };

  const putVoiteHandler = (e) => {
    const answerId = e.target.value;
    dispatch(putVote({ answerId, postId: data.postId }));
  };

  const cssGridColumnsString = (length) => {
    switch (true) {
      case length === 1:
        return '1fr';

      case length === 2:
        return '1fr 1fr';

      case length >= 3:
        return '1fr 1fr 1fr';

      default:
        return '1fr';
    }
  };

  return (
    <div className={bem()}>
      <div className={bem('txt-content')}>
        {(() => {
          switch (data?.postType) {
            case 'VOTE':
              return (
                <Vote
                  title={data.voteResponse.description}
                  surveyType="Анонимный опрос"
                  voteAnswers={data.voteResponse.answers}
                  onChange={putVoiteHandler}
                  dataSelectedVoice={dataSelectedVoice}
                  isLoading={isLoading}
                />
              );

            case 'DEFAULT':
              return (
                <>
                  <TernaryForSkeleton isLoading={isLoading} width="100%" times={2}>
                    {ReactHtmlParser(data?.content, {
                      transform: (node) => {
                        let replaceValue;
                        if (node.name !== 'a' && node?.data) {
                          replaceValue = node?.data?.replace(
                            linksReg,
                            '<a href="$&" rel="noopener noreferrer" target="_blank">$&</a>'
                          );
                          replaceValue = replaceValue === node.data ? convertSymbolsToHTML(replaceValue) : replaceValue;

                          return ReactHtmlParser(replaceValue);
                        }
                      },
                    })}
                  </TernaryForSkeleton>
                  <TernaryForSkeleton isLoading={isLoading} times={1} width="30rem" />
                </>
              );

            default: {
              return null;
            }
          }
        })()}
      </div>
      {Boolean(pictures?.length) && (
        <div
          className={bem('images-container')}
          style={{ gridTemplateColumns: cssGridColumnsString(data?.pictures?.length) }}
        >
          {pictures?.map((picture, i) => (
            <img
              key={picture.pictureId}
              src={picture.url}
              className={bem('post-img')}
              onClick={() => handleToggleLightbox(i)}
              alt="img"
            />
          ))}

          <FsLightbox
            toggler={toggleLightbox}
            slide={currentSlide}
            sources={pictures?.map((picture) => picture.url)}
            type="image"
          />
        </div>
      )}
      {Boolean(data?.tagOutput?.length) && (
        <div className={bem('link-block')}>
          {data?.tagOutput.map((e) => (
            <NavLink to="/search" className={bem('tag')} key={e.name} onClick={() => handleTagsSearch(e.name)}>
              #{e.name}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};
