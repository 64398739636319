import React from 'react';
import { cn } from '@utils/bem-config';
import './AboutMeSettings.scss';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@components/common/MyProfilePreview/myProfilePreviewSlice';
import { SvgIcon } from '@components/common/SvgIcon';

const bem = cn('about-me-settings');
export const AboutMeSettings = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dateFormatter = currentUser?.birthDate && currentUser.birthDate.split('-').reverse().join('.');

  return (
    <div className={bem()}>
      <div className={bem('about-user-list')}>
        <div className={bem('about-user-list-item')}>
          <span>Почта:</span>
          <b>{currentUser?.email}</b>
        </div>
        <div className={bem('about-user-list-item')}>
          <span>Скайп:</span>
          <b>{currentUser?.skype}</b>
        </div>
        <div className={bem('about-user-list-item')}>
          <span>Телеграмм:</span>
          <b>{currentUser?.telegram}</b>
        </div>
        <div className={bem('about-user-list-item')}>
          <span>Дата рождения:</span>
          <b>{dateFormatter}</b>
        </div>
      </div>
      <div className={bem('information-plate')}>
        <div className={bem('information-plate-container')}>
          <div className={bem('information-title')}>
            Если в ваших данных содержится ошибка, напишите об этом <b>HR</b> своего направления или своему{' '}
            <b>руководителю</b>
          </div>
          <div>
            <SvgIcon type="exclamation-point" />
          </div>
        </div>
      </div>
    </div>
  );
};
