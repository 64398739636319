/* eslint-disable max-len */
export const defaultViewPortWidth = 80;
export const defaultViewPortHeight = 30;
export const accuracy = 4;
export const velocity = 20;
export const transitionsBezierCurve = '.79, .14, .32, .96; ';

export const rawPaths = `<path d="M7.0787 0.252315C-4.99506 2.7582 0.695694 19.1725 7.07872 16.3951C20.2477 10.6649 17.6198 -1.93547 7.0787 0.252315Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M68.5627 18.2938C56.654 20.728 66.6277 34.3458 72.9235 31.6479C85.9125 26.0818 78.9597 16.1686 68.5627 18.2938Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M9.60961 5.15208C-1.27044 2.31047 -9.23306 40.3534 22.303 30.3057C43.7715 23.4655 20.0168 7.87019 9.60961 5.15208Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M25.1001 0.643615C13.9018 1.77096 9.88495 19.4168 42.8743 16.2634C79.4999 12.7624 65.2267 -3.39599 25.1001 0.643615Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M48.5335 2.83324C20.9605 10.0825 39.1334 40.4604 70.6697 30.2768C92.1383 23.3443 70.6697 -2.98664 48.5335 2.83324Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M55.9417 0.199811C44.9621 1.0728 53.4784 39.6621 69.6986 26.1226C88.6219 10.3266 79.9799 -1.71146 55.9417 0.199811Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M25.317 5.0659C13.1053 6.77805 37.9497 40.9812 46.0013 21.5737C53.4641 3.58558 44.1689 2.42273 25.317 5.0659Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M31.3826 2.14357C19.3089 4.64946 14.1587 40.5047 45.695 30.3211C67.1636 23.3886 41.9237 -0.044215 31.3826 2.14357Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M64.3567 1.01736C52.0338 1.46637 57.4257 14.485 64.3566 15.1324C85.513 17.1084 81.637 0.387714 64.3567 1.01736Z" fill="#C4C4C4" fill-opacity="0.4"/>
<path d="M13.3531 12.0759C4.19104 11.4086 -11.9354 14.9596 14.3359 29.97C30.2837 39.082 49.6322 14.7182 13.3531 12.0759Z" fill="#C4C4C4" fill-opacity="0.4"/>`;
