import React from 'react';

const BurgerIcon = ({
  style = {},
  stroke = 'var(--text-level-3)',
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}) => (
  <svg
    className={className}
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="12" transform="matrix(3.64262e-08 1 1 -5.16589e-08 2 3)" fill={stroke} />
    <rect width="2" height="12" transform="matrix(3.64262e-08 1 1 -5.16589e-08 2 7)" fill={stroke} />
    <rect width="2" height="12" transform="matrix(3.97376e-08 1 1 -4.7354e-08 2 11)" fill={stroke} />
  </svg>


);
export default BurgerIcon;
