import React from 'react';


const DeleteIcon = ({ fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} height={height} viewBox={viewBox} className={className} xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M3.5 6H4.5V12.1667H11.5V6H12.5V13.1667H3.5V6Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M13.3332 5.16663H2.6665V4.16663H13.3332V5.16663Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M6.1665 11.3333V6H7.1665V11.3333H6.1665Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M8.8335 11.3333V6H9.8335V11.3333H8.8335Z" />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8335 2.83337H11.1668V5.16671H4.8335V2.83337ZM5.8335 3.83337V4.16671H10.1668V3.83337H5.8335Z"
    />
  </svg>
);

export default DeleteIcon;
