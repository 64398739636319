import { Portal } from '@components/common/Portal/Portal';
import React from 'react';
import { SvgIcon } from '@components/common/SvgIcon';
import { cn } from '@utils/bem-config';
import './Modal.scss';
import ReactHtmlParser from 'react-html-parser';

const bem = cn('modal');
export const Modal = ({ closeHandler, content, title, text, className }) => (
  <Portal id={bem()} modalContentClassName={bem('inner-wrapper')}>
    <button className={bem('close-btn')} onClick={closeHandler}>
      <SvgIcon type="close" />
    </button>
    {title && <h1 className={bem('title')}>{title}</h1>}
    {text && <span className={bem(className)}>{ReactHtmlParser(text)}</span>}
    {content}
  </Portal>
);
