import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import './ReactQuillEditor.scss';

const modules = {
  toolbar: [[{ header: [3, false] }], ['bold', 'strike'], [{ list: 'bullet' }], ['link'], ['clean']],
};

export const ReactQuillEditor = ({ contentInputRef, handleChange, postContent, handleCtrlEnter }) => {
  useEffect(() => {
    document.querySelector('.ql-editor').setAttribute('ondragstart', 'return false;');
    document.querySelector('.ql-editor').setAttribute('ondrop', 'return false;');
  }, []);

  return (
    <div className="text-editor">
      <ReactQuill
        theme="snow"
        placeholder="Расскажите о чем то новом..."
        onChange={handleChange}
        value={postContent}
        onKeyPress={handleCtrlEnter}
        ref={contentInputRef}
        modules={modules}
      />
    </div>
  );
};
