import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { STATUS } from '@src/hoc/StatusSwitch';
import { api, request } from '@src/app/api';
import { searchUsers } from '@components/common/Search/searchSlice';
import Qs from 'qs';

const initialState = {
  searchedUsersList: [],
  usersSearchStatus: STATUS.NO_STATUS,
  userSearchValue: '',
};

export const searchUsersList = createAsyncThunk('searchUsersList', async (data, { rejectWithValue }) => {
  try {
    const { debouncedUsersValue, size, department, city } = data;
    const response = await request.get(api.usersSearch(), {
      params: {
        size,
        page: 0,
        fullName: debouncedUsersValue,
        department,
        city,
        delete: false,
      },
      paramsSerializer(params) {
        return Qs.stringify(params);
      },
    });
    return { response: response.data, debouncedUsersValue };
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const usersSearchSlice = createSlice({
  name: 'usersSearchSlice',
  initialState,
  reducers: {
    setUserSearchValue: (state, { payload }) => {
      state.userSearchValue = payload;
    },
  },
  extraReducers: {
    [searchUsers.fulfilled]: (state, action) => {
      state.userSearchValue = action.payload.searchValue;
    },
    [searchUsersList.pending]: (state) => {
      state.usersSearchStatus = STATUS.LOADING;
    },
    [searchUsersList.fulfilled]: (state, action) => {
      state.searchedUsersList = action.payload.response;
      state.userSearchValue = action.payload.debouncedUsersValue;
      state.usersSearchStatus = STATUS.DONE;
    },
    [searchUsersList.rejected]: (state, response) => {
      message.error(response?.payload?.debugMessage);
      state.usersSearchStatus = STATUS.ERROR;
    },
  },
});

export const { setUserSearchValue } = usersSearchSlice.actions;

export const selectUsersSearchStatus = (state) => state.usersSearch.usersSearchStatus;
export const selectSearchedUsersList = (state) => state.usersSearch.searchedUsersList;
export const selectUserSearchValue = (state) => state.usersSearch.userSearchValue;

export default usersSearchSlice.reducer;
