import { cn } from '@src/utils/bem-config';
import React, { useEffect, useState } from 'react';
import './Scroll.scss';

const bem = cn('scroll');

export const Scroll = () => {
  const [isActive, setIsActive] = useState(false);
  const html = document.querySelector('html');

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 100 && !isActive) {
        setIsActive(true);
        html.classList.add('isScrolled');
      }

      if (window.scrollY < 100 && isActive) {
        setIsActive(false);
        html.classList.remove('isScrolled');
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, [isActive]);

  return (
    <div className={bem({ active: isActive })}>
      <button onClick={handleClick} className={bem('sticky-button')}>
        <div className={bem('left-column')}>
          <div className={bem('button-title')}>Наверх</div>
        </div>
      </button>
    </div>
  );
};
