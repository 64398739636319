import React from 'react';


const WelcomeIcon = ({ style = {}, fill = 'var(--text-level-3)', width = '16', height = '16', className = '', viewBox = '0 0 16 16' }) => (
  <svg width={width} height={height} viewBox={viewBox} style={style} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2H2V3.2H3L3 10.8H2V12H7.39961V12.4415L4.7002 14L5.3002 15.0392L7.39961 13.8271V15H8.59961V13.8267L10.6998 15.0392L11.2998 14L8.59961 12.441V12H14V10.8H13V3.2H14V2ZM4.2 10.8L4.2 3.2H11.8L11.8 10.8H4.2ZM6.9998 6.44834L7.9998 5.88017L8.9998 6.44834V7.55157L7.9998 8.11974L6.9998 7.55157V6.44834ZM7.9998 4.5L10.1998 5.74998V8.24993L7.9998 9.4999L5.7998 8.24993V5.74998L7.9998 4.5Z"
      fill={fill}
    />
  </svg>
);
export default WelcomeIcon;
