import React from 'react';
import { Dropdown, Menu } from 'antd';
import './DropdownMenuCategories.scss';
import { cn } from '@utils/bem-config';
import { Button } from '@components/common/Button';
import ArrowIcon from '@components/common/SvgIcon/ArrowIcon';

const bem = cn('dropDown');

export const DropdownMenuCategories = ({ data, functionOnClick, currentData, setCurrentData }) => {
  const menu = (
    <Menu className={bem('menu')} motion={false}>
      {data?.length ? (
        <>
          <Menu.Item className={bem('item')} onClick={() => setCurrentData([])} key="reset-tag">
            Не выбрано
          </Menu.Item>
          {data.map((item) => (
            // Убрали ID из запроса, если он будет нужно будет переписать payload
            <Menu.Item className={bem('item')} key={`${item}`} onClick={() => setCurrentData([item])}>
              {`#${item}`}
            </Menu.Item>
          ))}
        </>
      ) : (
        <Menu.Item className={bem('item')}>Список пуст</Menu.Item>
      )}
      <Menu.Item className={bem('dropDown-button')} key="link-to-settings">
        <Button onClick={functionOnClick}>Управление тегами</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown className={bem()} overlay={menu} trigger="click">
      <div>
        <span className={bem('text-select')}>{currentData.length === 0 ? 'Тематика' : currentData}</span>
        <ArrowIcon />
      </div>
    </Dropdown>
  );
};
