import React, { useState } from 'react';
import { Clusterer } from 'react-yandex-maps';
import ClusterIcon from '@src/assets/images/cluster-icon.svg';
import './ClustererLayoutOffices.scss';

export const ClustererLayoutOffices = ({ children, map }) => {
  const [officesByCity, setOffices] = useState({});

  const clusterIconContentTemplate = '<div class="i-cluster-content">{{ properties.geoObjects.length }}</div>';
  const clusterIconContentLayout = map?.templateLayoutFactory?.createClass(clusterIconContentTemplate);

  const handleClick = (geoObjects) => {
    const currentOffice = geoObjects.reduce((a, c) => {
      const {
        balloonContentHeader: city,
        balloonContenBody: address,
        balloonContentFooter: photo,
      } = c.properties.getAll();

      a[city] = { address, photo };

      return a;
    }, {});

    setOffices(currentOffice);
  };

  // создаем новый шаблон для кластера
  // по аналогии с ишью на гитхабе https://github.com/gribnoysup/react-yandex-maps/issues/234
  const clusterBalloonContentLayout = () => {
    if (map) {
      const cities = Object.keys(officesByCity);

      const clusterTemplate = cities
        .map(
          (city) => `
          <div class='i-balloon-office'>
            <div class='i-balloon-office__picture'>
              <img class='i-balloon-office__picture-img' src=${officesByCity[city].photo} />
            </div>
              <div class='i-balloon-office__item-office'>
                <div class='i-balloon-office__item-city'>
                  ${city}
                </div>
                <div class='i-balloon-office__item-street'>
                  ${officesByCity[city].address.split(',').slice(1)}
                </div>
              </div>
            </div>
          </div>
        `
        )
        .join('');

      const clusterLayout = map.templateLayoutFactory.createClass(clusterTemplate, {
        build() {
          clusterLayout.superclass.build.call(this);
        },
      });
      return clusterLayout;
    }
    return null;
  };

  const clusterIcons = [
    {
      href: ClusterIcon,
      size: [45, 45],
      offset: [-20, -20],
    },
    {
      href: ClusterIcon,
      size: [65, 65],
      offset: [-30, -30],
    },
  ];

  const clusterOptions = {
    iconLayout: 'cluster#icon',
    clusterIcons,
    clusterIconContentLayout,
    groupByCoordinates: false,
    clusterDisableClickZoom: true,
    clusterHideIconOnBalloonOpen: false,
    geoObjectHideIconOnBalloonOpen: false,
    minClusterSize: 1,
    clusterBalloonMaxHeight: 180,
    clusterBalloonContentLayout: clusterBalloonContentLayout(),
  };

  return (
    <Clusterer
      instanceRef={(ref) => {
        if (ref) {
          // переопределяем функцию createCluster, чтобы получить доступ к данным кластера и повесить обработчик
          // по аналогии с ишью на гитхабе https://github.com/gribnoysup/react-yandex-maps/issues/234
          ref.createCluster = function createCluster(center, geoObjects) {
            const clusterPlacemark = map.Clusterer.prototype.createCluster.call(this, center, geoObjects);
            clusterPlacemark.events.add('click', () => {
              handleClick(geoObjects);
            });
            return clusterPlacemark;
          };
        }
      }}
      options={clusterOptions}
    >
      {children}
    </Clusterer>
  );
};
