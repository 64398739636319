import React, { useRef, useEffect, useState } from 'react';
import { cn } from '@utils/bem-config';
import { AnimatedButtonBackground, generateSvgAnimation } from '../AnimatedButtonBackground';
import './ButtonAnimated.scss';

const bem = cn('button-animate');

export const ButtonAnimated = ({ onClick, children, type = '', disabled, ...props }) => {
  const buttonRef = useRef();
  const reserveWidth = 80;
  const [svgAnimation, setSvgAnimation] = useState(null);

  useEffect(() => {
    const buttonSize = buttonRef.current.getBoundingClientRect();
    const buttonWidth = buttonSize.width > 4 ? buttonSize.width - 2 : reserveWidth;

    setSvgAnimation(generateSvgAnimation(buttonWidth || reserveWidth));
  }, []);

  return (
    <button ref={buttonRef} onClick={onClick} className={bem({ type })} disabled={disabled} {...props}>
      <span className={bem('text')}>{children}</span>
      {svgAnimation && <AnimatedButtonBackground svgAnimation={svgAnimation} disabled={disabled} />}
    </button>
  );
};

export const LinkAnimated = ({ onClick, children, type = '', disabled, ...props }) => {
  const buttonRef = useRef();
  const reserveWidth = 80;
  const [svgAnimation, setSvgAnimation] = useState(null);

  useEffect(() => {
    const buttonSize = buttonRef.current.getBoundingClientRect();
    const buttonWidth = buttonSize.width > 4 ? buttonSize.width - 2 : reserveWidth;

    setSvgAnimation(generateSvgAnimation(buttonWidth || reserveWidth));
  }, []);

  return (
    <a ref={buttonRef} onClick={onClick} className={bem({ type })} disabled={disabled} {...props}>
      <span className={bem('text')}>{children}</span>
      {svgAnimation && <AnimatedButtonBackground svgAnimation={svgAnimation} disabled={disabled} />}
    </a>
  );
};
