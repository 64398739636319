import { PostListTabs } from '@components/common/PostListTabs/PostListTabs';
import { setIsOpenVoteForm, setIsOpenYouTubeForm } from '@components/common/PostsList/postsListSlice';
import { VoteButton } from '@components/common/VoteButton/VoteButton';
import { selectRoles } from '@src/appSlice';
import { NewPostForm } from '@src/components/common/NewPostForm';
import { PostsList } from '@src/components/common/PostsList';
import { cn } from '@utils/bem-config';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Feed.scss';

const bem = cn('feed');

export const Feed = ({ mobile }) => {
  const roles = useSelector(selectRoles);
  const isAdmin = new Set(roles).has('ADMIN');
  const dispatch = useDispatch();

  return (
    <div className={bem()}>
      <div className={bem('posts-container', { mobile })}>
        {isAdmin ? (
          <>
            <div className={bem('newPost-container')}>
              <NewPostForm />
              <VoteButton
                onButtonClick={() => dispatch(setIsOpenYouTubeForm())}
                title="Прикрепить YouTube видео"
                typeIcon="youtube"
              />
              <VoteButton onButtonClick={() => dispatch(setIsOpenVoteForm())} title="Создать опрос" typeIcon="poll" />
            </div>
            <PostListTabs />
          </>
        ) : (
          <PostsList />
        )}
      </div>
    </div>
  );
};
