import React from 'react';

const AddImageIcon = ({
  style = {},
  fill = 'var(--text-level-3)',
  stroke = '',
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}) => (
  <svg style={style} width={width} height={height} viewBox={viewBox} fill={fill} className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4.07141H9.20636V5.11904H2.04762V12.4524H12.1746V8.08729H13.2222V13.5H1V4.07141Z"
      fill={fill}
      stroke={stroke}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99134 8.02283L12.3788 12.6143L11.6214 13.3381L8.0003 9.54862L5.57466 12.1475L4.35501 10.8408L2.24735 12.9935L1.49878 12.2605L4.37313 9.32479L5.57466 10.6121L7.99134 8.02283Z"
      fill={fill}
      stroke={stroke}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.61913 7.7381L3.61913 6.69048L4.66675 6.69048L4.66675 7.7381L3.61913 7.7381Z"
      fill={fill}
      stroke={stroke}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.2222 2.5V6.69048H12.1746V2.5H13.2222Z" fill={fill} stroke={stroke} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6033 4.07144L14.7938 4.07144L14.7938 5.11906L10.6033 5.11906L10.6033 4.07144Z"
      fill={fill}
      stroke={stroke}
    />
  </svg>
);

export default AddImageIcon;
