import React, { useState, useEffect } from 'react';
import { cn } from '@utils/bem-config';
import { TernaryForSkeleton } from '@components/common/Skeletons/Skeleton';
import { VoteAnswers } from '@components/common/Vote/VoteAnswers/VoteAnswers';
import './Vote.scss';

const bem = cn('vote');

export const Vote = ({ voteAnswers, onChange, dataSelectedVoice, title, surveyType, isLoading }) => {
  /**
   * Общее количество голосов.
   */
  const [voteNumber, setVoteNumber] = useState(0);

  useEffect(() => {
    setVoteNumber(voteAnswers.reduce((accum, item) => accum + item.count, 0));
  }, [voteAnswers]);

  return (
    <article className={bem()}>
      <TernaryForSkeleton isLoading={isLoading} width="100%" times={2}>
        <p className={bem('title')}>{title}</p>
        <p className={bem('survey-type')}>{surveyType}</p>
      </TernaryForSkeleton>
      <TernaryForSkeleton isLoading={isLoading} times={1} width="30rem" />

      <VoteAnswers
        voteAnswers={voteAnswers}
        onChange={onChange}
        isLoading={isLoading}
        isVoted={dataSelectedVoice?.selected}
        voteNumber={voteNumber}
        voteId={dataSelectedVoice?.id}
      />
    </article>
  );
};
