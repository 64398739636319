import React from 'react';

const HeaderLogo = ({
  style = {},
  stroke = 'var(--c-accent)',
  width = '22',
  height = '21',
  className = '',
  viewBox = '0 0 22 21',
}) => (
  <svg
    className={className}
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd"
      d="M19.5203 11.8854L16.2428 3.93929L7.72257 2.80455L2.47972 9.61595L5.75715 17.5621L14.2774 18.6968L19.5203 11.8854ZM15.1386 20.7848L21.7591 12.1836L17.6205 2.14952L6.86139 0.716613L0.24093 9.31779L4.37953 19.3519L15.1386 20.7848Z"
      fill={stroke} />
  </svg>
);
export default HeaderLogo;
