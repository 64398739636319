import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, request } from '@src/app/api';
import { STATUS } from '@src/hoc/StatusSwitch';
import {
  addLikesFulfilled,
  addViewsFulfilled,
  deleteCommentFulfilled,
  deletePostFulfilled,
  restoreCommentFulfilled,
  sendCommentFulfilled,
} from '@utils/postActionsHandlers';
import {
  addLikes,
  addViews,
  deleteComment,
  deletePost,
  restoreComment,
  sendComment,
} from '@components/common/PostsList/postsListSlice';
import Qs from 'qs';

const initialState = {
  searchedUsers: { title: 'Сотрудники', results: [] },
  searchedPosts: { title: 'Посты', results: [] },
  searchedPostsByTags: { title: 'Категории', results: [] },
  searchValue: '',
  tagValue: false,
};

export const searchUsers = createAsyncThunk('search/searchUsers', async (data) => {
  const { searchValue, size, emptyRequest } = data;
  const response = await request.get(api.usersSearch(), {
    params: {
      size,
      page: 0,
      fullName: searchValue,
      delete: false,
    },
    paramsSerializer(params) {
      return Qs.stringify(params);
    },
  });
  return { response: response.data, emptyRequest, searchValue };
});

export const searchPosts = createAsyncThunk('search/searchPosts', async (data) => {
  const { searchValue, size, emptyRequest, tag } = data;
  const response = await request.get(api.postsSearch(), {
    params: {
      size,
      page: 0,
      content: searchValue,
      tag: tag || null,
    },
    paramsSerializer(params) {
      return Qs.stringify(params);
    },
  });
  return { response: response.data, emptyRequest };
});

export const searchPostsByTags = createAsyncThunk('search/searchPostsByTags', async (data) => {
  const { searchValue, size } = data;
  const response = await request.get(api.postsSearch(), {
    params: {
      size,
      page: 0,
      tag: searchValue,
    },
    paramsSerializer(params) {
      return Qs.stringify(params);
    },
  });
  return response.data;
});

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setRecentSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
    setTagValue: (state, { payload }) => {
      state.tagValue = payload;
    },
  },
  extraReducers: {
    [searchUsers.fulfilled]: (state, action) => {
      if (action.payload.response.length) state.searchedUsers.results = action.payload.response;
      if (!action.payload.response.length || action.payload.emptyRequest) state.searchedUsers.results = [];
    },
    [searchPosts.fulfilled]: (state, action) => {
      if (action.payload.response.length) state.searchedPosts.results = action.payload.response;
      if (!action.payload.response.length || action.payload.emptyRequest) state.searchedPosts.results = [];
    },
    [searchPosts.rejected]: (state) => {
      state.searchedPosts.results = [];
    },
    [searchPostsByTags.fulfilled]: (state, action) => {
      if (action.payload.length) state.searchedPostsByTags.results = action.payload;
      if (!action.payload.length) state.searchedPostsByTags.results = [];
    },
    [searchPostsByTags.rejected]: (state) => {
      state.searchedPostsByTags.results = [];
    },
    [sendComment.pending]: (state) => {
      state.sendCommentStatus = STATUS.LOADING;
    },
    [sendComment.fulfilled]: (state, action) => {
      sendCommentFulfilled(state, action);
    },
    [deleteComment.fulfilled]: (state, action) => {
      deleteCommentFulfilled(state, action);
    },
    [restoreComment.fulfilled]: (state, action) => {
      restoreCommentFulfilled(state, action);
    },
    [deletePost.fulfilled]: (state, action) => {
      deletePostFulfilled(state, action);
    },
    [addLikes.fulfilled]: (state, action) => {
      addLikesFulfilled(state, action);
    },
    [addViews.fulfilled]: (state, action) => {
      addViewsFulfilled(state, action);
    },
  },
});

export const { setRecentSearchValue, setTagValue } = searchSlice.actions;

export const selectSearchedUsers = (state) => state.search.searchedUsers;
export const selectSearchedPosts = (state) => state.search.searchedPosts;
export const selectSearchedPostsByTags = (state) => state.search.searchedPostsByTags;
export const selectSearchValue = (state) => state.search.searchValue;
export const selectTagValue = (state) => state.search.tagValue;

export const SearchSlice = searchSlice.reducer;
