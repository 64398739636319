import { UserItem } from '@components/common/UserItem';
import { StatusSwitch } from '@src/hoc/StatusSwitch';
import { cn } from '@utils/bem-config';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUsersSearchStatus, selectSearchedUsersList } from '@components/common/UsersSearch/usersSearchSlice';

import './UsersList.scss';

const bem = cn('users-list');

const ComponentOnDone = ({ allUsers }) => (
  <>
    {allUsers.length > 0 ? (
      allUsers.map((user) => (
        <div className={bem('item-container')} key={user.ldapId}>
          <UserItem
            userLdapId={user?.ldapId}
            photo={user?.photos?.avatar?.url}
            photoPosition="left"
            photoSize="l"
            lastName={user?.lastName}
            firstName={user?.firstName}
            secondLine={user?.department.join(' | ') || 'Направление не указано'}
          />
        </div>
      ))
    ) : (
      <p>Сотрудников не найдено</p>
    )}
  </>
);

const ComponentOnLoading = () => (
  <>
    {[0, 1, 2, 4, 5, 6, 7, 8, 9].map((item) => (
      <div className={bem('item-container')} key={item}>
        <UserItem photoSize="l" secondLine isLoading />
      </div>
    ))}
  </>
);

export const UsersList = () => {
  const status = useSelector(selectUsersSearchStatus);
  const searchedUsers = useSelector(selectSearchedUsersList);

  return (
    <div className={bem()}>
      <StatusSwitch
        status={status}
        ComponentOnDone={<ComponentOnDone allUsers={searchedUsers} />}
        ComponentOnLoading={<ComponentOnLoading />}
        ComponentOnError={<>Сотрудников не найдено</>}
      />
    </div>
  );
};
