import React from 'react';

const SendIcon = ({
  style = {},
  fill = 'var(--text-level-3)',
  stroke = 'var(--text-level-3)',
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}) => (
  <svg width={width} style={style} height={height} viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4.56619L14 11.4338L7.99992 14.8624L1.99984 11.4338L1.99984 4.56619L7.99992 1.13757L14 4.56619ZM12.8387 5.24012L7.99992 2.47511L3.16114 5.24012L3.16114 10.7598L7.99992 13.5248L12.8387 10.7598L12.8387 5.24012Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63553 4.70545L4.61409 7.57791L3.7793 6.4092L7.80074 3.53674L8.63553 4.70545ZM10.564 10.3448V5.55733H12.0002V10.3448H10.564ZM5.04274 9.24894L9.06418 11.6427L8.32957 12.8768L4.30813 10.4831L5.04274 9.24894Z"
      fill={fill}
    />
    <line x1="6.5" y1="-2.18557e-08" x2="6.5" y2="16" stroke={stroke} />
    <line x1="9.5" y1="-2.18557e-08" x2="9.5" y2="16" stroke={stroke} />
  </svg>
);

export default SendIcon;
